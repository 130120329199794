<div class="text-center pt-3 bg-white">
  <img src="assets/park-evaluations-logo.svg" width="160">
  <hr />
</div>

<!-- Error Body -->
<div class="d-flex flex-column justify-content-center error-container mt-5">
  <div class="flex-grow text-center">
    <h1>Oops! :(</h1>
    <h5> An error occurred while we're trying to process the page!</h5>
  </div>
  <div class="text-center mt-4">
    <div class="alert alert-danger d-inline-block" style="width: 500px">
      <label class="font-weight-bold">Error Information</label>
      <hr class="m-0" />
      <div class="mt-3">
        <div>
          <label class="font-weight-bold">Location:</label>&nbsp;{{errorLocation}}
        </div>
        <div>
          <label class="font-weight-bold">Error:</label>&nbsp;{{errorMessage}}
        </div>
      </div>
    </div>
  </div>
  <div class="text-center mt-4">
    <button class="btn btn-primary btn-lg" (click)="refresh()">Refresh</button>
  </div>
</div>

