<!--The content below is only a placeholder and can be replaced.-->
<router-outlet></router-outlet>

<!-- Gloabal mini-loader on the right -->
<app-global-loader></app-global-loader>

<!-- Screensize error monitor -->
<app-error-screensize-monitor></app-error-screensize-monitor>

<!-- Used during development. Only visible on LOCALHOST -->
<div class="style-index-container" *ngIf="styleIndexEnabled">
  <button class="btn btn-primary" routerLink="/main/style-index">Theme and Style Index (For Developers)</button>
</div>

<p-toast></p-toast>

<app-unsaved-changes-modal></app-unsaved-changes-modal>

<app-offline-screen></app-offline-screen>

<app-terms-overlay></app-terms-overlay>
