import * as tslib_1 from "tslib";
import { AuthService } from '../../services/auth.service';
import { SystemTermsService } from '../../services/data/system-terms.service';
import { BehaviorSubject } from 'rxjs';
import { UserType } from '../../api/user/user-type';
import * as i0 from "@angular/core";
import * as i1 from "../../services/auth.service";
import * as i2 from "../../services/data/system-terms.service";
var TermsOverlayService = /** @class */ (function () {
    function TermsOverlayService(authService, sysTerms) {
        var _this = this;
        this.authService = authService;
        this.sysTerms = sysTerms;
        this.shown = new BehaviorSubject(false);
        this.authService
            .status
            .subscribe(function (user) {
            _this.checkTerms(user);
        });
    }
    TermsOverlayService.prototype.checkTerms = function (user) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var terms;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!user || user.userType === UserType.Internal) {
                            this.shown.next(false);
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.sysTerms
                                .get(user.organizationId)
                                .toPromise()];
                    case 1:
                        terms = _a.sent();
                        // If bypassed or accepted do not process
                        if (terms.bypass || terms.acknowledge) {
                            this.shown.next(false);
                            return [2 /*return*/];
                        }
                        this.shown.next(true);
                        return [2 /*return*/];
                }
            });
        });
    };
    TermsOverlayService.ngInjectableDef = i0.defineInjectable({ factory: function TermsOverlayService_Factory() { return new TermsOverlayService(i0.inject(i1.AuthService), i0.inject(i2.SystemTermsService)); }, token: TermsOverlayService, providedIn: "root" });
    return TermsOverlayService;
}());
export { TermsOverlayService };
