import { Router } from '@angular/router';
import { of, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { UserType } from '../api/user/user-type';
import { UserPermissionType } from '../api/organization/user-permissions';
import { skipWhile, switchMap } from 'rxjs/operators';
import { isNullOrUndefinedOrBlank } from '../utils/is-null-or-undefined';
import * as i0 from "@angular/core";
import * as i1 from "../services/auth.service";
import * as i2 from "@angular/router";
var UserGuard = /** @class */ (function () {
    function UserGuard(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    /**
     * This guard is for detecting which module to redirect the current user.
     *
     * @param next
     * @param state
     */
    UserGuard.prototype.canActivate = function (next, state) {
        var _this = this;
        return this.authService
            .status
            .pipe(skipWhile(function (user) { return isNullOrUndefinedOrBlank(user); }), switchMap(function (user) {
            if (user.userType === UserType.Internal) {
                return of(_this.handleInternal());
            }
            if (user.userType === UserType.Expert) {
                _this.handleExpert();
            }
            if (user.userType === UserType.Company) {
                _this.handleCustomer();
            }
            // Everything else returns false. Only the internal user type can display the instance selector
            return of(false);
        }));
    };
    UserGuard.prototype.handleInternal = function () {
        var permissions = this.authService
            .currentUser
            .permissions
            .map(function (perms) { return perms.permission; });
        // Do you have access to both systems?
        if (permissions.includes(UserPermissionType.ParkEval) && permissions.includes(UserPermissionType.ParkAds)) {
            // Nothing to do here. We will render instance selector.
            return true;
        }
        // For ParkAds only
        if (permissions.includes(UserPermissionType.ParkAds)) {
            this.router.navigate(['/park-ads/internal']);
            return false;
        }
        // For ParkEval only
        if (permissions.includes(UserPermissionType.ParkEval)) {
            this.router.navigate(['/main']);
            return false;
        }
        // If you reached here, you don't have either. That is definitely a problem.
        // Normally we wouldn't reach here.
        throwError('No valid module permission for this user');
    };
    UserGuard.prototype.handleExpert = function () {
        this.router.navigate(['/main']);
    };
    UserGuard.prototype.handleCustomer = function () {
        // In the current setup, ONLY park ads company are allowed to login.
        // ParkEval companies are not allowed to access the system.
        this.router.navigate(['/park-ads/company']);
    };
    UserGuard.ngInjectableDef = i0.defineInjectable({ factory: function UserGuard_Factory() { return new UserGuard(i0.inject(i1.AuthService), i0.inject(i2.Router)); }, token: UserGuard, providedIn: "root" });
    return UserGuard;
}());
export { UserGuard };
