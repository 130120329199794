import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FirebaseService } from '../../services/firebase.service';
import { ToastService } from '../../services/toast.service';
import { RaygunService } from '../../services/raygun.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {

  @Input()
  public code: string; // Firebase oob code

  public password: string;

  public passwordRepeat: string;

  public errorMsg: string;

  public requestInProgress = false;

  constructor( private fb: FormBuilder,
               private authService: AuthService,
               private route: ActivatedRoute,
               private firebase: FirebaseService,
               private toast: ToastService,
               private raygun: RaygunService,
               private router: Router) {}

  public ngOnInit(): void {

  }

  public redirectToLogIn(): void {
    this.router.navigate(['/public/login']);
  }

  public async submit(): Promise<void> {
    this.errorMsg = null;
    this.requestInProgress = true;
    try {
      await this.firebase.auth.verifyPasswordResetCode(this.code);
      await this.firebase.auth.confirmPasswordReset(this.code, this.password);
      this.toast.addSuccess('Password updated');
      this.redirectToLogIn();
    } catch (e) {
      // handling errors here.
      if (e.code && e.code === 'auth/invalid-action-code') {
        this.errorMsg = 'Reset link has expired';
        return;
      }

      // Other errors - they will be send to raygun if unhandled.
      this.errorMsg = 'Failed to update your password. Try again or please contact support.';
      this.raygun.send(e, {
        message: 'Failed to update password from Firebase',
      });

      this.requestInProgress = false;
    }
  }
}
