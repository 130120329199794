import { FieldType } from './field-type';

export interface FilterOperator {
  type: FieldType;
  value: string;
  label: string;
}

export const Operators = {
  string: {
    $eq: { type: FieldType.String, value: '=', label: '=' } as FilterOperator,
    $neq: { type: FieldType.String, value: '!=', label: '!=' } as FilterOperator,
    $contains: { type: FieldType.String, value: 'contains', label: 'Contains' } as FilterOperator,
    $ncontains: { type: FieldType.String, value: 'does-not-contain', label: 'Does not contain' } as FilterOperator,
  },
  numeric: {
    $eq: { type: FieldType.Numeric, value: '=', label: '=' },
    $neq: { type: FieldType.Numeric, value: '!=', label: '!=' },
    $gt: { type: FieldType.Numeric, value: '>', label: '>' },
    $gte: { type: FieldType.Numeric, value: '>=', label: '>=' },
    $lt: { type: FieldType.Numeric, value: '<', label: '<' },
    $lte: { type: FieldType.Numeric, value: '<=', label: '<=' },
    $between: { type: FieldType.Numeric, value: 'between', label: 'Between' } as FilterOperator,
    $contains: { type: FieldType.Numeric, value: 'contains', label: 'Contains' } as FilterOperator,
    $ncontains: { type: FieldType.Numeric, value: 'does-not-contain', label: 'Does not contain' } as FilterOperator,
  },
  boolean: {
    $eq: { type: FieldType.Boolean, value: '=', label: '=' } as FilterOperator,
  },
  date: {
    $between: { type: FieldType.Date, value: 'between', label: 'Between' } as FilterOperator,
  },
  array: {
    $contains: { type: FieldType.Array, value: 'contains', label: 'Contains' } as FilterOperator,
    $ncontains: { type: FieldType.Array, value: 'does-not-contain', label: 'Does not contain' } as FilterOperator,
  },
  collection: {
    $contains: { type: FieldType.Collection, value: 'contains', label: 'Contains' } as FilterOperator,
    $ncontains: { type: FieldType.Collection, value: 'does-not-contain', label: 'Does not contain' } as FilterOperator,
  },
  // Common operators added after the pagination upgrade to simplify query generation
  common: {
    $eq: '==',
    $neq: '!=',
    $gt: '>',
    $gte: '>=',
    $lt: '<',
    $lte: '<=',
    $between: 'between', // Usually for numbers and ranges
    $contains: 'contains', // Usually for strings
    $ncontains: 'does-not-contain', // Usually for strings
    $in: 'in', // Usually used for arrays
    $out: 'out', // Usually used for arrays
    $or: 'or', // For arrays - same with 'in' operator
    $isnull: 'isnull'
  }
};

export const OperatorCollection: FilterOperator[] = [
  // String:
  Operators.string.$eq,
  Operators.string.$neq,
  Operators.string.$contains,
  Operators.string.$ncontains,
  // Numeric:
  Operators.numeric.$eq,
  Operators.numeric.$neq,
  Operators.numeric.$gt,
  Operators.numeric.$gte,
  Operators.numeric.$lt,
  Operators.numeric.$lte,
  Operators.numeric.$between,
  // Boolean:
  Operators.boolean.$eq,
  // Date
  Operators.date.$between,
  // Array
  Operators.array.$contains,
  Operators.array.$ncontains,
  // Collection
  Operators.collection.$contains,
  Operators.collection.$ncontains,
];
