import { ServiceStage } from './service-stage';

export enum Action {
  Rejected = 'rejected',
  CompleteExpert = 'completeExpert',
  CompleteWritingDefault = 'completeWritingDefault',
  NotStarted = 'notStarted',
  StartAndClaim = 'startAndClaim',
  StartAndClaimCase = 'startAndClaimCase',
  StartAssigned = 'startAssigned',
  ClaimService = 'claimService',
  ReleaseClaimService = 'releaseClaimService',
  CompleteWritingExpert = 'completeWritingExpert',
  CompleteWritingDupont = 'completeWritingDupont',
  CompleteWritingPostDupont = 'completeWritingPostDupontResponse',
  CompleteWritingPreDupont = 'completeWritingPreDupontResponse',
  ContinueWork = 'continueWork',
  CompleteProofreadingExpert = 'completeProofreadingExpert',
  InProgress = 'inProgress',
  ViewCompleted = 'viewCompleted',
  CompleteProofreadingNoExpert = 'completeProofreadingNoExpert',
  CompleteValidation = 'completeValidation',
  CompleteMarkupTemplateExpertWriter = 'completeMarkupTemplateExpertWriter',
  CompleteMarkupTemplateEquivalencyWriter = 'completeMarkupTemplateEquivalencyWriter',
  CompleteMarkupTemplateExpertEquivalencyWriter = 'completeMarkupTemplateExpertEquivalencyWriter',
  CancelService = 'cancelService',
  CancelAdsService = 'cancelAdService',
  CancelCase = 'cancelCase',
  HoldCase = 'holdCase',
  HoldService = 'holdService',
  ResumeService = 'resumeService',
  ResumeCase = 'resumeCase',
  ReassignTemplate = 'reassignNewTemplate',
  ReassignExpert = 'reassignNewExpert',
  ReassignService = 'reassignService',
  CompleteCourseworkResponse = 'completeCourseworkResponse',
  ReassignNewService = 'reassignNewService',
  ReassignNewStage = 'reassignNewStage',
  DeliveryCase = 'deliveryCase',
  ReplaceCompletedFile = 'replaceCompletedFile',
  ManualDelivery = 'manualDelivery',
  CompleteWritingAcademic = 'completeWritingACA',
  CompleteGeneralStatement = 'completeGeneralStatement',
  SaveWritingProgress = 'saveWritingProgress',

  // ads action type
  StartAndClaimAds = 'startAndClaimAds',
  HoldAdsCase = 'holdAdsCase',
  ResumeAdsCase = 'resumeAdsCase',
  CancelAdsCase = 'cancelAdsCase',
  AssignPM = 'assignPM',
  BuildClientQuote = 'buildClientQuote',
  SaveQuote = 'saveQuote',
  RequestQuote = 'requestQuote',
  ReviseDetailsAndPricing = 'reviseDetailsAndPricing',
  EditQuote = 'editQuote',
  SendQuoteToClient = 'sendQuoteToClient',
  ConfirmQuote = 'confirmQuote',
  BuildAdSchedule = 'buildAdSchedule',
  ConfirmRunDate = 'confirmRunDate',
  ArchiveFile = 'archiveFile',
  ScheduleAndInvoice = 'sendScheduleAndInvoice',
  BackToConfirmRunDate = 'backToConfirmRunDate',
  StoreAdFiles = 'storeAdFiles',
  VerifyETearPackage = 'verifyETearPackage',
  SendETearPackage = 'sendETearPackage',
  BackToStoreFiles = 'backToStoreFiles',
  AddService = 'addAdService',
  AddServiceAfterQuoted = 'addAdServiceAfterQuoted',
  RemoveAdService = 'removeAdService',
  ApproveQuote = 'approveQuote',
  RequestQuoteRevision = 'requestQuoteRevision',
  ConfirmAdPosted = 'confirmAdPosted',
  CompleteVeteranHold = 'completeVeteranHold',
  EditEndDate = 'editEndDate',
  RepostAd = 'repostAd',
  UpdateAdUploads = 'updateAdUploads', // use for when service stage is completed
  ResetQuote = 'resetQuote',
  AutoApproveQuote = 'autoApproveQuote',
  EditRunDate = 'editRunDate',
  UpdateAdText = 'updateAdText',
  BackToQuote = 'backToQuote',
  SendQuoteToInternalReview = 'sendQuoteToInternalReview',
  SendQuoteBackToPm = 'sendQuoteBackToPm',
  AssignQuoteReviewer = 'assignQuoteReviewer',
  AddTranslation = 'addTranslation',
  CancelTranslation = 'cancelTranslation',
  EditScheduling = 'editScheduling',
  UpdateObtainedCredential = 'updateObtainedCredential',
  SaveQuoteVendorRequest = 'saveQuoteVendorRequest',
  SendQuoteVendorEmail = 'sendQuoteVendorEmail',
}

export enum ActionType {
  View = 'view',
  Complete = 'complete',
  Update = 'update',
  Delivery = 'delivery',
  Hold = 'hold',
  Resume = 'resume',
  Cancel = 'cancel',
}

export enum ActionLevel {
  Case = 'CASE',
  Service = 'SERVICE',
  Request = 'REQUEST',
}

export enum RequestAction {
  View = 'view',
  Download = 'download',
  Choice = 'choice',
  Add = 'add',
  Expert = 'expert',
  Template = 'template',
  Writer = 'writer',
  ExpertConfirmation = 'expert-confirmation',
  Start = 'start',
  Claim = 'claim',
  ReleaseClaim = 'release-claim',
  Cancel = 'cancel',
  Hold = 'hold',
  Resume = 'resume',
  Deadline = 'deadline',
  ExpertDeadline = 'expertDeadline',
  DupontDeadline = 'dupontDeadline',
  Reassign = 'reassign',
  Internal = 'internal',
  Service = 'service',
  Stage = 'stage',
  Delivery = 'delivery',
  Ready = 'ready',
  DeliverySubject = 'deliverySubject',
  AddTranslation = 'addTranslation',
  CancelTranslation = 'cancelTranslation',
}

export enum RequestType {
  Candidate = 'candidate',
  Expert = 'expert',
  Template = 'template',
  Equivalency = 'equivalency',
  File = 'file',
  Note = 'note',
  Email = 'email',
  Assign = 'assign',
  Boolean = 'boolean',
  Hidden = 'hidden',
  Date = 'date',
  CourseworkResponse = 'courseworkResponse',
  ServiceTemplateFields = 'serviceTemplateFields',
  PendingServiceTemplateFields = 'pendingServiceTemplateFields',
  Rating = 'rating',
  ObtainedCredentials = 'obtainedCredentials',

  // Park Ads
  QuoteRequest = 'quoteRequest',
  AdLoginCredential = 'adLoginCredential',
  EditRunDate = 'editRunDate',
  EditQuote = 'editQuote',
  ServiceUpload = 'serviceUpload',
  AdPlacementUrl = 'adPlacementUrl',
  Proofs = 'proofs',
  HoldReason = 'holdReason',
  AddAdsCaseService = 'addAdsCaseService',
  JobComplete = 'jobComplete',
  VeteranHold = 'veteranHold',
  VeteranFile = 'veteranFile',
  VeteranReminder = 'veteranReminder',
  VeteranStartDate = 'veteranStartDate',
  EditEndDate = 'editEndDate',
  RepostAd = 'repostAd',
  AutoApproveQuote = 'autoApproveQuote',
  PrepaymentType = 'prepaymentType',
  AssignmentDate = 'assignmentDate',
  RemoveAdsCaseService = 'removeAdsCaseService',
  EmailAttachments = 'emailAttachments',
  UpdateAdText = 'editAdText',
  ArchiveFile = 'archiveFile',
  QuoteError = 'quoteError',
  AssignQuoteReviewer = 'assignQuoteReviewer',
  EditVendorReferenceNumber = 'editVendorReferenceNumber',
  EditServiceSpecificNote = 'editServiceSpecificNote',
  EditScheduling = 'editScheduling',
  VendorQuoteEmailRequest = 'vendorQuoteEmailRequest',
  VendorSendAutomatedQuoteRequest = 'vendorSendAutomatedQuoteRequest',
}

export enum SubAction {
  NoChanges = 'no-changes',
  UploadNew = 'upload-new',
  Reject = 'reject',
}

export interface WorkflowAction {
  id: number;
  creationDate?: number;
  lastModified?: number;
  action: Action;
  readableAction?: string;
  actionType: ActionType;
  workflowActionRequests?: WorkflowActionRequest[];
}

export interface WorkflowActionRequest {
  id?: number;
  actionLevel: ActionLevel;
  carryOnServiceStage?: ServiceStage;
  carryOnValue?: boolean;
  creationDate?: number;
  defaultResponseValue?: any;
  dependentRequestId?: number;
  dependentValue?: boolean;
  description: string;
  lastModified?: number;
  requestAction: RequestAction;
  requestResponse?: any;
  required?: boolean;
  subAction?: SubAction;
  type?: RequestType;
}
