import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

export enum ToastSeverity {
  Success = 'success',
  Info = 'info',
  Warn = 'warn',
  Error = 'error',
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private messageService: MessageService) { }

  public add(severity: ToastSeverity, message: string, title?: string): void {
    const srcMsg = {severity, detail: message, summary: title};
    this.messageService.add(srcMsg);
  }

  /**
   * Shorthand for generic success message
   */
  public addSuccess(msg?: string): void {
    this.add(ToastSeverity.Success, msg ? msg : 'Saved Successfully');
  }

  /**
   * Shorthand for generic error message
   */
  public addError(msg?: string): void {
    this.add(ToastSeverity.Error, msg ? msg : 'Operation Failed');
  }
}
