<div class="text-center pt-3 bg-white">
  <img src="assets/park-evaluations-logo.svg" width="160">
  <hr />
</div>

<!-- Error Body -->
<div class="d-flex flex-column justify-content-center error-container mt-5">
  <div class="flex-grow text-center">
    <h1>Oops! :(</h1>
    <h5> An error occurred while we're trying to process the page!</h5>
  </div>
  <div class="text-center mt-4">
    <div class="alert alert-danger d-inline-block" style="width: 500px">
      <label class="font-weight-bold">End Client Inactive: Reactivation Required</label>
      <hr class="m-0" />
      <div class="mt-3">
        We noticed that the End Client "<b>{{endClient}}</b>" for the selected case "<b>{{caseNumber}}</b>"
        is currently inactive. To proceed, please ask your company admin (or a Park project manager) to
        reactivate the end client through the settings.
      </div>
    </div>
  </div>
  <div class="text-center mt-4">
    <button class="btn btn-primary btn-lg" (click)="goBack()">Go back</button>
  </div>
</div>

