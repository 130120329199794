import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import UserCredential = firebase.auth.UserCredential;
import { NgForm } from '@angular/forms';
import { touchForm } from '../../utils/touch-form';
import { Router } from '@angular/router';
import { ToastService } from '../../services/toast.service';
import { RaygunService } from '../../services/raygun.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  @Input()
  public token: string;

  @Input()
  public user: string;

  public password: string;

  public passwordRepeat: string;

  public error: string;

  public verifying = true;

  public expired: boolean = null; // expired unless proven

  constructor(private authService: AuthService,
              private router: Router,
              private raygun: RaygunService) { }

  public ngOnInit(): void {
    this.authService
      .loginToVerify(this.user, this.token)
      .toPromise()
      .then(() => {
        // debugger;
        this.expired = false;
      })
      .catch(() => {
        // debugger;
        this.expired = true;
      })
      .finally(() => {
        this.verifying = false;
      });
  }

  public async update(form: NgForm): Promise<void> {
    this.error = null;
    touchForm(form);

    if (form.invalid) {
      return;
    }

    // Login the user first.
    let user: UserCredential;
    try {
      user = await this.authService
        .login(this.user, this.token)
        .toPromise();
    } catch(e) {
      this.error = 'Password update failed. Your token maybe expired';
      return;
    }

    // Try updating password.
    try {
      await user.user.updatePassword(this.password);
    } catch (e) {
      this.error = 'Password update failed. Please try again.';
      // If this error-ed out, something must be amiss. Since we have the proper validators.
      // Send to raygun for investigation.
      this.raygun.send(e, { source: 'Signup password update.' });
      // Log out the user again if the operation errors out
      this.authService.logout();
      return;
    }

    window.location.reload();
  }

}
