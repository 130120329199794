import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { UserType } from '../api/user/user-type';

@Injectable({
  providedIn: 'root'
})
export class InternalCasesGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.currentUser.userType === UserType.Expert) {
      this.router.navigate(['main', 'expert-services'], { replaceUrl: false });
      return false;
    }

    return true;
  }
}
