import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Service } from '../../api/system-config/service';
import { SystemConfigType } from '../../api/system-config/system-config-type';
import { ReadableConfig } from '../../api/system-config/readable-config';
import { SystemConfigRequest } from '../../api/system-config/system-config-request';
import { BillingRate } from '../../api/system-config/billing-rate';
import { EmailExclusion } from '../../api/system-config/email-exclusion';
import { SystemConfigResponse } from '../../api/system-config/system-config-response';
import { Country } from '../../api/country';
import { Timezone } from '../../api/timezone';
import { AdditionalPricing } from '../../api/system-config/additional-pricing';
import { ServiceAndWorkflow } from '../../api/system-config/service-and-workflow';
import { sortBy } from 'src/app/utils/sort-by';
import { map, mapTo, switchMap, tap } from 'rxjs/operators';
import { University } from '../../api/system-config/university';
import { CountryPrefix } from '../../api/system-config/country-prefix';
import { ServiceCategoryType } from '../../api/system-config/service-category-type';
import { ErrorType } from '../../main/case/workspace/stage-proofreading/quality-issue-form';
import { ServiceStageValue } from '../../api/service-stage';
import { SalesPerson } from '../../api/system-config/sales-person';
import { SystemType } from '../../api/system-config/system-type';
import { SwaSite } from '../../api/system-config/swa-site';
import { OnlineConsumerSite } from '../../api/system-config/online-consumer-site';
import { OnlineTradeJournal } from '../../api/system-config/online-trade-journal';
import { SystemModule } from '../../api/system-config/system-module';
import { AdsBillingRate } from '../../api/system-config/ads-billing-rate';
import { AdsService } from '../../api/system-config/ads-service';
import { AdsServiceType } from '../../api/system-config/ads-service-type';
import { ParamConfig, ParamConfigType } from '../../api/system-config/param-config';
import { ReminderEmail } from '../../api/system-config/reminder-email';
import { SystemDefaultUser } from '../../api/system-config/system-default-user';
import { clone } from '../../utils/clone';
import { ServiceType } from '../../api/system-config/service-type';
import { includes } from '../../utils/includes';
import { PAGINATE_NO_LIMIT, PaginatedData, PaginateObject } from '../../api/pagination/paginated-data';
import { AcademicEquivalencyMapping } from '../../api/case/academic-equivalency-mapping';
import { ObtainedCredential, ObtainedCredentialPaginated } from '../../api/case/obtained-credential';
import { sortByString } from '../../pipes/sort-by-string.pipe';
import { RsqlEncoder } from '../../api/pagination/rsql-encoder';
import { FieldType } from '../../api/pagination/field-type';
import { Operators } from '../../api/pagination/filter-operators';
import { GlobalLoaderService } from '../global-loader.service';
import { SwaStateRequirements } from '../../api/system-config/swa-state-requirements';

@Injectable({
  providedIn: 'root'
})
export class SystemConfigService {

  private swaStateRequirements: SwaStateRequirements[];

  private swaSites: SwaSite[];

  private currentModule = SystemModule.None;

  constructor(
    private httpClient: HttpClient,
    private globalLoader: GlobalLoaderService,
  ) {

  }

  public get activeModule(): SystemModule {
    if (this.currentModule) {
      return this.currentModule;
    }

    // Just a failsafe in-case somebody forgets to reset.
    return SystemModule.None;
  }

  public setModule(module: SystemModule): void {
    this.currentModule = module;
  }

  public resetModule(): void {
    this.currentModule = SystemModule.None;
  }

  public getServices(forInvoice: boolean = false, showDeleted: boolean = false): Observable<Service[]> {
    return this.httpClient
      .get<Service[]>(`/api/system_config?type=${SystemConfigType.Services}`)
      .pipe(
        map((ogServices: Service[]) => {
          let services = clone(ogServices);
          // Story 19000:
          // Remove WEVLoE (Experience and Expertise Combo Letter)
          // and TBD (other expert opinion letter);
          // Backend still returns them
          services = services
            .filter(service => showDeleted ? true : !service.deleteInd)
            .filter(service => !includes(
              [ServiceType.ExperienceAndExpertiseComboLetter, ServiceType.OtherExpertOpinionLetter],
              service.serviceType
            ));

          if (forInvoice) {
            // for invoice line item remove the TBD/PENDING
            const filteredServices = services
              .filter(service => service.serviceCategoryType !== ServiceCategoryType.PENDING);
            return sortBy(filteredServices, (service) => service.displayName.toLowerCase());
          } else {
            // non invoice filter out the INVOICE category type.
            const filteredServices = services
              .filter(service => service.serviceCategoryType !== ServiceCategoryType.INVOICE);
            return sortBy(filteredServices, (service) => service.displayName.toLowerCase());
          }
        })
      );
  }

  public getAdsServices(excludedServices?: AdsServiceType[]): Observable<AdsService[]> {
    return this.httpClient
      .get<AdsService[]>(`/api/system_config?type=${SystemConfigType.AdsServices}`)
      .pipe(
        map((services: AdsService[]) => {
          if (!excludedServices) {
            return services;
          }

          // Filter out excluded services
          return services.filter(service => !excludedServices.includes(service.adsServiceType));
        })
      );
  }

  public getDegrees(): Observable<ReadableConfig[]> {
    return this.httpClient.get<ReadableConfig[]>(`/api/system_config?type=${SystemConfigType.Degree}`)
      .pipe(map((degrees: ReadableConfig[]) => {
        return sortBy(degrees, (degree) => degree.readableType.toLowerCase());
      }));
  }

  public saveDegree(degree: ReadableConfig): Observable<SystemConfigResponse<ReadableConfig>> {
    const request: SystemConfigRequest = {
      id: degree.id,
      degreeDTO: degree,
      type: SystemConfigType.Degree
    };

    return this.saveOrUpdate<ReadableConfig>(request);
  }

  public getAcademicEquivalencies(): Observable<ReadableConfig[]> {
    return this.httpClient.get<ReadableConfig[]>(`/api/system_config?type=${SystemConfigType.AcademicEquivalency}`)
      .pipe(map((academicEquivalencies: ReadableConfig[]) => {
        return sortBy(academicEquivalencies, (academicEquivalency) => academicEquivalency.readableType.toLowerCase());
      }));
  }

  public saveAcademicEquivalency(academicEquivalency: ReadableConfig):
    Observable<SystemConfigResponse<ReadableConfig>> {
    const request: SystemConfigRequest = {
      id: academicEquivalency.id,
      academicEquivalencyDTO: academicEquivalency,
      type: SystemConfigType.AcademicEquivalency,
    };

    return this.saveOrUpdate<ReadableConfig>(request);
  }

  public saveObtainedCredential(obtainedCredential: ObtainedCredential):
    Observable<SystemConfigResponse<ReadableConfig>> {
    const request: SystemConfigRequest = {
      id: obtainedCredential.id,
      obtainedCredentialConfigDTO: obtainedCredential,
      type: SystemConfigType.ObtainedCredential,
    };

    return this.saveOrUpdate<ReadableConfig>(request);
  }

  public saveCountryPrefix(countryPrefix: CountryPrefix): Observable<SystemConfigResponse<CountryPrefix>> {
    const request: SystemConfigRequest = {
      id: countryPrefix.id,
      countryPrefixDTO: countryPrefix,
      type: SystemConfigType.CountryPrefix
    };

    return this.saveOrUpdate<CountryPrefix>(request);
  }

  public getEmailReminder(): Observable<ReminderEmail[]> {
    return this.httpClient.get<ReminderEmail[]>(`/api/system_config?type=${SystemConfigType.ReminderEmail}`);
  }

  public saveEmailReminder(reminder: ReminderEmail): Observable<SystemConfigResponse<ReminderEmail>> {
    const request: SystemConfigRequest = {
      id: reminder.id,
      reminderEmailConfigDTO: reminder,
      type: SystemConfigType.ReminderEmail,
    };

    return this.saveOrUpdate<ReminderEmail>(request);
  }

  public saveSystemDefaultReviewer(user: SystemDefaultUser): Observable<SystemConfigResponse<SystemDefaultUser>> {
    const request: SystemConfigRequest = {
      systemDefaultUserDTO: user,
      type: SystemConfigType.SystemDefaultUser,
    };

    return this.httpClient.put<SystemConfigResponse<SystemDefaultUser>>(`/api/system_config`, request);
  }

  public updateCountriesPrefix(countriesPrefix: CountryPrefix[]): Observable<any> {
    const request: SystemConfigRequest[] = [];
    for (const countryPrefix of countriesPrefix) {
      request.push({
        id: countryPrefix.id,
        countryPrefixDTO: countryPrefix,
        type: SystemConfigType.CountryPrefix,
      });
    }
    return this.httpClient.post<any>(`/api/system_configs`, request);
  }

  public saveProgramDuration(programDuration: ReadableConfig): Observable<SystemConfigResponse<ReadableConfig>> {
    const request: SystemConfigRequest = {
      id: programDuration.id,
      programDurationDTO: programDuration,
      type: SystemConfigType.ProgramDuration
    };

    return this.saveOrUpdate<ReadableConfig>(request);
  }

  public getAreaOfExpertise(): Observable<ReadableConfig[]> {
    return this.httpClient.get<ReadableConfig[]>(`/api/system_config?type=${SystemConfigType.AreaOfExpertise}`)
      .pipe(map((areaOfExpertise) => {
        return sortBy(areaOfExpertise, (study) => study.readableType.toLowerCase());
      }));
  }

  public getFieldOfStudies(): Observable<ReadableConfig[]> {
    return this.httpClient.get<ReadableConfig[]>(`/api/system_config?type=${SystemConfigType.FieldOfStudy}`)
      .pipe(map((fieldOfStudy) => {
        return sortBy(fieldOfStudy, (study) => study.readableType.toLowerCase());
      }));
  }

  public saveFieldOfStudy(field: ReadableConfig): Observable<SystemConfigRequest> {
    const request: SystemConfigRequest = {
      id: field.id,
      fieldOfStudyDTO: field,
      type: SystemConfigType.FieldOfStudy
    };

    return this.saveOrUpdate<ReadableConfig>(request);
  }

  public saveFieldOfStudies(fields: ReadableConfig[]): Observable<any> {
    const request: SystemConfigRequest[] = [];
    fields.forEach(field => request.push({
      id: field.id,
      fieldOfStudyDTO: field,
      type: SystemConfigType.FieldOfStudy
    }));

    return this.httpClient.post<any>(`/api/system_configs`, request);
  }

  public getUniversities(): Observable<University[]> {
    return this.httpClient.get<University[]>(`/api/system_config?type=${SystemConfigType.University}`)
      .pipe(map((universities: any) => {
        return sortBy(universities, (university) => university.readableType.toLowerCase());
      }));
  }

  public saveUniversity(university: University): Observable<SystemConfigResponse<ReadableConfig>> {
    const request: SystemConfigRequest = {
      id: university.id,
      universityDTO: university,
      type: SystemConfigType.University
    };

    return this.saveOrUpdate<ReadableConfig>(request);
  }

  public getBillingRates(): Observable<BillingRate[]> {
    return this.httpClient.get<BillingRate[]>(`/api/system_config?type=${SystemConfigType.Billing}`);
  }

  public getAdsBillingRates(): Observable<AdsBillingRate[]> {
    return this.httpClient.get<AdsBillingRate[]>(`/api/system_config?type=${SystemConfigType.AdsBilling}`);
  }

  public saveBillingRate(data: BillingRate[]): Observable<SystemConfigResponse<BillingRate[]>> {
    const request: SystemConfigRequest = {
      billingRateDTO: data,
      type: SystemConfigType.Billing
    };

    return this.httpClient.put<SystemConfigResponse<BillingRate[]>>(`/api/system_config`, request);
  }

  public saveAdsBillingRate(data: AdsBillingRate[]): Observable<SystemConfigResponse<AdsBillingRate[]>> {
    const request: SystemConfigRequest = {
      adsBillingRateDTO: data,
      type: SystemConfigType.AdsBilling
    };

    return this.httpClient.put<SystemConfigResponse<AdsBillingRate[]>>(`/api/system_config`, request);
  }

  public getExclusionList(): Observable<EmailExclusion[]> {
    return this.httpClient
      .get<EmailExclusion[]>(`/api/system_config?type=${SystemConfigType.ExclusionList}`)
      .pipe(
        map(emailList => emailList.filter(email => email.systemType === SystemType.ParkEval))
      );
  }

  public getExclusionListAds(): Observable<EmailExclusion[]> {
    return this.httpClient
      .get<EmailExclusion[]>(`/api/system_config?type=${SystemConfigType.ExclusionList}`)
      .pipe(
        map(emailList => emailList.filter(email => email.systemType === SystemType.ParkAds))
      );
  }

  public createExclusionList(data: EmailExclusion): Observable<SystemConfigResponse<EmailExclusion>> {
    const request: SystemConfigRequest = {
      emailExclusionDTO: [data],
      type: SystemConfigType.ExclusionList,
    };

    return this.saveOrUpdate<EmailExclusion>(request);
  }

  public saveExclusionList(data: EmailExclusion[]): Observable<SystemConfigResponse<EmailExclusion>> {
    const request: SystemConfigRequest = {
      emailExclusionDTO: data,
      type: SystemConfigType.ExclusionList,
    };

    return this.httpClient.put<SystemConfigResponse<EmailExclusion>>(`/api/system_config`, request);
  }

  public getAdditionalPricing(): Observable<AdditionalPricing[]> {
    return this.httpClient.get<AdditionalPricing[]>(`/api/system_config?type=${SystemConfigType.AdditionalPricing}`);
  }

  public saveAdditionalPricing(data: AdditionalPricing[]): Observable<SystemConfigResponse<AdditionalPricing[]>> {
    const request: SystemConfigRequest = {
      additionalServiceConfigDTO: data,
      type: SystemConfigType.AdditionalPricing
    };

    return this.httpClient.put<SystemConfigResponse<AdditionalPricing[]>>(`/api/system_config`, request);
  }

  public getSystemRoles(): Observable<ReadableConfig[]> {
    return this.httpClient.get<ReadableConfig[]>(`/api/system_config?type=${SystemConfigType.SystemRoles}`);
  }

  public getPermissions(): Observable<ReadableConfig[]> {
    return this.httpClient.get<ReadableConfig[]>(`/api/system_config?type=${SystemConfigType.Permissions}`);
  }

  public getCountries(): Observable<Country[]> {
    return this.httpClient
      .get<Country[]>(`/api/system_config?type=${SystemConfigType.Country}`);
  }

  public getTimezones(): Observable<Timezone[]> {
    return this.httpClient.get<Timezone[]>('/assets/timezones.json');
  }

  public removeConfig(id: number, type: SystemConfigType): Observable<ReadableConfig> {
    return this.httpClient.delete(`/api/system_config/${id}?type=${type}`);
  }

  public getServiceAndWorkflowByServiceId(serviceId: number): Observable<ServiceAndWorkflow> {
    return this.httpClient
      .get<ServiceAndWorkflow>(`/api/system_config/${serviceId}?type=${SystemConfigType.ServiceAndWorkflow}`);
  }

  public getCountryPrefixes(): Observable<CountryPrefix[]> {
    return this.httpClient
      .get<CountryPrefix[]>(`/api/system_config?type=${SystemConfigType.CountryPrefix}`);
  }

  public getProgramDurations(): Observable<ReadableConfig[]> {
    return this.httpClient
      .get<ReadableConfig[]>(`/api/system_config?type=${SystemConfigType.ProgramDuration}`)
      .pipe(
        map(values => {
          return sortByString(values, 'readableType');
        })
      );
  }

  public getMappedFields(): Observable<ReadableConfig[]> {
    return this.httpClient
      .get<ReadableConfig[]>(`/api/system_config?type=${SystemConfigType.MappedField}`);
  }

  public getDefaultReviewer(): Observable<SystemDefaultUser[]> {
    return this.httpClient
      .get<SystemDefaultUser[]>(`/api/system_config?type=${SystemConfigType.SystemDefaultUser}`);
  }

  private saveOrUpdate<T>(data: SystemConfigRequest): Observable<SystemConfigResponse<T>> {
    if (data.id) {
      return this.httpClient.put<SystemConfigResponse<T>>(`/api/system_config`, data);
    }

    return this.httpClient.post<SystemConfigResponse<T>>(`/api/system_config`, data);
  }

  public getErrorTypes(stage: ServiceStageValue): Observable<ErrorType[]> {
    return this.httpClient.get<ErrorType[]>(`/api/system_config/error_type?stage=${stage}`);
  }

  public getSalesPersons(): Observable<SalesPerson[]> {
    return this.httpClient.get<SalesPerson[]>(`/api/system_config?type=${SystemConfigType.SalesPerson}`)
      .pipe(
        map((persons) => {
          return sortBy(persons, (person: SalesPerson) => person.fullname.toUpperCase());
        }
      ));
  }

  public saveSalesPerson(person: SalesPerson): Observable<SystemConfigResponse<SalesPerson>> {
    const request: SystemConfigRequest = {
      id: person.id,
      salesPersonDTO: person,
      type: SystemConfigType.SalesPerson,
    };

    return this.saveOrUpdate(request);
  }

  public getSwas(serviceableOnly: boolean = false): Observable<SwaSite[]> {
    return this.httpClient.get<SwaSite[]>(`/api/system_config?type=${SystemConfigType.Swa}`)
      .pipe(
        switchMap(sites => {
          if (this.swaSites) {
            return of(clone(this.swaSites));
          }

          return of(sites);
        }),
        map((sites) => {
          this.swaSites = sites;
          let filteredSites: SwaSite[] = sites;

          if (serviceableOnly) {
            filteredSites = this.swaSites.filter(site => site.serviceable);
          }

          return sortBy(filteredSites, (site: SwaSite) => {
            if (site.readableType) {
              return site.readableType.toUpperCase();
            }

            return '';
          });
          }
        ));
  }

  public saveSwaSite(site: SwaSite): Observable<SystemConfigResponse<ReadableConfig>> {
    const request: SystemConfigRequest = {
      id: site.id,
      swaStateDTO: site,
      type: SystemConfigType.Swa
    };

    return this.saveOrUpdate<ReadableConfig>(request);
  }

  public getOnlineConsumerSites(
    activeOnly: boolean = true,
    includeInactiveId: number = null
  ): Observable<OnlineConsumerSite[]> {
    return this.httpClient.get<OnlineConsumerSite[]>(`/api/system_config?type=${SystemConfigType.OnlineConsumerSite}`)
      .pipe(
        map((sites) => {
          if (activeOnly) {
            // We must negate deleteInd since it is an inverted boolean
            sites = sites.filter(journal => !journal.deleteInd || journal.id === includeInactiveId);
          }

          return sortBy(sites, (site: OnlineConsumerSite) => {
              if (!site.name) {
                return;
              }
              return site.name.toUpperCase();
            });
          }
        ));
  }

  public saveOnlineConsumerSite(site: OnlineConsumerSite): Observable<SystemConfigResponse<OnlineConsumerSite>> {
    const request: SystemConfigRequest = {
      id: site.id,
      onlineConsumerSiteConfigDTO: site,
      type: SystemConfigType.OnlineConsumerSite
    };

    return this.saveOrUpdate<OnlineConsumerSite>(request);
  }

  public getOnlineTradeJournals(
    activeOnly: boolean = true,
    includeInactiveId: number = null
  ): Observable<OnlineTradeJournal[]> {
    return this.httpClient.get<OnlineTradeJournal[]>(`/api/system_config?type=${SystemConfigType.OnlineTradeJournal}`)
      .pipe(
        map((data) => {
          if (activeOnly) {
            // Must negate since deleteInd is an inverted boolean
            data = data.filter(journal => !journal.deleteInd || journal.id === includeInactiveId);
          }

          return sortBy(data, (site: OnlineTradeJournal) => {
            if (!site.name) {
              return '';
            }
            return site.name.toUpperCase();
          });
        }));
  }

  public saveOnlineTradeJournal(data: OnlineTradeJournal): Observable<SystemConfigResponse<OnlineTradeJournal>> {
    const request: SystemConfigRequest = {
      id: data.id,
      onlineTradeJournalConfigDTO: data,
      type: SystemConfigType.OnlineTradeJournal
    };

    return this.saveOrUpdate<OnlineTradeJournal>(request);
  }

  public getParamConfig(subType?: ParamConfigType): Observable<ParamConfig[]> {

    if (subType) {
      return this.httpClient
        .get<ParamConfig[]>(`/api/system_config?type=${SystemConfigType.ParamConfig}&subType=${subType}`);
    }

    return this.httpClient
      .get<ParamConfig[]>(`/api/system_config?type=${SystemConfigType.ParamConfig}`);
  }

  public saveParamConfig(paramConfig: ParamConfig): Observable<SystemConfigResponse<ParamConfig>> {
    const request: SystemConfigRequest = {
      id: paramConfig.id,
      paramConfigDTO: paramConfig,
      type: SystemConfigType.ParamConfig
    };

    return this.saveOrUpdate<ParamConfig>(request);
  }

  public getPaginatedAcademicEquivalencies(
    request: PaginatedData<AcademicEquivalencyMapping>,
    opts?: { loaderIgnoreOnce?: boolean }
  ): Observable<PaginatedData<AcademicEquivalencyMapping>> {
    const url = `/api/system-config/paginated?type=${SystemConfigType.AcademicEquivalency}`;

    if (opts && opts.loaderIgnoreOnce) {
      this.globalLoader.ignoreOnce(url);
    }

    return this.httpClient
      .post<any>(url, request.toObject())
      .pipe(
        // Update the given pagination object with values from the response.
        map((response: PaginateObject<any>) => request.update(response))
      );
  }

  public getAcademicEquivalencyById(id: number): Observable<ReadableConfig> {
    return this.httpClient
      .get<ReadableConfig>(`/api/system_config/${id}?type=${SystemConfigType.AcademicEquivalency}`);
  }

  public getPaginatedObtainedCredential(
    request: PaginatedData<ObtainedCredentialPaginated>
  ): Observable<PaginatedData<ObtainedCredentialPaginated>> {
    return this.httpClient
      .post<any>(
        `/api/system-config/paginated?type=${SystemConfigType.ObtainedCredential}`, request.toObject())
      .pipe(
        // Update the given pagination object with values from the response.
        map((response: PaginateObject<any>) => request.update(response))
      );
  }

  public getObtainedCredentialById(id: number): Observable<ObtainedCredential> {
    return this.httpClient
      .get<ObtainedCredential>(`/api/system_config/${id}?type=${SystemConfigType.ObtainedCredential}`);
  }

  public getObtainedCredentials(): Observable<ObtainedCredential[]> {
    return this.httpClient
      .get<ObtainedCredential[]>(`/api/system_config/?type=${SystemConfigType.ObtainedCredential}`);
  }

  public isAcademicEquivalencyDegreeDuplicate(newDegree: string, currentDegree?: ReadableConfig): Observable<boolean> {
    if (currentDegree && newDegree.toLowerCase() === currentDegree.readableType.toLowerCase()) {
      return of(false);
    }

    // If different from the original value, check if there is a duplicate
    const paginatedData = new PaginatedData<AcademicEquivalencyMapping>();
    paginatedData.size = PAGINATE_NO_LIMIT;
    paginatedData.query = RsqlEncoder.combine([
      RsqlEncoder.encode({
        field: 'deleteInd',
        type: FieldType.Boolean,
        value: false,
        operator: Operators.common.$eq
      }),
      RsqlEncoder.encode({
        field: 'degree',
        type: FieldType.String,
        value: newDegree,
        operator: Operators.common.$eq
      })
    ]);

    return this.getPaginatedAcademicEquivalencies(paginatedData, {loaderIgnoreOnce: true})
      .pipe(
        switchMap(data => {
          return of(data.content.length > 0);
        }),
      );
  }

  public getSwaRequiredFields(): Observable<SwaStateRequirements[]> {
    return this.httpClient
      .get<SwaStateRequirements[]>(`/api/system_config/?type=${SystemConfigType.SwaStateRequirements}`)
      .pipe(
        switchMap(reqs => {
          if (this.swaStateRequirements) {
            return of(clone(this.swaStateRequirements));
          }

          return of(reqs);
        }),
        tap((reqs) => this.swaStateRequirements = reqs)
      );
  }

  public getSwaRequiredFieldsByState(state: string): Observable<SwaStateRequirements> {
    return this.getSwaRequiredFields()
      .pipe(
        switchMap((reqs) => {
            return this.getSwas();
        }),
        switchMap(() => {
          const targetState = this.swaSites
            .find(swa => swa.readableType === state);
          return of(this.swaStateRequirements.find(rq => rq.stateId === targetState.id));
        })
      );
  }
}
