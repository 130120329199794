import { zip } from 'rxjs';
import { WorkflowService } from '../services/data/workflow.service';
import { mapTo } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../services/data/workflow.service";
var StartupEssentialsGuard = /** @class */ (function () {
    function StartupEssentialsGuard(workflowService) {
        this.workflowService = workflowService;
    }
    StartupEssentialsGuard.prototype.canActivate = function (next, state) {
        return zip(this.workflowService.init())
            .pipe(mapTo(true));
    };
    StartupEssentialsGuard.ngInjectableDef = i0.defineInjectable({ factory: function StartupEssentialsGuard_Factory() { return new StartupEssentialsGuard(i0.inject(i1.WorkflowService)); }, token: StartupEssentialsGuard, providedIn: "root" });
    return StartupEssentialsGuard;
}());
export { StartupEssentialsGuard };
