import { isNullOrUndefined } from 'util';

/**
 * Creates a full name based on the object.
 * It should have a firstName and lastName property
 * @param obj
 */
export function fullName(obj: any): string {
  if (isNullOrUndefined(obj)) {
    return '';
  }

  const name = obj.firstName + ' ' + obj.lastName;

  return name.trim();
}
