import * as tslib_1 from "tslib";
import { of, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ModelDataUpdate } from './data-update';
import { switchMap, tap } from 'rxjs/operators';
import { sortBy } from '../../utils/sort-by';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var OrganizationModelUpdate = /** @class */ (function (_super) {
    tslib_1.__extends(OrganizationModelUpdate, _super);
    function OrganizationModelUpdate() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return OrganizationModelUpdate;
}(ModelDataUpdate));
export { OrganizationModelUpdate };
var InternalOrganizationService = /** @class */ (function () {
    function InternalOrganizationService(httpClient) {
        this.httpClient = httpClient;
        this.updates = new Subject();
    }
    InternalOrganizationService.prototype.get = function (id) {
        return this.httpClient
            .get("/api/internal-organization");
    };
    InternalOrganizationService.prototype.getUser = function (id) {
        return this.httpClient
            .get("/api/internal-organization/user/" + id);
    };
    InternalOrganizationService.prototype.getUsers = function () {
        return this.httpClient
            .get('/api/internal-organization/users')
            .pipe(switchMap(function (users) {
            return of(sortBy(users, function (user) {
                return user.firstName + ' ' + user.lastName;
            }));
        }));
    };
    InternalOrganizationService.prototype.getAssignableUsers = function (permission) {
        var url = permission ? "/api/internal-organization/assignable-users/permission/" + permission :
            '/api/internal-organization/assignable-users';
        return this.httpClient
            .get(url)
            .pipe(switchMap(function (users) {
            return of(sortBy(users, function (user) {
                return (user.firstName + ' ' + user.lastName).toLowerCase();
            }));
        }));
    };
    InternalOrganizationService.prototype.getAssignableUsersByRoleAndPermission = function (role, permission) {
        var url = permission ? "/api/internal-organization/assignable-users/role/" + role + "/permission/" + permission :
            '/api/internal-organization/assignable-users';
        return this.httpClient
            .get(url)
            .pipe(switchMap(function (users) {
            return of(sortBy(users, function (user) {
                return (user.firstName + ' ' + user.lastName).toLowerCase();
            }));
        }));
    };
    InternalOrganizationService.prototype.saveUser = function (data) {
        var _this = this;
        if (data.id) {
            return this.httpClient
                .put("/api/internal-organization/user/" + data.id, data)
                .pipe(tap(function (res) { return _this.updates.next(new OrganizationModelUpdate(res)); }));
        }
        return this.httpClient
            .post('/api/internal-organization/user', data);
    };
    InternalOrganizationService.prototype.addAdmin = function (data) {
        return this.httpClient
            .post("/api/internal-organization/user/" + data.id + "/add-admin", data);
    };
    InternalOrganizationService.prototype.removeAdmin = function (data) {
        return this.httpClient
            .post("/api/internal-organization/user/" + data.id + "/remove-admin", data);
    };
    InternalOrganizationService.ngInjectableDef = i0.defineInjectable({ factory: function InternalOrganizationService_Factory() { return new InternalOrganizationService(i0.inject(i1.HttpClient)); }, token: InternalOrganizationService, providedIn: "root" });
    return InternalOrganizationService;
}());
export { InternalOrganizationService };
