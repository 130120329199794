import { MessageService } from 'primeng/api';
import * as i0 from "@angular/core";
import * as i1 from "primeng/components/common/messageservice";
export var ToastSeverity;
(function (ToastSeverity) {
    ToastSeverity["Success"] = "success";
    ToastSeverity["Info"] = "info";
    ToastSeverity["Warn"] = "warn";
    ToastSeverity["Error"] = "error";
})(ToastSeverity || (ToastSeverity = {}));
var ToastService = /** @class */ (function () {
    function ToastService(messageService) {
        this.messageService = messageService;
    }
    ToastService.prototype.add = function (severity, message, title) {
        var srcMsg = { severity: severity, detail: message, summary: title };
        this.messageService.add(srcMsg);
    };
    /**
     * Shorthand for generic success message
     */
    ToastService.prototype.addSuccess = function (msg) {
        this.add(ToastSeverity.Success, msg ? msg : 'Saved Successfully');
    };
    /**
     * Shorthand for generic error message
     */
    ToastService.prototype.addError = function (msg) {
        this.add(ToastSeverity.Error, msg ? msg : 'Operation Failed');
    };
    ToastService.ngInjectableDef = i0.defineInjectable({ factory: function ToastService_Factory() { return new ToastService(i0.inject(i1.MessageService)); }, token: ToastService, providedIn: "root" });
    return ToastService;
}());
export { ToastService };
