import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var MainShellService = /** @class */ (function () {
    function MainShellService() {
        this.shellVisibility = new BehaviorSubject(true);
    }
    MainShellService.prototype.hide = function () {
        this.shellVisibility.next(false);
    };
    MainShellService.prototype.show = function () {
        this.shellVisibility.next(true);
    };
    MainShellService.ngInjectableDef = i0.defineInjectable({ factory: function MainShellService_Factory() { return new MainShellService(); }, token: MainShellService, providedIn: "root" });
    return MainShellService;
}());
export { MainShellService };
