<div class="bg-white">
  <div class="container pt-4">
    <img src="assets/park-evaluations-logo.svg" align="Park Evaluation Logo" width="170">
  </div>

  <hr class="mb-0" />
</div>

<!-- Public Shell -->
<router-outlet></router-outlet>
