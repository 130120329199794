/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error-maintenance.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./error-maintenance.component";
var styles_ErrorMaintenanceComponent = [i0.styles];
var RenderType_ErrorMaintenanceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ErrorMaintenanceComponent, data: {} });
export { RenderType_ErrorMaintenanceComponent as RenderType_ErrorMaintenanceComponent };
export function View_ErrorMaintenanceComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "text-center pt-3 bg-white"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["src", "assets/park-evaluations-logo.svg"], ["width", "160"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 14, "div", [["class", "page-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 13, "div", [["class", "page-content text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["src", "/assets/images/error-page-icons/maintenance.svg"], ["width", "400px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 10, "div", [["class", "message-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h1", [["class", "title mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["BIG CHANGES HAPPENING"])), (_l()(), i1.ɵeld(10, 0, null, null, 3, "h3", [["class", "message mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Sorry, we are currently enhancing the system."])), (_l()(), i1.ɵeld(12, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" We should be back up and running in just a few hours. "])), (_l()(), i1.ɵeld(14, 0, null, null, 3, "h3", [["class", "message mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Please come back shortly to access the system"])), (_l()(), i1.ɵeld(16, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" and see what we have upgraded. "]))], null, null); }
export function View_ErrorMaintenanceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-error-maintenance", [], null, null, null, View_ErrorMaintenanceComponent_0, RenderType_ErrorMaintenanceComponent)), i1.ɵdid(1, 114688, null, 0, i2.ErrorMaintenanceComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ErrorMaintenanceComponentNgFactory = i1.ɵccf("app-error-maintenance", i2.ErrorMaintenanceComponent, View_ErrorMaintenanceComponent_Host_0, {}, {}, []);
export { ErrorMaintenanceComponentNgFactory as ErrorMaintenanceComponentNgFactory };
