import { AgmCoreModule } from '@agm/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorsModule } from './errors/errors.module';
import { PublicModule } from './public/public.module';
import { HttpClientModule } from '@angular/common/http';
import { GuardsModule } from './guards/guards.module';
import { ServicesModule } from './services/services.module';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { InterceptorsModule } from './interceptors/interceptors.module';
import { ToastModule } from 'primeng/toast';
import { UnsavedChangesModalModule } from './main/shared/unsaved-changes-modal/unsaved-changes-modal.module';
import { PasswordResetModule } from './public/password-reset/password-reset.module';
import { OfflineScreenModule } from './main/offline-screen/offline-screen.module';
import { TermsOverlayModule } from './main/terms-overlay/terms-overlay.module';
import { RedirectModule } from './main/shared/redirect/redirect.module';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { InstanceSelectorModule } from './instance-selector/instance-selector.module';
import { PopoverModule } from 'ngx-bootstrap/popover';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAGuWYDsGeVymk3aPRtgTP3hl_OuwfcFKg',
      libraries: ['places']
    }),
    ErrorsModule,
    PublicModule,
    GuardsModule,
    ServicesModule,
    InterceptorsModule,
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    ButtonsModule.forRoot(),
    AccordionModule.forRoot(),
    PopoverModule.forRoot(),
    ToastModule,
    UnsavedChangesModalModule,
    PasswordResetModule,
    OfflineScreenModule,
    TermsOverlayModule,
    RedirectModule,
    MonacoEditorModule.forRoot(),
    InstanceSelectorModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
