import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import {  tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate, CanLoad {

  constructor(private authService: AuthService, private router: Router) { }

  /**
   * Checks for user session and redirects to login if none.
   */
  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService
      .hasValidSession()
      .pipe(
        tap((session) => {
          if(!session) {
            this.router.navigate(['login']);
          }
        })
      );
  }

  /**
   * Handles whether the routing system can load the async module route
   */
  public canLoad(): Observable<boolean> {
    return this.authService
      .hasValidSession()
      .pipe(
        tap((session) => {
          if (!session) {
            this.router.navigate(['login']);
          }
        })
      );
  }
}
