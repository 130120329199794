import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { GlobalLoaderService } from '../services/global-loader.service';

@Injectable()
export class GlobalLoaderInterceptor implements HttpInterceptor {

  private ignoredUrls: string[] = [
    '/api/public/validateEmail/',
    '/api/company/name/',
    'api/media-vendor/name',
    // '/api/case/service/paginated',
    '/api/case/service/counts',
    // '/api/case/paginated',
    '/api/case/counts',
    '/assets/alive.txt',
    '/api/park-ads/case/counts',
    '/api/park-ads/case/service/counts',
  ];

  constructor(private globalLoader: GlobalLoaderService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // Trigger the global loader
    const ignored = this.ignoredUrls.some(url => request.url.startsWith(url));
    if (!ignored && !this.globalLoader.hasIgnoreOnce(request.url)) {
      this.globalLoader.triggerAuto();
    }

    return next.handle(request)
      .pipe(
        tap((event) => {
          // NOTE: Logic moved to "finalize" to handle canceled events
          // console.log('http event:', event);
          //
          // // Success requests
          // if (event instanceof HttpResponse) {
          //   this.globalLoader.completeAuto();
          //   return;
          // }
          //
          // if (event instanceof HttpErrorResponse) {
          //   this.globalLoader.completeAuto();
          //   return;
          // }
        }, () => {
          // Request failed!
          // this.globalLoader.completeAuto();
        }),
        finalize(() => {
          if (!ignored && !this.globalLoader.hasIgnoreOnce(request.url)) {
            this.globalLoader.completeAuto();
            this.globalLoader.cleanupIgnoreOnce(request.url);
          }
        })
      );
  }
}
