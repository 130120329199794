import { Injectable } from '@angular/core';
import { MediaVendor } from '../../api/vendor/media-vendor';
import { map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PaginatedData, PaginateObject } from '../../api/pagination/paginated-data';
import { MediaVendorPaginated } from '../../api/vendor/media-vendor-paginated';
import { sortBy } from '../../utils/sort-by';
import { fullName } from '../../utils/full-name';
import { User } from '../../api/user/user';
import { Pricing } from '../../api/organization/pricing';
import { FileSystemKey } from '../../api/file-system-key';
import { MediaVendorLite } from '../../api/vendor/media-vendor-lite';
import { CompanyBilling } from '../../api/organization/company-billing';
import { PricingPreference } from '../../api/organization/pricing-preference';
import { BillingType } from '../../api/billing/billing-type';
import { QuickbookPreference } from '../../api/vendor/quickbook-preference';
import { MediaReminderEmail } from '../../api/vendor/media-reminder-email';

@Injectable({
  providedIn: 'root'
})
export class VendorService {

  constructor(private httpClient: HttpClient) {
  }

  public save(data: MediaVendor): Observable<MediaVendor> {
    // Trim whitespace
    data.name = data.name.trim();

    if (data.id) {
      return this.httpClient
        .put<MediaVendor>(`/api/media-vendor/general-info/${data.id}`, data)
        .pipe(
          tap((res) => {
            // Nothing here for now, just testing.
          })
        );
    }

    return this.httpClient.post<MediaVendor>(`/api/media-vendor`, data);
  }

  public getAllPaginated(request: PaginatedData<MediaVendorPaginated>)
    : Observable<PaginatedData<MediaVendorPaginated>> {
    return this.httpClient
      .post<any>(`/api/media-vendor/paginated`, request.toObject())
      .pipe(
        // Update the given pagination object with values from the response.
        map((response: PaginateObject<MediaVendorPaginated>) => request.update(response))
      );
  }

  public getAll(): Observable<MediaVendor[]> {
    return this.httpClient
      .get<MediaVendor[]>(`/api/media-vendor`)
      .pipe(
        map(vendors => sortBy(vendors, 'name'))
      );
  }

  public get(id: number): Observable<MediaVendor> {
    return this.httpClient.get<MediaVendor>(`/api/media-vendor/${id}`)
      .pipe(
        tap((data) => {
          data.organizationUsers = sortBy(data.organizationUsers, (user) => {
            return fullName(user)
              .toLowerCase();
          });
        })
      );
  }

  public getPrintAds(vendorId: number): Observable<Pricing> {
    return this.httpClient.get<Pricing>(`/api/media-vendor/${vendorId}/print-ad`);
  }

  public updatePrintAds(vendorId: number, data: Pricing): Observable<MediaVendor> {
    return this.httpClient
      .put<MediaVendor>(`/api/media-vendor/${vendorId}/print-ad`, data);
  }

  public saveMediaContact(vendorId: number, data: User): Observable<MediaVendor> {
    if (data.id) {
      return this.httpClient.put<MediaVendor>(`/api/media-vendor/${vendorId}/contact`, data);
    } else {
      return this.httpClient.post<MediaVendor>(`/api/media-vendor/${vendorId}/contact`, data);
    }
  }

  public toggleContactActive(userId: number): Observable<User> {
    return this.httpClient.put<User>(`/api/media-vendor/contact/${userId}/active`, null);
  }

  public getRadioPricing(vendorId: number): Observable<Pricing> {
    return this.httpClient.get<Pricing>(`/api/media-vendor/${vendorId}/radio-pricing`);
  }

  public updateRadioPricing(vendorId: number, data: Pricing): Observable<Pricing> {
    return this.httpClient.put<Pricing>(`/api/media-vendor/${vendorId}/radio-pricing`, data);
  }

  public getAllByAdsServiceId(
    serviceId: number,
    activeOnly: boolean = true,
    includeInactiveId: number = null,
    ): Observable<MediaVendor[]> {
    return this.httpClient
      .get<MediaVendor[]>(`/api/media-vendor/ads-service/${serviceId}`)
      .pipe(
        map(vendors => {
          if (activeOnly) {
            vendors = vendors.filter(vendor => vendor.active || vendor.id === includeInactiveId);
          }

          const sortedVendors = sortBy(vendors, (vendor: MediaVendor) => {
            return vendor.name.toUpperCase();
          });

          for (const vendor of sortedVendors) {
            vendor.organizationUsers = sortBy(vendor.organizationUsers, (user: User) => {
              return fullName(user)
                .trim()
                .toUpperCase();
            });
          }
          return sortedVendors;
        })
      );
  }

  public getAllLiteByAdsServiceId(
    serviceId: number,
    activeOnly: boolean = true,
    preselectedMediaVendor?: MediaVendor): Observable<MediaVendorLite[]> {
    return this.httpClient
      .get<MediaVendor[]>(`/api/media-vendor/lite/ads-service/${serviceId}?isActive=${activeOnly}`)
      .pipe(
        // push the preselected vendor that is no longer active.
        tap(vendors => {
          if (!vendors || !vendors.length || !preselectedMediaVendor) {
            return;
          }

          const vendorExists = vendors.some(vendor => vendor.id === preselectedMediaVendor.id);
          if (!vendorExists && activeOnly) {
            vendors.push(preselectedMediaVendor);
          }
        }),
        map(vendors => sortBy(vendors, (vendor: MediaVendorLite) => vendor.name ? vendor.name.toLowerCase() : ''))
      );
  }

  public addFiles(id: number, fileSystemKeyIds: number[]): Observable<MediaVendor> {
    return this.httpClient.post<MediaVendor>(`/api/media-vendor/${id}/files/`, fileSystemKeyIds);
  }

  public removeFiles(id: number, fileSystemKeyIds: number[]): Observable<MediaVendor> {
    return this.httpClient.post<MediaVendor>(`/api/media-vendor/${id}/files/delete`, fileSystemKeyIds);
  }

  public getAffidavitFile(id: number): Observable<FileSystemKey> {
    return this.httpClient.get<FileSystemKey>(`/api/media-vendor/${id}/affidavit`);
  }

  public updateAffidavitFile(id: number, file: FileSystemKey): Observable<MediaVendor> {
    return this.httpClient.put<MediaVendor>(`/api/media-vendor/${id}/affidavit`, file);
  }

  public getBilling(id: number): Observable<CompanyBilling> {
    return this.httpClient.get<CompanyBilling>(`/api/media-vendor/${id}/billing`);
  }

  public setQuickbooksPreference(
    mediaVendorId: number,
    quickBookPreference: QuickbookPreference
  ): Observable<MediaVendor> {
    return this.httpClient
      .put<MediaVendor>(
        `/api/media-vendor/${mediaVendorId}/qbid/sync-quickbooks-preference`,
        quickBookPreference);
  }

  public updateBillingType(mediaVendorId: number,
                           billingType: BillingType): Observable<MediaVendor> {
    return this.httpClient
      .put<MediaVendor>(
        `/api/media-vendor/${mediaVendorId}/billing-type/${billingType}`,
        null);
  }

  public getBillingType(mediaVendorId: number): Observable<BillingType> {
    return this.httpClient
      .get<BillingType>(
        `/api/media-vendor/${mediaVendorId}/billing-type`);
  }

  public updateBillingAddress(id: number, billing: CompanyBilling): Observable<CompanyBilling> {
    return this.httpClient.put<CompanyBilling>(`/api/media-vendor/${id}/billing`, billing);
  }

  public getNewspaperServices(mediaVendorId: number): Observable<Pricing> {
    return this.httpClient.get<Pricing>(`/api/media-vendor/${mediaVendorId}/newspaper-services`);
  }

  public updateNewspaperServices(mediaVendorId: number, data: Pricing): Observable<Pricing> {
    return this.httpClient.put<Pricing>(`/api/media-vendor/${mediaVendorId}/newspaper-services`, data);
  }

  public updateQuickBooksId(mediaVendorId: number, quickBooksId: string): Observable<MediaVendor> {
    return this.httpClient.put<MediaVendor>(`/api/media-vendor/${mediaVendorId}/qbid/${quickBooksId}`, null);
  }

  public isExisting(name: string): Observable<boolean> {
    return this.httpClient.get<boolean>(`api/media-vendor/name/${name}`);
  }

  public updatePricingPreference(mediaVendorId: number, data: Pricing): Observable<Pricing> {
    return this.httpClient.put<Pricing>(`/api/media-vendor/${mediaVendorId}/pricing-preference`, data);
  }

  public getPricingPreference(mediaVendorId: number): Observable<PricingPreference> {
    return this.httpClient.get<PricingPreference>(`/api/media-vendor/${mediaVendorId}/pricing-preference`);
  }

  public updateVisibilityToClient(mediaVendorId: number, visibility: boolean): Observable<MediaVendor> {
    return this.httpClient
      .put<MediaVendor>(`/api/media-vendor/${mediaVendorId}/visible-to-clients/${visibility}`, null);
  }

  public updateCreditCardStoragePreference(mediaVendorId: number, ccInfoStored: boolean): Observable<MediaVendor> {
    return this.httpClient
      .put<MediaVendor>(`/api/media-vendor/${mediaVendorId}/cc-info-stored/${ccInfoStored}`, null);
  }

  public getReminderEmail(mediaVendorId: number): Observable<MediaReminderEmail> {
    return this.httpClient.get<MediaReminderEmail>(`api/media-vendor/${mediaVendorId}/reminder-email`);
  }

  public updateReminderEmail(mediaVendorId: number, reminderEmail: MediaReminderEmail): Observable<MediaVendor> {
    return this.httpClient.put<MediaVendor>(`api/media-vendor/${mediaVendorId}/reminder-email`, reminderEmail);
  }

  public updateRequireReferenceNumber(mediaVendorId: number, require: boolean): Observable<MediaReminderEmail> {
    return this.httpClient
      .put<MediaReminderEmail>(`api/media-vendor/${mediaVendorId}/require-vendor-reference-number/${require}`, null);
  }
}
