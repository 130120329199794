import { NavigationEnd, NavigationError, Router } from '@angular/router';
import * as rg4js from 'raygun4js';
import { environment } from '../../environments/environment';
import { LoggerService } from './logger.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./logger.service";
var RaygunService = /** @class */ (function () {
    function RaygunService(router, logger) {
        this.router = router;
        this.logger = logger;
        this.setup();
    }
    RaygunService.prototype.setup = function () {
        // NOTE: Tracking must only be enabled on production servers.
        if (!environment.production) {
            this.logger.log('Raygun NOT initialized');
            return;
        }
        this.logger.log('Initializing raygun');
        rg4js('apiKey', environment.raygunApiKey);
        rg4js('setVersion', environment.version);
        rg4js('enableCrashReporting', true);
        rg4js('enablePulse', true);
        this.router
            .events
            .subscribe(function (event) {
            if (event instanceof NavigationEnd) {
                // Track navigation end
                rg4js('trackEvent', {
                    type: 'pageView',
                    path: event.url
                });
            }
            else if (event instanceof NavigationError) {
                // Track navigation error
                rg4js('send', {
                    error: event.error
                });
            }
        });
    };
    RaygunService.prototype.setUser = function (user) {
        if (!environment.production) {
            return;
        }
        if (!user) {
            return;
        }
        this.logger.log("Setting up user for Raygun: " + user.email);
        rg4js('setUser', {
            identifier: user.id,
            isAnonymous: false,
            email: user.email,
            firstName: user.firstName,
            fullName: user.firstName + ' ' + user.lastName
        });
    };
    RaygunService.prototype.send = function (error, customData) {
        if (!environment.production) {
            return;
        }
        this.logger.log('Sending Raygun Error:');
        this.logger.log(error);
        this.logger.log(customData);
        if (customData) {
            rg4js('send', { error: error, customData: customData });
        }
        else {
            rg4js('send', { error: error });
        }
    };
    RaygunService.ngInjectableDef = i0.defineInjectable({ factory: function RaygunService_Factory() { return new RaygunService(i0.inject(i1.Router), i0.inject(i2.LoggerService)); }, token: RaygunService, providedIn: "root" });
    return RaygunService;
}());
export { RaygunService };
