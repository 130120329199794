import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class GlobalLoaderService {

  public loaderStatus = new BehaviorSubject<number>(0);

  private ignoreOnceList = [];

  constructor() { }

  public triggerAuto(): void {
    const current = this.loaderStatus.getValue();
    const next = current + 1;
    this.loaderStatus.next(next);
  }

  public completeAuto(): void {
    const current = this.loaderStatus.getValue();
    let next = current - 1;

    // Making sure it never goes below zero.
    if (next < 0) {
      next = 0;
    }

    // console.log('Cleared to the loader queue!', next);
    this.loaderStatus.next(next);
  }

  public ignoreOnce(url: string): void {
    this.ignoreOnceList.push(url);
  }

  public cleanupIgnoreOnce(url: string): void {
    this.ignoreOnceList = this.ignoreOnceList.filter(eIgnoreUrl => eIgnoreUrl !== url);
  }

  public hasIgnoreOnce(url: string): boolean {
    return !!this.ignoreOnceList.find(ignore => ignore === url);
  }
}
