import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SystemTermsService = /** @class */ (function () {
    function SystemTermsService(httpClient) {
        this.httpClient = httpClient;
    }
    SystemTermsService.prototype.get = function (organizationId) {
        return this.httpClient.get("/api/term/organization/" + organizationId);
    };
    SystemTermsService.prototype.bypass = function (termId, bypass, reason) {
        return this.httpClient.post("/api/term/" + termId + "/bypass/" + bypass, reason);
    };
    /**
     * Use this to accept a term as PM INTERNAL USER (for now)
     * @param data
     */
    SystemTermsService.prototype.update = function (data) {
        return this.httpClient.post("/api/term/" + data.id + "/update", data);
    };
    /**
     * Not sure what this is for yet.
     * @param type
     * @param organizationId
     */
    SystemTermsService.prototype.accept = function (type, organizationId) {
        return this.httpClient.post("/api/term/" + type + "/accept/" + organizationId, null);
    };
    /**
     * Used by non-internal.
     */
    SystemTermsService.prototype.acceptForExpert = function () {
        return this.httpClient.post("/api/term/accept", null);
    };
    SystemTermsService.ngInjectableDef = i0.defineInjectable({ factory: function SystemTermsService_Factory() { return new SystemTermsService(i0.inject(i1.HttpClient)); }, token: SystemTermsService, providedIn: "root" });
    return SystemTermsService;
}());
export { SystemTermsService };
