import { clone } from '../../utils/clone';
import { includes } from '../../utils/includes';
import { FilterSet } from './filter-set';
import { FieldType } from './field-type';
import { Operators } from './filter-operators';
import { isArray, isDate, isNullOrUndefined } from 'util';
import { isNullOrUndefinedOrBlank } from '../../utils/is-null-or-undefined';
import * as moment from 'moment';
import { uniq } from '../../utils/uniq';

/**
 * Handles encoding of FilterSet objects to RSQL string
 */
export class RsqlEncoder {

  /**
   * Utility function that combines all the queries by the specified operator.
   * @param queries
   * @param operator
   */
  public static combine(queries: string[], operator: 'and' | 'or' = 'and'): string {
    // const combined = queries.join(` ${operator} `);
    let combined = '';

    for (const query of queries) {
      if (isNullOrUndefined(query) || query === '') {
        continue;
      }

      combined += `(${query}) ${operator} `;
    }

    // Trim extra whitespace from generation
    combined = combined.trim();

    // The last operator gets trimmed;
    return combined
      .substring(0, combined.length - operator.length)
      .trim();
  }

  constructor() {

  }

  public static encode(filter: FilterSet): string {
    return RsqlEncoder.encodeGroup([filter]);
  }

  public static encodeGroup(filters: FilterSet[], mergeOperator: 'and' | 'or' = 'and'): string {
    if (!filters || filters.length === 0) {
      return '';
    }

    let rsql = '';

    for (const filter of filters) {
      const operator = RsqlEncoder.getOperator(filter);
      if (!operator) {
        continue;
      }

      rsql += `${operator} ${mergeOperator} `;
    }

    // Trim extra whitespace from generation
    rsql = rsql.trim();

    // The last operator gets trimmed;
    return rsql
      .substring(0, rsql.length - mergeOperator.length)
      .trim();
  }

  /**
   * Gets the equivalent RSQL operator for the give filter sets...
   * @param sourceFilter
   */
  private static getOperator(sourceFilter: FilterSet): string {
    // Run transformations first for certain data types
    const filter = RsqlEncoder.runTransformations(sourceFilter);

    // ---- COMMON OPERATORS ----
    const commonOperators = [
      Operators.common.$eq,   // equal
      Operators.common.$neq,  // not equal
      Operators.common.$gt,   // greater than
      Operators.common.$gte,  // greater than or equal
      Operators.common.$lt,   // less than
      Operators.common.$lte   // less than or equal
    ];
    if (includes(commonOperators, filter.operator)) {
      const value = filter.type === FieldType.String ? `'${RsqlEncoder.escapeString(filter.value)}'` : filter.value;
      return `${filter.field}${filter.operator}${value}`;
    }

    // ISNULL operator
    if (filter.operator === Operators.common.$isnull) {
      return `${filter.field}=${filter.operator}=${filter.value}`;
    }

    // Contains-statements is used as string search
    if (filter.operator === Operators.common.$contains) {
      if (isNullOrUndefinedOrBlank(filter.value)) {
        return null;
      }
      return `${filter.field}=='*${RsqlEncoder.escapeString(filter.value)}*'`;
    }

    // Between requires two array values
    if (filter.operator === Operators.common.$between && isArray(filter.value) && filter.value.length > 1) {
      // If the operator is between and one of the value sis missing - return null immediately Or backend will error out
      if (filter.value.some((val) => isNullOrUndefinedOrBlank(val))) {
        return null;
      }

      const first = `${filter.field}>=${RsqlEncoder.parseValue(filter.value[0])}`;
      const last = `${filter.field}<=${RsqlEncoder.parseValue(filter.value[1])}`;
      return `${first} and ${last}`;
    }

    // Contains-statements is used as string search
    if (filter.operator === Operators.common.$ncontains) {
      if (isNullOrUndefinedOrBlank(filter.value)) {
        return null;
      }
      return `${filter.field}!='*${RsqlEncoder.escapeString(filter.value)}*'`;
    }

    // IN- and OUT-statements need to be an array.
    if (includes([Operators.common.$in, Operators.common.$out], filter.operator) && isArray(filter.value)) {
      let values: any[] = filter.value;

      // Make sure to return null if there is no items
      if (!values || values.length === 0) {
        return null;
      }

      // Special condition for null values
      const nullQuery = values.indexOf(null) > -1 ? `${filter.field}=isnull=true` : ``;

      // Escape the values if they are string. This prevents the query from breaking on values with spaces
      values = values
        .filter(val => val != null)
        .map(val => `'${RsqlEncoder.escapeString(val)}'`);

      if (values.length) {
        const mainQuery = `${filter.field}=${filter.operator}=(${values.join(',')})`;
        return `(${RsqlEncoder.combine([nullQuery, mainQuery], 'or')})`;
      }
      return nullQuery;
    }

    // OR-statements
    if (filter.operator === Operators.common.$or && isArray(filter.value)) {
      const values: any[] = filter.value;

      // Make sure to return null if there is no items
      if (!values || values.length === 0) {
        return null;
      }

      // Special condition for null values
      const nullQuery = values.indexOf(null) > -1 ? `${filter.field}=isnull=true` : ``;

      const filters = values
        .filter(val => val != null)
        .map(val => `${filter.field}=='*${RsqlEncoder.escapeString(val)}*'`);

      return '(' + RsqlEncoder.combine([nullQuery, ...filters], 'or') + ')';
    }

    //  Return unsupported operators or invalid values
    return null;
  }

  private static parseValue(value: any): any {
    if (value instanceof Date) {
      return value.valueOf();
    }
    return value;
  }

  private static escapeString(value: string): string {
    return `${value}`.replace(/'/g,'\\\'');
  }

  private static runTransformations(sourceFilter: FilterSet): FilterSet {
    // Create a copy of the original filter so we can mutate it safely.
    const filter = clone(sourceFilter) as FilterSet;

    if (sourceFilter.noTransform) {
      return filter;
    }

    // Date type handling - they are converted to unix timestamps
    if (filter.type === FieldType.Date && isArray(filter.value)) {
      const values: any[] = filter.value;

      // Special handling for string dates (YYYY-MM-DD).
      // If only one is given, use the same date
      if (typeof values[0] === 'string' && !values[1]) {
        values[1] = values[0];

        return filter;
      }

      // If more than one given = do not do any transformations
      const valueTypes = values.map(val => typeof val);
      const valueTypeSummary = uniq(valueTypes)
        .shift();

      // If they are all string, we don't do any other transformation
      // and then return early
      if (valueTypeSummary === 'string') {
        return filter;
      }

      values.forEach((val, index) => {
        // Set the TO or the end of the range to the End of the Date (ex. Sep 06 2019 23:59:59)
        if (index === 1) {
          val = moment(!isNullOrUndefined(val) ? val : values[0])
            .endOf('day')
            .toDate();
        }
        // Convert to their numeric unix timestamp when it is a date object.
        // Otherwise don't touch it.
        if (isDate(val)) {
          filter.value[index] = val.valueOf();
        }
      });
    }

    // Add any other transformations here -
    // ...


    // Return the transformed filters
    return filter;
  }

}
