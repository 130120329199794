<ng-container *ngFor="let error of errorKeys">
  <div
    class="invalid-feedback d-block"
    *ngIf="control && (control.touched || control.dirty) && control.errors && control.errors[error]">
    <ng-container *ngIf="errorOverride[error]; else defaultMsg">
      {{errorOverride[error]}}
    </ng-container>
    <ng-template #defaultMsg>
      {{control.errors[error]}}
    </ng-template>
  </div>
</ng-container>
