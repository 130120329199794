import { Injectable } from '@angular/core';
import { HttpEvent, HttpResponse } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';

interface HttpResponseCache {
  url: string;
  response: HttpEvent<HttpResponse<any>>;
  request: BehaviorSubject<HttpEvent<HttpResponse<any>>>;
  requested: Date;
  completed: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class HttpCacheService {

  private ignoreOnceList: string[] = [];

  private cache: HttpResponseCache[] = [];

  /**
   * Time until the aggressive ttl cache expires
   */
  private aggressiveTTL = 2000;

  constructor() { }

  public add(url: string): void {
    // TODO: Add support for e-tags too if supported by backend
    // Do a cleanup first.
    this.cleanup();

    const existing = this.cache.find((cache) => cache.url === url);

    // Making sure we do not re-add existing ones.
    if (existing) {
      return;
    }

    // Not existing yet.
    this.cache.push({
      url,
      response: null,
      request: new BehaviorSubject(null),
      requested: new Date(),
      completed: false,
    });
  }

  public get(url: string): HttpResponseCache {
    return this.cache.find((response) => response.url === url);
  }

  public cleanup(): void {
    const now = new Date().valueOf();
    this.cache = this.cache.filter((cache) => {
      if (!cache.completed) {
        return true;
      }
      return now - cache.requested.valueOf() < this.aggressiveTTL;
    });
  }

  public ignoreOnce(url: string): void {
    this.ignoreOnceList.push(url);
  }

  public cleanupIgnoreOnce(url: string): void {
    this.ignoreOnceList = this.ignoreOnceList.filter(eIgnoreUrl => eIgnoreUrl !== url);
  }

  public hasIgnoreOnce(url: string): boolean {
    return !!this.ignoreOnceList.find(ignore => ignore === url);
  }

  /**
   * This purges the current cache of http request.
   * DO NOT USE THIS if you don't know WHAT you're doing or WHY you are doing it
   * Use this SPARINGLY as this negates any performance gain from http caching.
   */
  public purge(): void {
    this.cache = [];
  }

  public expireCache(cache: HttpResponseCache): void {
    cache.requested = moment()
      .day(-1)
      .toDate();
  }
}
