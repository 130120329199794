<div class="terms-overlay d-flex align-items-center flex-column justify-content-center" *ngIf="showTerms">
  <div class="terms-container pr-3"><app-terms-of-use-content></app-terms-of-use-content></div>
  <div class="mt-2">
    <div class="cb-text">
      <p-checkbox [(ngModel)]="accepted" binary="true" label="I agree to Park Evaluations Terms of Service"></p-checkbox>
    </div>
    <div class="mt-3 text-center">
      <button class="btn btn-outline-secondary mr-2" (click)="cancel()">Cancel</button>
      <button class="btn btn-primary" [disabled]="!accepted" (click)="accept()">Confirm</button>
    </div>
  </div>
</div>
