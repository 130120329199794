import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MainShellService {

  public shellVisibility = new BehaviorSubject(true);

  constructor() { }

  public hide(): void {
    this.shellVisibility.next(false);
  }

  public show(): void {
    this.shellVisibility.next(true);
  }
}
