import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfflineServicesComponent } from './offline-screen.component';
import { ConnectionDaemonService } from 'src/app/services/connection-daemon.service';

@NgModule({
  declarations: [
    OfflineServicesComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    OfflineServicesComponent
  ],
  providers: [
    ConnectionDaemonService
  ]
})

export class OfflineScreenModule {
}
