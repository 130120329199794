import { Component, OnInit } from '@angular/core';
import { GlobalLoaderService } from '../../services/global-loader.service';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-global-loader',
  templateUrl: './global-loader.component.html',
  styleUrls: ['./global-loader.component.scss']
})
export class GlobalLoaderComponent implements OnInit {

  public showLoader = false;

  constructor(private globalLoader: GlobalLoaderService, private router: Router) { }

  public ngOnInit(): void {
    this.globalLoader
      .loaderStatus
      .subscribe(loadCount => {
        this.toggleLoader(loadCount);
      });

    this.setupRouteListener();
  }

  private toggleLoader(current: number): void {
    this.showLoader = current > 0;
  }

  /**
   * Sets up a route listener so that when async routes are loaded,
   * the loader also comes up until the async route has finished loading.
   */
  private setupRouteListener(): void {
    this.router
      .events
      .subscribe((ev) => {
        if (ev instanceof NavigationStart) {
          this.globalLoader.triggerAuto();
        }

        if (ev instanceof NavigationEnd) {
          this.globalLoader.completeAuto();
        }

        // Making sure errors are handled too.
        if (ev instanceof NavigationError) {
          this.globalLoader.completeAuto();
        }

        // When canceled, also handle.
        if (ev instanceof NavigationCancel) {
          this.globalLoader.completeAuto();
        }
      });
  }

}
