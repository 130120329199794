/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./shell.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./shell.component";
var styles_ShellComponent = [i0.styles];
var RenderType_ShellComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShellComponent, data: {} });
export { RenderType_ShellComponent as RenderType_ShellComponent };
export function View_ShellComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "bg-white"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "container pt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["align", "Park Evaluation Logo"], ["src", "assets/park-evaluations-logo.svg"], ["width", "170"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "hr", [["class", "mb-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 5, 0); }, null); }
export function View_ShellComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-shell", [], null, null, null, View_ShellComponent_0, RenderType_ShellComponent)), i1.ɵdid(1, 114688, null, 0, i3.ShellComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShellComponentNgFactory = i1.ɵccf("app-shell", i3.ShellComponent, View_ShellComponent_Host_0, {}, {}, []);
export { ShellComponentNgFactory as ShellComponentNgFactory };
