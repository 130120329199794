/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./offline-screen.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./offline-screen.component";
import * as i4 from "../../services/connection-daemon.service";
var styles_OfflineServicesComponent = [i0.styles];
var RenderType_OfflineServicesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OfflineServicesComponent, data: {} });
export { RenderType_OfflineServicesComponent as RenderType_OfflineServicesComponent };
function View_OfflineServicesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "p-5 mb-4 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-exclamation-triangle dialog-icon text-warning mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["class", "mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["System currently offline."])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h5", [["class", "font-weight-normal"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You might be offline or the system is currently undergoing maintenance."])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [["class", "font-weight-light"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["To preserve the integrity of your data, system access will be disabled."]))], null, null); }
function View_OfflineServicesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "offline-screen"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "d-flex align-items-center vh-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "m-auto offline-dialog d-flex flex-column"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OfflineServicesComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.currentStatus === _co.statusCodes.Offline); _ck(_v, 4, 0, currVal_0); }, null); }
export function View_OfflineServicesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_OfflineServicesComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.currentStatus !== _co.statusCodes.Online); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_OfflineServicesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-offline-screen", [], null, null, null, View_OfflineServicesComponent_0, RenderType_OfflineServicesComponent)), i1.ɵdid(1, 114688, null, 0, i3.OfflineServicesComponent, [i4.ConnectionDaemonService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OfflineServicesComponentNgFactory = i1.ɵccf("app-offline-screen", i3.OfflineServicesComponent, View_OfflineServicesComponent_Host_0, {}, {}, []);
export { OfflineServicesComponentNgFactory as OfflineServicesComponentNgFactory };
