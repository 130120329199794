<div class="text-center pt-3 bg-white">
  <img src="../../../assets/park-evaluations-logo.svg" width="160">
  <hr />
</div>

<div class="d-flex align-items-center justify-content-center">
  <div class="error-container">
    <span class="error-code">403</span>
    <div class="error-content text-center">
      <div class="error-title">You don't have access</div>
      <div class="error-text">
        <div class="mb-3">If you think this is a mistake, please contact support.</div>
        <button class="btn btn-link" (click)="reload()"><i class="fa fa-redo"></i> RELOAD</button>
        <span class="d-inline-block mx-2">or</span>
        <button class="btn btn-link" routerLink="/logout"><i class="fa fa-sign-out-alt"></i> LOGOUT</button>
      </div>
    </div>
  </div>
</div>
