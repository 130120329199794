import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { SystemConfigType } from '../../api/system-config/system-config-type';
import { sortBy } from 'src/app/utils/sort-by';
import { map, switchMap, tap } from 'rxjs/operators';
import { ServiceCategoryType } from '../../api/system-config/service-category-type';
import { SystemType } from '../../api/system-config/system-type';
import { SystemModule } from '../../api/system-config/system-module';
import { clone } from '../../utils/clone';
import { ServiceType } from '../../api/system-config/service-type';
import { includes } from '../../utils/includes';
import { PAGINATE_NO_LIMIT, PaginatedData } from '../../api/pagination/paginated-data';
import { sortByString } from '../../pipes/sort-by-string.pipe';
import { RsqlEncoder } from '../../api/pagination/rsql-encoder';
import { FieldType } from '../../api/pagination/field-type';
import { Operators } from '../../api/pagination/filter-operators';
import { GlobalLoaderService } from '../global-loader.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../global-loader.service";
var SystemConfigService = /** @class */ (function () {
    function SystemConfigService(httpClient, globalLoader) {
        this.httpClient = httpClient;
        this.globalLoader = globalLoader;
        this.currentModule = SystemModule.None;
    }
    Object.defineProperty(SystemConfigService.prototype, "activeModule", {
        get: function () {
            if (this.currentModule) {
                return this.currentModule;
            }
            // Just a failsafe in-case somebody forgets to reset.
            return SystemModule.None;
        },
        enumerable: true,
        configurable: true
    });
    SystemConfigService.prototype.setModule = function (module) {
        this.currentModule = module;
    };
    SystemConfigService.prototype.resetModule = function () {
        this.currentModule = SystemModule.None;
    };
    SystemConfigService.prototype.getServices = function (forInvoice, showDeleted) {
        if (forInvoice === void 0) { forInvoice = false; }
        if (showDeleted === void 0) { showDeleted = false; }
        return this.httpClient
            .get("/api/system_config?type=" + SystemConfigType.Services)
            .pipe(map(function (ogServices) {
            var services = clone(ogServices);
            // Story 19000:
            // Remove WEVLoE (Experience and Expertise Combo Letter)
            // and TBD (other expert opinion letter);
            // Backend still returns them
            services = services
                .filter(function (service) { return showDeleted ? true : !service.deleteInd; })
                .filter(function (service) { return !includes([ServiceType.ExperienceAndExpertiseComboLetter, ServiceType.OtherExpertOpinionLetter], service.serviceType); });
            if (forInvoice) {
                // for invoice line item remove the TBD/PENDING
                var filteredServices = services
                    .filter(function (service) { return service.serviceCategoryType !== ServiceCategoryType.PENDING; });
                return sortBy(filteredServices, function (service) { return service.displayName.toLowerCase(); });
            }
            else {
                // non invoice filter out the INVOICE category type.
                var filteredServices = services
                    .filter(function (service) { return service.serviceCategoryType !== ServiceCategoryType.INVOICE; });
                return sortBy(filteredServices, function (service) { return service.displayName.toLowerCase(); });
            }
        }));
    };
    SystemConfigService.prototype.getAdsServices = function (excludedServices) {
        return this.httpClient
            .get("/api/system_config?type=" + SystemConfigType.AdsServices)
            .pipe(map(function (services) {
            if (!excludedServices) {
                return services;
            }
            // Filter out excluded services
            return services.filter(function (service) { return !excludedServices.includes(service.adsServiceType); });
        }));
    };
    SystemConfigService.prototype.getDegrees = function () {
        return this.httpClient.get("/api/system_config?type=" + SystemConfigType.Degree)
            .pipe(map(function (degrees) {
            return sortBy(degrees, function (degree) { return degree.readableType.toLowerCase(); });
        }));
    };
    SystemConfigService.prototype.saveDegree = function (degree) {
        var request = {
            id: degree.id,
            degreeDTO: degree,
            type: SystemConfigType.Degree
        };
        return this.saveOrUpdate(request);
    };
    SystemConfigService.prototype.getAcademicEquivalencies = function () {
        return this.httpClient.get("/api/system_config?type=" + SystemConfigType.AcademicEquivalency)
            .pipe(map(function (academicEquivalencies) {
            return sortBy(academicEquivalencies, function (academicEquivalency) { return academicEquivalency.readableType.toLowerCase(); });
        }));
    };
    SystemConfigService.prototype.saveAcademicEquivalency = function (academicEquivalency) {
        var request = {
            id: academicEquivalency.id,
            academicEquivalencyDTO: academicEquivalency,
            type: SystemConfigType.AcademicEquivalency,
        };
        return this.saveOrUpdate(request);
    };
    SystemConfigService.prototype.saveObtainedCredential = function (obtainedCredential) {
        var request = {
            id: obtainedCredential.id,
            obtainedCredentialConfigDTO: obtainedCredential,
            type: SystemConfigType.ObtainedCredential,
        };
        return this.saveOrUpdate(request);
    };
    SystemConfigService.prototype.saveCountryPrefix = function (countryPrefix) {
        var request = {
            id: countryPrefix.id,
            countryPrefixDTO: countryPrefix,
            type: SystemConfigType.CountryPrefix
        };
        return this.saveOrUpdate(request);
    };
    SystemConfigService.prototype.getEmailReminder = function () {
        return this.httpClient.get("/api/system_config?type=" + SystemConfigType.ReminderEmail);
    };
    SystemConfigService.prototype.saveEmailReminder = function (reminder) {
        var request = {
            id: reminder.id,
            reminderEmailConfigDTO: reminder,
            type: SystemConfigType.ReminderEmail,
        };
        return this.saveOrUpdate(request);
    };
    SystemConfigService.prototype.saveSystemDefaultReviewer = function (user) {
        var request = {
            systemDefaultUserDTO: user,
            type: SystemConfigType.SystemDefaultUser,
        };
        return this.httpClient.put("/api/system_config", request);
    };
    SystemConfigService.prototype.updateCountriesPrefix = function (countriesPrefix) {
        var request = [];
        for (var _i = 0, countriesPrefix_1 = countriesPrefix; _i < countriesPrefix_1.length; _i++) {
            var countryPrefix = countriesPrefix_1[_i];
            request.push({
                id: countryPrefix.id,
                countryPrefixDTO: countryPrefix,
                type: SystemConfigType.CountryPrefix,
            });
        }
        return this.httpClient.post("/api/system_configs", request);
    };
    SystemConfigService.prototype.saveProgramDuration = function (programDuration) {
        var request = {
            id: programDuration.id,
            programDurationDTO: programDuration,
            type: SystemConfigType.ProgramDuration
        };
        return this.saveOrUpdate(request);
    };
    SystemConfigService.prototype.getAreaOfExpertise = function () {
        return this.httpClient.get("/api/system_config?type=" + SystemConfigType.AreaOfExpertise)
            .pipe(map(function (areaOfExpertise) {
            return sortBy(areaOfExpertise, function (study) { return study.readableType.toLowerCase(); });
        }));
    };
    SystemConfigService.prototype.getFieldOfStudies = function () {
        return this.httpClient.get("/api/system_config?type=" + SystemConfigType.FieldOfStudy)
            .pipe(map(function (fieldOfStudy) {
            return sortBy(fieldOfStudy, function (study) { return study.readableType.toLowerCase(); });
        }));
    };
    SystemConfigService.prototype.saveFieldOfStudy = function (field) {
        var request = {
            id: field.id,
            fieldOfStudyDTO: field,
            type: SystemConfigType.FieldOfStudy
        };
        return this.saveOrUpdate(request);
    };
    SystemConfigService.prototype.saveFieldOfStudies = function (fields) {
        var request = [];
        fields.forEach(function (field) { return request.push({
            id: field.id,
            fieldOfStudyDTO: field,
            type: SystemConfigType.FieldOfStudy
        }); });
        return this.httpClient.post("/api/system_configs", request);
    };
    SystemConfigService.prototype.getUniversities = function () {
        return this.httpClient.get("/api/system_config?type=" + SystemConfigType.University)
            .pipe(map(function (universities) {
            return sortBy(universities, function (university) { return university.readableType.toLowerCase(); });
        }));
    };
    SystemConfigService.prototype.saveUniversity = function (university) {
        var request = {
            id: university.id,
            universityDTO: university,
            type: SystemConfigType.University
        };
        return this.saveOrUpdate(request);
    };
    SystemConfigService.prototype.getBillingRates = function () {
        return this.httpClient.get("/api/system_config?type=" + SystemConfigType.Billing);
    };
    SystemConfigService.prototype.getAdsBillingRates = function () {
        return this.httpClient.get("/api/system_config?type=" + SystemConfigType.AdsBilling);
    };
    SystemConfigService.prototype.saveBillingRate = function (data) {
        var request = {
            billingRateDTO: data,
            type: SystemConfigType.Billing
        };
        return this.httpClient.put("/api/system_config", request);
    };
    SystemConfigService.prototype.saveAdsBillingRate = function (data) {
        var request = {
            adsBillingRateDTO: data,
            type: SystemConfigType.AdsBilling
        };
        return this.httpClient.put("/api/system_config", request);
    };
    SystemConfigService.prototype.getExclusionList = function () {
        return this.httpClient
            .get("/api/system_config?type=" + SystemConfigType.ExclusionList)
            .pipe(map(function (emailList) { return emailList.filter(function (email) { return email.systemType === SystemType.ParkEval; }); }));
    };
    SystemConfigService.prototype.getExclusionListAds = function () {
        return this.httpClient
            .get("/api/system_config?type=" + SystemConfigType.ExclusionList)
            .pipe(map(function (emailList) { return emailList.filter(function (email) { return email.systemType === SystemType.ParkAds; }); }));
    };
    SystemConfigService.prototype.createExclusionList = function (data) {
        var request = {
            emailExclusionDTO: [data],
            type: SystemConfigType.ExclusionList,
        };
        return this.saveOrUpdate(request);
    };
    SystemConfigService.prototype.saveExclusionList = function (data) {
        var request = {
            emailExclusionDTO: data,
            type: SystemConfigType.ExclusionList,
        };
        return this.httpClient.put("/api/system_config", request);
    };
    SystemConfigService.prototype.getAdditionalPricing = function () {
        return this.httpClient.get("/api/system_config?type=" + SystemConfigType.AdditionalPricing);
    };
    SystemConfigService.prototype.saveAdditionalPricing = function (data) {
        var request = {
            additionalServiceConfigDTO: data,
            type: SystemConfigType.AdditionalPricing
        };
        return this.httpClient.put("/api/system_config", request);
    };
    SystemConfigService.prototype.getSystemRoles = function () {
        return this.httpClient.get("/api/system_config?type=" + SystemConfigType.SystemRoles);
    };
    SystemConfigService.prototype.getPermissions = function () {
        return this.httpClient.get("/api/system_config?type=" + SystemConfigType.Permissions);
    };
    SystemConfigService.prototype.getCountries = function () {
        return this.httpClient
            .get("/api/system_config?type=" + SystemConfigType.Country);
    };
    SystemConfigService.prototype.getTimezones = function () {
        return this.httpClient.get('/assets/timezones.json');
    };
    SystemConfigService.prototype.removeConfig = function (id, type) {
        return this.httpClient.delete("/api/system_config/" + id + "?type=" + type);
    };
    SystemConfigService.prototype.getServiceAndWorkflowByServiceId = function (serviceId) {
        return this.httpClient
            .get("/api/system_config/" + serviceId + "?type=" + SystemConfigType.ServiceAndWorkflow);
    };
    SystemConfigService.prototype.getCountryPrefixes = function () {
        return this.httpClient
            .get("/api/system_config?type=" + SystemConfigType.CountryPrefix);
    };
    SystemConfigService.prototype.getProgramDurations = function () {
        return this.httpClient
            .get("/api/system_config?type=" + SystemConfigType.ProgramDuration)
            .pipe(map(function (values) {
            return sortByString(values, 'readableType');
        }));
    };
    SystemConfigService.prototype.getMappedFields = function () {
        return this.httpClient
            .get("/api/system_config?type=" + SystemConfigType.MappedField);
    };
    SystemConfigService.prototype.getDefaultReviewer = function () {
        return this.httpClient
            .get("/api/system_config?type=" + SystemConfigType.SystemDefaultUser);
    };
    SystemConfigService.prototype.saveOrUpdate = function (data) {
        if (data.id) {
            return this.httpClient.put("/api/system_config", data);
        }
        return this.httpClient.post("/api/system_config", data);
    };
    SystemConfigService.prototype.getErrorTypes = function (stage) {
        return this.httpClient.get("/api/system_config/error_type?stage=" + stage);
    };
    SystemConfigService.prototype.getSalesPersons = function () {
        return this.httpClient.get("/api/system_config?type=" + SystemConfigType.SalesPerson)
            .pipe(map(function (persons) {
            return sortBy(persons, function (person) { return person.fullname.toUpperCase(); });
        }));
    };
    SystemConfigService.prototype.saveSalesPerson = function (person) {
        var request = {
            id: person.id,
            salesPersonDTO: person,
            type: SystemConfigType.SalesPerson,
        };
        return this.saveOrUpdate(request);
    };
    SystemConfigService.prototype.getSwas = function (serviceableOnly) {
        var _this = this;
        if (serviceableOnly === void 0) { serviceableOnly = false; }
        return this.httpClient.get("/api/system_config?type=" + SystemConfigType.Swa)
            .pipe(switchMap(function (sites) {
            if (_this.swaSites) {
                return of(clone(_this.swaSites));
            }
            return of(sites);
        }), map(function (sites) {
            _this.swaSites = sites;
            var filteredSites = sites;
            if (serviceableOnly) {
                filteredSites = _this.swaSites.filter(function (site) { return site.serviceable; });
            }
            return sortBy(filteredSites, function (site) {
                if (site.readableType) {
                    return site.readableType.toUpperCase();
                }
                return '';
            });
        }));
    };
    SystemConfigService.prototype.saveSwaSite = function (site) {
        var request = {
            id: site.id,
            swaStateDTO: site,
            type: SystemConfigType.Swa
        };
        return this.saveOrUpdate(request);
    };
    SystemConfigService.prototype.getOnlineConsumerSites = function (activeOnly, includeInactiveId) {
        if (activeOnly === void 0) { activeOnly = true; }
        if (includeInactiveId === void 0) { includeInactiveId = null; }
        return this.httpClient.get("/api/system_config?type=" + SystemConfigType.OnlineConsumerSite)
            .pipe(map(function (sites) {
            if (activeOnly) {
                // We must negate deleteInd since it is an inverted boolean
                sites = sites.filter(function (journal) { return !journal.deleteInd || journal.id === includeInactiveId; });
            }
            return sortBy(sites, function (site) {
                if (!site.name) {
                    return;
                }
                return site.name.toUpperCase();
            });
        }));
    };
    SystemConfigService.prototype.saveOnlineConsumerSite = function (site) {
        var request = {
            id: site.id,
            onlineConsumerSiteConfigDTO: site,
            type: SystemConfigType.OnlineConsumerSite
        };
        return this.saveOrUpdate(request);
    };
    SystemConfigService.prototype.getOnlineTradeJournals = function (activeOnly, includeInactiveId) {
        if (activeOnly === void 0) { activeOnly = true; }
        if (includeInactiveId === void 0) { includeInactiveId = null; }
        return this.httpClient.get("/api/system_config?type=" + SystemConfigType.OnlineTradeJournal)
            .pipe(map(function (data) {
            if (activeOnly) {
                // Must negate since deleteInd is an inverted boolean
                data = data.filter(function (journal) { return !journal.deleteInd || journal.id === includeInactiveId; });
            }
            return sortBy(data, function (site) {
                if (!site.name) {
                    return '';
                }
                return site.name.toUpperCase();
            });
        }));
    };
    SystemConfigService.prototype.saveOnlineTradeJournal = function (data) {
        var request = {
            id: data.id,
            onlineTradeJournalConfigDTO: data,
            type: SystemConfigType.OnlineTradeJournal
        };
        return this.saveOrUpdate(request);
    };
    SystemConfigService.prototype.getParamConfig = function (subType) {
        if (subType) {
            return this.httpClient
                .get("/api/system_config?type=" + SystemConfigType.ParamConfig + "&subType=" + subType);
        }
        return this.httpClient
            .get("/api/system_config?type=" + SystemConfigType.ParamConfig);
    };
    SystemConfigService.prototype.saveParamConfig = function (paramConfig) {
        var request = {
            id: paramConfig.id,
            paramConfigDTO: paramConfig,
            type: SystemConfigType.ParamConfig
        };
        return this.saveOrUpdate(request);
    };
    SystemConfigService.prototype.getPaginatedAcademicEquivalencies = function (request, opts) {
        var url = "/api/system-config/paginated?type=" + SystemConfigType.AcademicEquivalency;
        if (opts && opts.loaderIgnoreOnce) {
            this.globalLoader.ignoreOnce(url);
        }
        return this.httpClient
            .post(url, request.toObject())
            .pipe(
        // Update the given pagination object with values from the response.
        map(function (response) { return request.update(response); }));
    };
    SystemConfigService.prototype.getAcademicEquivalencyById = function (id) {
        return this.httpClient
            .get("/api/system_config/" + id + "?type=" + SystemConfigType.AcademicEquivalency);
    };
    SystemConfigService.prototype.getPaginatedObtainedCredential = function (request) {
        return this.httpClient
            .post("/api/system-config/paginated?type=" + SystemConfigType.ObtainedCredential, request.toObject())
            .pipe(
        // Update the given pagination object with values from the response.
        map(function (response) { return request.update(response); }));
    };
    SystemConfigService.prototype.getObtainedCredentialById = function (id) {
        return this.httpClient
            .get("/api/system_config/" + id + "?type=" + SystemConfigType.ObtainedCredential);
    };
    SystemConfigService.prototype.getObtainedCredentials = function () {
        return this.httpClient
            .get("/api/system_config/?type=" + SystemConfigType.ObtainedCredential);
    };
    SystemConfigService.prototype.isAcademicEquivalencyDegreeDuplicate = function (newDegree, currentDegree) {
        if (currentDegree && newDegree.toLowerCase() === currentDegree.readableType.toLowerCase()) {
            return of(false);
        }
        // If different from the original value, check if there is a duplicate
        var paginatedData = new PaginatedData();
        paginatedData.size = PAGINATE_NO_LIMIT;
        paginatedData.query = RsqlEncoder.combine([
            RsqlEncoder.encode({
                field: 'deleteInd',
                type: FieldType.Boolean,
                value: false,
                operator: Operators.common.$eq
            }),
            RsqlEncoder.encode({
                field: 'degree',
                type: FieldType.String,
                value: newDegree,
                operator: Operators.common.$eq
            })
        ]);
        return this.getPaginatedAcademicEquivalencies(paginatedData, { loaderIgnoreOnce: true })
            .pipe(switchMap(function (data) {
            return of(data.content.length > 0);
        }));
    };
    SystemConfigService.prototype.getSwaRequiredFields = function () {
        var _this = this;
        return this.httpClient
            .get("/api/system_config/?type=" + SystemConfigType.SwaStateRequirements)
            .pipe(switchMap(function (reqs) {
            if (_this.swaStateRequirements) {
                return of(clone(_this.swaStateRequirements));
            }
            return of(reqs);
        }), tap(function (reqs) { return _this.swaStateRequirements = reqs; }));
    };
    SystemConfigService.prototype.getSwaRequiredFieldsByState = function (state) {
        var _this = this;
        return this.getSwaRequiredFields()
            .pipe(switchMap(function (reqs) {
            return _this.getSwas();
        }), switchMap(function () {
            var targetState = _this.swaSites
                .find(function (swa) { return swa.readableType === state; });
            return of(_this.swaStateRequirements.find(function (rq) { return rq.stateId === targetState.id; }));
        }));
    };
    SystemConfigService.ngInjectableDef = i0.defineInjectable({ factory: function SystemConfigService_Factory() { return new SystemConfigService(i0.inject(i1.HttpClient), i0.inject(i2.GlobalLoaderService)); }, token: SystemConfigService, providedIn: "root" });
    return SystemConfigService;
}());
export { SystemConfigService };
