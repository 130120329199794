import { Directive } from '@angular/core';
import { FormControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[appValidEmailValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: ValidateEmailPatternDirective,
      multi: true
    }
  ]
})
export class ValidateEmailPatternDirective implements Validator {
  private static validateEmailPattern(control: FormControl): ValidationErrors | null {
    const emailRegex: RegExp = new RegExp(
      '^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]' +
      '{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$'
    );

    const value = control.value;

    if (!emailRegex.test(value)) {
      return { invalidEmail: 'Not a valid email address' };
    }
    return null;
  }

  constructor() { }

  public validate(c: FormControl): { [key: string]: ValidationErrors | null } {
    return ValidateEmailPatternDirective.validateEmailPattern(c);
  }
}
