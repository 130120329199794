import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './public/login/login.component';
import { ShellComponent as PublicShell } from './public/shell/shell.component';
import { ForgotPasswordComponent } from './public/forgot-password/forgot-password.component';
import { AuthGuard } from './guards/auth.guard';
import { PageNotFoundComponent } from './errors/page-not-found/page-not-found.component';
import { ExistingSessionGuard } from './guards/existing-session.guard';
import { LogoutComponent } from './public/logout/logout.component';
import { GenericErrorComponent } from './errors/generic-error/generic-error.component';
import { StartupEssentialsGuard } from './guards/startup-essentials.guard';
import { ErrorMaintenanceComponent } from './errors/error-maintenance/error-maintenance.component';
import { Error403Component } from './errors/error-403/error-403.component';
import { RedirectComponent } from './main/shared/redirect/redirect.component';
import { InstanceSelectorComponent } from './instance-selector/instance-selector.component';
import { SystemSetParkevalModuleGuard } from './guards/system-set-parkeval-module.guard';
import { SystemSetParkadsModuleGuard } from './guards/system-set-parkads-module.guard';
import { UserGuard } from './guards/user.guard';
import { ParkEvalOnlyGuard } from './guards/park-eval-only.guard';
import { ParkAdsOnlyGuard } from './guards/park-ads-only.guard';
import { StartupEssentialsAdsGuard } from './guards/startup-essentials-ads.guard';
import { ErrorInactiveEndClientComponent } from './errors/error-inactive-end-client/error-inactive-end-client.component';

// NOTE on routing structure:
// Routes was structured this way (public/main) so that we don't have to load the whole system's module
// when user is just about to login.
//
// The whole system is loaded async after login so that the login page loads VERY fast.
// The whole system module is loaded only after the user logs in.

const routes: Routes = [
  {
    path: '',
    component: InstanceSelectorComponent,
    canActivate: [AuthGuard, UserGuard]
  },
  // A shortcut to login
  {
    path: 'login',
    redirectTo: 'public/login',
    pathMatch: 'full'
  },
  // A shortcut logout
  {
    path: 'logout',
    redirectTo: 'public/logout',
    pathMatch: 'full'
  },
  {
    path: 'public',
    component: PublicShell,
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      { path: 'login', component: LoginComponent, canActivate: [ExistingSessionGuard] },
      { path: 'logout', component: LogoutComponent },
      { path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [ExistingSessionGuard] },
    ]
  },
  {
    path: 'main',
    canActivate: [AuthGuard, ParkEvalOnlyGuard, StartupEssentialsGuard, SystemSetParkevalModuleGuard],
    canLoad: [AuthGuard],
    canDeactivate: [SystemSetParkevalModuleGuard],
    loadChildren: './main/main.module#MainModule'
  },
  {
    path: 'park-ads',
    canActivate: [AuthGuard, ParkAdsOnlyGuard, StartupEssentialsAdsGuard, SystemSetParkadsModuleGuard],
    canLoad: [AuthGuard],
    canDeactivate: [SystemSetParkevalModuleGuard],
    loadChildren: './main-ads/main-ads.module#MainAdsModule'
  },
  {
    path: 'redirect',
    component: RedirectComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'error',
    component: GenericErrorComponent,
  },
  {
    path: 'error-404',
    component: PageNotFoundComponent,
  },
  {
    path: 'error-503-maintenance',
    component: ErrorMaintenanceComponent,
  },
  {
    path: 'error-403',
    component: Error403Component,
  },
  {
    path: 'error-inactive-end-client',
    component: ErrorInactiveEndClientComponent,
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
