import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Candidate } from '../../api/case/candidate';
import { CaseCustomerInfoUpdateRequest } from '../../api/case/case-customer-info-update-request';
import { CaseIntake } from '../../api/case/case-intake';
import { CaseReferenceType } from '../../api/case/case-reference-type';
import { clone } from '../../utils/clone';
import { map } from 'rxjs/operators';
import { PaginatedData, PaginateObject } from '../../api/pagination/paginated-data';
import { CompanyPaginated } from '../../api/organization/company-paginated';
import { CasePaginated } from '../../api/case/case-paginated';
import { Case } from '../../api/case/case';
import { isNullOrUndefinedOrBlank } from '../../utils/is-null-or-undefined';
import { CaseServicePaginated } from '../../api/case/case-service-paginated';
import { CountResponse } from '../../api/case/count-response';
import { Service } from '../../api/system-config/service';
import { FileSystemKey } from '../../api/file-system-key';
import { MessageType } from '../../api/messaging/message-type';
import { EvaluationFile } from '../../api/case/evaluation-file';
import { ProcessResponseMessage } from '../../api/process-response-message';
import { HttpCacheService } from '../http-cache.service';
import { GlobalLoaderService } from '../global-loader.service';
import { Gender } from '../../api/gender';
import { CaseSourceFile } from '../../api/case/case-source-file';
import { ModifyServiceRequest } from '../../api/case/modify-service-request';
import { TurnAroundTime } from '../../api/case/turn-around-time';
import { CaseDeliveryPreferences } from '../../main/case/components/case-header/case-delivery-preferences';
import { CaseBillingContact } from '../../api/case/case-billing-contact';
import { QualityIssueForm } from '../../main/case/workspace/stage-proofreading/quality-issue-form';
import { ServiceStageValue } from '../../api/service-stage';
import { NoteMessage } from '../../api/messaging/note-message';
import { BatchCaseRequest } from '../../api/case/batch-case-request';
import { BatchCaseResponse } from '../../api/case/batch-case-response';
import { SpreadsheetHeaderData } from '../../api/case/spreadsheet-header-data';
import { BulkProjectResponse } from '../../api/case/bulk-project-response';
import { BatchCaseCommonData } from '../../api/case/batch-case-common-data';
import { BatchCaseServiceDeliverableRequest } from '../../api/case/batch-case-service-deliverable-request';
import { TitleCasePipe } from '@angular/common';
import { PdfServiceStatus } from '../../api/case/pdf-service-status';
import _get from 'lodash/get';
import _set from 'lodash/set';
import { isNullOrUndefined } from 'util';
import { CaseTranslatedFile } from '../../api/case/case-translated-file';
import { MessageCategory } from '../../api/messaging/message-category';
import { CaseFileType } from '../../api/case-file-type';
import { ApiQueryOptions, ApiQueryParams } from './api-query-params';
import { ReplaceDeliveyFileRequest } from '../../api/case/replace-delivey-file-request';
import { SystemType } from '../../api/system-config/system-type';
import { SalesforceOpportunity } from '../../api/case/salesforce-opportunity';
import { CaseTranslationActivity } from '../../api/case/case-translation-activity';
import { CaseProjectObtainedCredential } from '../../api/case/case-project-obtained-credential';
import { ServiceType } from '../../api/system-config/service-type';
import { AccreditationStatus } from '../../api/system-config/university';
import { ObtainedCredentialFlag } from '../../api/case/obtained-credential-flag';
import { OtherServicesOfMediaContact } from '../../api/case/other-services-of-media-contact';
import { QuoteRequestPreviewEmail } from '../../api/case/quote-request-preview-email';

@Injectable({
  providedIn: 'root'
})
export class CaseService {

  constructor(private httpClient: HttpClient,
              private httpCacheService: HttpCacheService,
              private globalLoader: GlobalLoaderService,
              private titleCasePipe: TitleCasePipe) {

  }

  public get caseDefault(): CaseIntake {
    const genders = Gender;
    const value = {
      candidate: {
        candidateSex: genders.Unspecified
      },
      email: {
        creator: {
          id: null,
        },
        messageSubject: null,
        messageBody: null,
        originalMessageBody: null,
        creationTime: new Date().valueOf()
      },
      caseServices: [],
      sourceFiles: [],
      holdReason: this.holdReasonDefault,
      markupAssignee: null,
      rfedeadline: null
    };

    return clone(value);
  }

  public get holdReasonDefault(): NoteMessage {
    return clone({
      creator: {
        id: null,
      },
      category: MessageCategory.HOLD,
      messageSubject: null,
      messageBody: null,
      originalMessageBody: null,
      messageType: MessageType.Note,
      creationTime: new Date().valueOf()
    });
  }

  public createCase(newCase: CaseIntake): Observable<Case> {
    this.cleanCase(newCase);

    return this.httpClient.post<Case>('/api/case', newCase);
  }

  public saveDraftCase(newCase: CaseIntake): Observable<Case> {
    this.cleanCase(newCase);

    const caseToSend = clone(newCase);

    return this.httpClient.post<Case>('/api/case/draft', caseToSend);
  }

  public cancelDraftCase(caseId: number, note: NoteMessage): Observable<Case> {
    return this.httpClient.put<Case>(`/api/case/draft/${caseId}/cancel`, note);
  }

  public get(id: number, opts?: { nocache?: boolean, loaderIgnoreOnce?: boolean }): Observable<Case> {
    const url = `/api/case/${id}`;

    if (opts && opts.nocache) {
      this.httpCacheService.ignoreOnce(url);
    }

    if (opts && opts.loaderIgnoreOnce) {
      this.globalLoader.ignoreOnce(url);
    }

    return this.httpClient.get<Case>(url);
  }

  public getAll(): Observable<Case[]> {
    return this.httpClient.get<Case[]>(`/api/case`);
  }

  public getAllPaginated(request: PaginatedData<CasePaginated>): Observable<PaginatedData<CasePaginated>> {
    return this.httpClient
      .post<any>(`/api/case/paginated`, request.toObject())
      .pipe(
        // Update the given pagination object with values from the response.
        map((response: PaginateObject<CompanyPaginated>) => request.update(response))
      );
  }

  public getAllServicesPaginated(request: PaginatedData<CaseServicePaginated>)
    : Observable<PaginatedData<CaseServicePaginated>> {
    return this.httpClient
      .post<any>(`/api/case/service/paginated`, request.toObject())
      .pipe(
        // Update the given pagination object with values from the response.
        map((response: PaginateObject<CaseServicePaginated>) => request.update(response))
      );
  }

  public count(filter: string): Observable<CountResponse> {
    const data = new PaginatedData<any>();
    data.query = filter;
    return this.httpClient.post<CountResponse>(`/api/case/counts`, data.toObject());
  }

  public countServices(filter: string): Observable<CountResponse> {
    const data = new PaginatedData<any>();
    data.query = filter;
    // data.addColumnValue('id');
    return this.httpClient.post<CountResponse>(`/api/case/service/counts`, data.toObject());
  }

  public updateCaseServices(caseId: number, services: ModifyServiceRequest[]): Observable<Case> {
    return this.httpClient
      .put<Case>(`/api/case/${caseId}/services`, services);
  }

  public updateAdditionalServices(caseServiceId: number, additionalServices: number[]): Observable<Case> {
    return this.httpClient.put<Case>(`/api/case/service/${caseServiceId}/additional-services`, additionalServices);
  }

  public addCaseService(caseId: number, service: Service): Observable<Case> {
    return this.httpClient
      .post<Case>(`/api/case/${caseId}/service`, service);
  }

  public removeCaseService(caseId: number, serviceId: number): Observable<Case> {
    return this.httpClient
      .delete<Case>(`/api/case/${caseId}/service/${serviceId}`);
  }

  public removeCaseServices(caseId: number, theCaseServiceIds: number[]): Observable<Case> {
    return this.httpClient
      .request<Case>('delete', `/api/case/${caseId}/services`, {body: theCaseServiceIds});
  }

  public addCompletedTranslations(caseId: number, fileIds: number[]): Observable<CaseTranslatedFile[]> {
    return this.httpClient
      .post<FileSystemKey[]>(`/api/case/${caseId}/completed-translation-files`, fileIds);
  }

  public addSourceFiles(caseId: number, fileIds: number[]): Observable<CaseSourceFile[]> {
    return this.httpClient
      .post<CaseSourceFile[]>(`/api/case/${caseId}/source-files`, fileIds);
  }

  public updateCaseCandidate(caseId: number, candidate: Candidate): Observable<Candidate> {
    return this.httpClient.put<Candidate>(`/api/case/${caseId}/candidate`, candidate);
  }

  public updateCaseDeadline(caseId: number, deadline: number): Observable<Case> {
    return this.httpClient.put<Case>(`/api/case/${caseId}/deadline`, deadline);
  }

  public updateRfeDeadline(caseId: number, deadline: number): Observable<Case> {
    return this.httpClient.put<Case>(`/api/case/${caseId}/rfe-deadline`, deadline);
  }

  public updateCaseTurnaround(caseId: number, turnaround: TurnAroundTime): Observable<Case> {
    return this.httpClient.put<Case>(`/api/case/${caseId}/turnaround-type`, turnaround);
  }

  public updateCaseIntakeDate(caseId: number, intakeDate: number): Observable<Case> {
    return this.httpClient.put<Case>(`/api/case/${caseId}/startdate`, intakeDate);
  }

  public updateCaseDeliveryStatus(caseId: number, deliveryPreferences: CaseDeliveryPreferences): Observable<Case> {
    return this.httpClient.put<Case>(`/api/case/${caseId}/delivery-preferences`, deliveryPreferences);
  }

  public updateCaseReference(caseId: number, type: CaseReferenceType, value: string): Observable<Case> {
    return this.httpClient.put<Case>(`/api/case/${caseId}/reference-number/${type}`, value);
  }

  public updateCaseCustomerInfo(caseId: number, customerInfo: CaseCustomerInfoUpdateRequest): Observable<Case> {
    return this.httpClient.put<Case>(`/api/case/${caseId}/customer-info`, customerInfo);
  }

  public getEvaluationFiles(caseId: number, caseServiceId: number): Observable<EvaluationFile[]> {
    return this.httpClient
      .get<EvaluationFile[]>(`/api/case/${caseId}/service/${caseServiceId}/evaluation-files`);
  }

  public toggleTranslationComplete(caseId: number, complete: boolean, confirmed: boolean):
    Observable<ProcessResponseMessage> {
    return this.httpClient
      .put<ProcessResponseMessage>(
        `/api/case/${caseId}/toggle-complete-translation/${complete}/confirmed/${confirmed}`, null);
  }

  public generateServiceDeliveryFile(caseServiceId: number, fileKey: string): Observable<FileSystemKey> {
    return this.httpClient.post<FileSystemKey>(`/api/case/service/${caseServiceId}/delivery-file/${fileKey}`, null);
  }

  public replaceDeliveryFile(caseServiceId: number, request: ReplaceDeliveyFileRequest): Observable<FileSystemKey> {
    return this.httpClient
      .post<FileSystemKey>(
        `/api/case/service/${caseServiceId}/replace-delivery-files`, request);
  }

  public toggleExpertSourceFileAccess(caseId: number, sourceFileId: number, access: boolean):
    Observable<CaseSourceFile> {
    return this.httpClient
      .put<CaseSourceFile>(`/api/case/${caseId}/source/file/${sourceFileId}/expert-access/${access}`, null);
  }

  public toggleTranslatedSourceFileAccess(caseId: number, translatedFileId: number, makeSource: boolean):
    Observable<CaseTranslatedFile> {
    return this.httpClient
      .put<CaseTranslatedFile>(`/api/case/${caseId}/translation/file/${translatedFileId}/source/${makeSource}`, null);
  }

  public toggleTranslatedDeliveryFileAccess(caseId: number, translatedFileId: number, makeDelivery: boolean):
    Observable<CaseTranslatedFile> {
    return this.httpClient
      .put<CaseTranslatedFile>(
        `/api/case/${caseId}/translation/file/${translatedFileId}/delivery/${makeDelivery}`, null);
  }

  public updateCaseBillingContact(caseId: number, caseBillingContact: CaseBillingContact):
    Observable<CaseBillingContact> {
    return this.httpClient
      .put<CaseBillingContact>(`/api/case/${caseId}/billing/contact`, caseBillingContact);
  }

  public createBatch(batch: BatchCaseRequest): Observable<BatchCaseResponse> {
    return this.httpClient
      .post<BatchCaseResponse>(`/api/case/batch`, batch);
  }

  public buildBatchIntakeData(spreadSheetFsKey: string, sourceFsKey: string, data: SpreadsheetHeaderData)
    : Observable<BulkProjectResponse> {

    const url = `/api/case/bulk/build-bulk-case-intake-data/${spreadSheetFsKey}/source-files/${sourceFsKey}`;
    return this.httpClient
      .post<BulkProjectResponse>(url, data);
  }

  public getBatchCommonData(batchNumber: number): Observable<BatchCaseCommonData> {
    return this.httpClient.get<BatchCaseCommonData>(`api/case/batch/${batchNumber}`);
  }

  /**
   * Generates the field pairing list for the bulk intake form
   * @param fileKey Filekey property of the excel spreadsheet
   */
  public parseBulkData(fileKey: string): Observable<SpreadsheetHeaderData> {
    return this.httpClient
      .get<SpreadsheetHeaderData>(`/api/case/bulk/parse-header/${fileKey}`);
  }


  public removeCaseFile(caseId: number, fileId: number, fileType: CaseFileType): Observable<Case> {
    let url = `/api/case/${caseId}/remove/file/${fileId}`;

    const queryOptions: ApiQueryOptions = {
      queryParams: {fileType}
    };

    url += new ApiQueryParams(queryOptions).generateQueryParams();

    return this.httpClient.get<Case>(url);
  }

  public addTranslationFile(caseId: number, fileId: number): Observable<Case> {
    return this.httpClient.post<Case>(`/api/case/${caseId}/file/${fileId}/translation-file`, null);
  }

  private cleanCase(newCase: CaseIntake): void {
    // Remove email property if body is empty.
    if (newCase.email && isNullOrUndefinedOrBlank(newCase.email.messageBody)) {
      delete newCase.email;
    }

    const toTrimProperties: string[] = [
      'clientReference1',
      'clientReference2',
      'employeeId',
      'email.messageBody',
      'candidate.candidateName',
      'candidate.positionTitle',
      'candidate.employerEndClient',
      'candidate.degreesRequired',
      'caseContact.firstName',
      'caseContact.lastName',
      'caseContact.email',
      'caseContact.phoneNumber.phoneNumber'
    ];

    // Trim the whitespaces of text fields
    for (const propertyPath of toTrimProperties) {
      let propertyValue = _get(newCase, propertyPath);

      if (isNullOrUndefined(propertyValue) || typeof propertyValue !== 'string') {
        continue;
      }

      propertyValue = propertyValue.trim();

      // Set the trimmed value
      _set(newCase, propertyPath, propertyValue);
    }

    if (newCase.holdReason && isNullOrUndefinedOrBlank(newCase.holdReason.messageBody)) {
      newCase.holdReason = null;
    }

    // Fix capitalization for Candidate Name
    if (newCase.candidate) {
      newCase.candidate.candidateName = this.autoCapitalizeName(newCase.candidate.candidateName);
    }

    if (newCase.caseServices) {
      if (!newCase.caseServices.some(service => service.service.serviceType === ServiceType.AcademicEvaluation)) {
        newCase.caseProjectObtainedCredential = null;
      }
    }

    if (newCase.caseProjectObtainedCredential && !newCase.caseProjectObtainedCredential.automatedAcademicEvaluation) {
      newCase.caseProjectObtainedCredential = { automatedAcademicEvaluation: false };
    }
  }

  public getQualityIssues(caseServiceId: number, serviceStage: ServiceStageValue): Observable<QualityIssueForm> {
    return this.httpClient
      .get<QualityIssueForm>(`/api/case/service/${caseServiceId}/quality-issues/${serviceStage}`);
  }

  public downloadAsDeliverables(theBatchId: number,
                                batchDeliverableRequest: BatchCaseServiceDeliverableRequest): void {
    const url = `/api/case/bulk/deliverable/download/`;
    const queryOptions: ApiQueryOptions = {
      queryParams: { caseProjectIds: batchDeliverableRequest.caseProjectIds }
    };
    this.httpClient.post<any>(url, batchDeliverableRequest)
      .toPromise()
      .then(resp => {
        let downloadUrl = 'api/public/case/bulk/' + theBatchId + '/deliverable/zip/' + resp.response;
        downloadUrl += new ApiQueryParams(queryOptions).generateQueryParams();
        window.open(downloadUrl, '_self');
      });
  }

  public getCaseServicePdfProcessStatus(caseServiceId: number): Observable<PdfServiceStatus> {
    return this.httpClient
      .get<PdfServiceStatus>(`/api/case/service/${caseServiceId}/pdf-status`);
  }

  public updateSalesPerson(caseId: number, salesPersonId: number): Observable<Case> {
    if (salesPersonId) {
      return this.httpClient
        .put<Case>(`/api/case/${caseId}/sales-person/${salesPersonId}`, null);
    } else {
      return this.httpClient
        .put<Case>(`/api/case/${caseId}/sales-person`, null);
    }
  }

  public updateAccountManager(caseId: number, accountManagerId: number): Observable<Case> {
    if (accountManagerId) {
      return this.httpClient.put<Case>(`/api/case/${caseId}/account-manager/${accountManagerId}`, null);
    } else {
      return this.httpClient.put<Case>(`/api/case/${caseId}/sales-person`, null);
    }
  }

  public getSalesforceOpportunity(caseId: number): Observable<SalesforceOpportunity> {
    return this.httpClient.get<SalesforceOpportunity>(`/api/salesforce/${SystemType.ParkEval}/${caseId}`);
  }

  public syncSalesforceOpportunity(caseId: number): Observable<void> {
    return this.httpClient.post<void>(`/api/salesforce/${SystemType.ParkEval}/${caseId}`, null);
  }

  public regenerateDeliveryFilename(serviceIds: number[]): Observable<any> {
    const url = `/api/case/regenerate/delivery/filename`;
    return this.httpClient.put<any>(url, serviceIds);
  }

  public getTranslationActivities(caseId: number): Observable<CaseTranslationActivity[]> {
    const url = `/api/case/${caseId}/case-translation-activities`;
    return this.httpClient.get<CaseTranslationActivity[]>(url);
  }

  private autoCapitalizeName(name: string): string {
    if (isNullOrUndefinedOrBlank(name) || typeof name !== 'string') {
      return '';
    }
    return this.titleCasePipe.transform(name.trim());
  }

  public updateCaseHoldMessage(caseId: number, note: NoteMessage): Observable<NoteMessage> {
    return this.httpClient.put<NoteMessage>(`/api/case/hold-note/${caseId}`, note);
  }

  public getOtherServicesOfMediaContact(adsCaseServiceId: number): Observable<OtherServicesOfMediaContact[]> {
    const url = `/api/park-ads/case-service/${adsCaseServiceId}/other-services-of-media-contact`;
    return this.httpClient.get<OtherServicesOfMediaContact[]>(url);
  }

  public scanAcademicEquivalence(caseData: CaseIntake): Observable<CaseProjectObtainedCredential> {
    // Always clear scannedObtainedCredential before creating a request or else backend will return the same values
    caseData.caseProjectObtainedCredential.scannedObtainedCredential = {};

    return this.httpClient
      .post<CaseProjectObtainedCredential>(`/api/case/intake/scan-academic-equivalence`, caseData);
  }

  public isAcaNextStageProofreading(obtainedCredential: CaseProjectObtainedCredential): boolean {
    try {
      const isSchoolAccredited = obtainedCredential.school.accreditationStatus === AccreditationStatus.Accredited;
      const isNoFlag = obtainedCredential.scannedObtainedCredential.flag === ObtainedCredentialFlag.None;

      return isSchoolAccredited && isNoFlag;
    } catch (e) {
      // If no school or scannedObtainCredential, this will error out.
      // Instead of adding additional null checks for those values,
      // we will just catch null errors here and return false if one of those values we need is null
      return false;
    }
  }

  public isSchoolNotAccredited(obtainedCredential: CaseProjectObtainedCredential): boolean {
    const schoolAccreditation: AccreditationStatus = obtainedCredential.school.accreditationStatus;
    return schoolAccreditation === AccreditationStatus.Unknown ||
      schoolAccreditation === AccreditationStatus.NotAccredited;
  }

  public isAcaFlaggedMarkupOnly(obtainedCredential: CaseProjectObtainedCredential): boolean {
    return obtainedCredential.scannedObtainedCredential.flag === ObtainedCredentialFlag.MarkupOnly;
  }

  public isAcaFlaggedMarkupThenWriting(obtainedCredential: CaseProjectObtainedCredential): boolean {
    return obtainedCredential.scannedObtainedCredential.flag === ObtainedCredentialFlag.MarkupAndWriting;
  }

  public isAcaSkipMarkupAndWriting(obtainedCredential: CaseProjectObtainedCredential): boolean {
    return obtainedCredential.scannedObtainedCredential.flag === ObtainedCredentialFlag.None;
  }

  public isAcaWritingOnly(obtainedCredential: CaseProjectObtainedCredential): boolean {
    try {
      return obtainedCredential.scannedObtainedCredential.flag === ObtainedCredentialFlag.WritingOnly;
    } catch (e) {
      return false;
    }
  }

  public getSuggestedExpertDeadline(caseId: number): Observable<number> {
    return this.httpClient
      .get<number>(`/api/case/${caseId}/suggested-expert-deadline`);
  }

  public getDefaultDeadlineTime(): Observable<number> {
    return this.httpClient.get<number>(`/api/case/default-deadline-time`);
  }

  public createPreviewEmail(newPreviewEmail: QuoteRequestPreviewEmail): Observable<QuoteRequestPreviewEmail> {
    return this.httpClient
      .post<QuoteRequestPreviewEmail>('/api/park-ads/vendor-quote-request/preview-email', newPreviewEmail);
  }
}
