import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from './auth.guard';
import { ExistingSessionGuard } from './existing-session.guard';
import { StartupEssentialsGuard } from './startup-essentials.guard';
import { InternalCasesGuard } from './internal-cases.guard';
import { InstanceGuard } from './instance.guard';
import { SystemSetParkadsModuleGuard } from './system-set-parkads-module.guard';
import { SystemSetParkevalModuleGuard } from './system-set-parkeval-module.guard';
import { UserGuard } from './user.guard';
import { ParkEvalOnlyGuard } from './park-eval-only.guard';
import { ParkAdsOnlyGuard } from './park-ads-only.guard';
import { StartupEssentialsAdsGuard } from './startup-essentials-ads.guard';
import { StyleIndexGuard } from './style-index.guard';
import { StyleIndexAdminGuard } from './style-index-admin.guard';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    AuthGuard,
    ExistingSessionGuard,
    StartupEssentialsGuard,
    StartupEssentialsAdsGuard,
    InternalCasesGuard,
    InstanceGuard,
    SystemSetParkadsModuleGuard,
    SystemSetParkevalModuleGuard,
    UserGuard,
    ParkEvalOnlyGuard,
    ParkAdsOnlyGuard,
    StyleIndexGuard,
    StyleIndexAdminGuard,
  ],
})
export class GuardsModule { }
