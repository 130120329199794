<div class="text-center pt-3 bg-white">
  <img src="assets/park-evaluations-logo.svg" width="160">
  <hr />
</div>

<!-- Error Body -->
<div class="d-flex flex-column justify-content-center error-container mt-5">
  <div class="flex-grow text-center">
    <h1>404 Error</h1>
    <h5>The page you're trying to reach does not exist!</h5>
    <p>Please check your url.</p>
  </div>
</div>

