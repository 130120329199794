import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class InstanceGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) { }
  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // An important thing to note about the instance selector is that the user has
    // to have both the permissions for the instances. Otherwise, we will redirect them accordingly.
    // return this.authService
    //   .hasValidSession()
    //   .pipe(
    //     tap((session) => {
    //       if(!session) {
    //         this.router.navigate(['login']);
    //       }
    //
    //       // If we have session - check if we
    //       // this.authService.currentUser.authorities
    //     })
    //   );

    // NOTE: This is a temporary placeholder until we are able to figure out where to get the permissions from.
    return of(true);
  }
}
