import * as tslib_1 from "tslib";
import { WorkflowService } from './workflow.service';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { clone } from '../../utils/clone';
import { includes } from '../../utils/includes';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var WorkflowAdsService = /** @class */ (function (_super) {
    tslib_1.__extends(WorkflowAdsService, _super);
    function WorkflowAdsService(httpClient) {
        var _this = _super.call(this, httpClient) || this;
        _this.workflows = [];
        return _this;
    }
    WorkflowAdsService.prototype.init = function () {
        var _this = this;
        return this.httpClient
            .get('/api/park-ads/workflow-actions')
            .pipe(tap(function (response) { return _this.workflows = response; }));
    };
    WorkflowAdsService.prototype.getByIds = function (ids) {
        return clone(this.workflows.filter(function (workflow) { return includes(ids, workflow.id); }));
    };
    WorkflowAdsService.prototype.updateAdsAction = function (requests) {
        return this.httpClient
            .post("/api/park-ads/workflow/update", requests);
    };
    WorkflowAdsService.prototype.getCaseActions = function (caseId) {
        return this.httpClient
            .get("/api/park-ads/workflow-actions/case/" + caseId);
    };
    WorkflowAdsService.prototype.getServiceActions = function (serviceId) {
        return this.httpClient
            .get("/api/park-ads/workflow-actions/service/" + serviceId);
    };
    WorkflowAdsService.ngInjectableDef = i0.defineInjectable({ factory: function WorkflowAdsService_Factory() { return new WorkflowAdsService(i0.inject(i1.HttpClient)); }, token: WorkflowAdsService, providedIn: "root" });
    return WorkflowAdsService;
}(WorkflowService));
export { WorkflowAdsService };
