import { Injectable } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { SystemTermsService } from '../../services/data/system-terms.service';
import { User } from '../../api/user/user';
import { BehaviorSubject } from 'rxjs';
import { UserType } from '../../api/user/user-type';

@Injectable({
  providedIn: 'root'
})
export class TermsOverlayService {

  public shown = new BehaviorSubject(false);

  constructor(private authService: AuthService,
              private sysTerms: SystemTermsService) {

    this.authService
      .status
      .subscribe((user) => {
        this.checkTerms(user);
      });
  }

  private async checkTerms(user: User): Promise<void> {
    if (!user || user.userType === UserType.Internal) {
      this.shown.next(false);
      return;
    }

    const terms = await this.sysTerms
      .get(user.organizationId)
      .toPromise();

    // If bypassed or accepted do not process
    if (terms.bypass || terms.acknowledge) {
      this.shown.next(false);
      return;
    }

    this.shown.next(true);
  }
}
