import { clone } from '../../utils/clone';
export var PAGINATE_NO_LIMIT = 1000000;
/**
 * Class for handling pagination data requests and responses.
 * Type for the paginated data must be specified.
 *
 * Direct access to the pagination object is not allowed.
 * Any adjustments to the paginated data must come through here.
 * This provides us flexibility when adding features or functions to the paginated request/response in the future.
 */
var PaginatedData = /** @class */ (function () {
    function PaginatedData(opts) {
        if (opts) {
            this.paginationObject = clone(opts);
        }
        else {
            // Get the default settings
            this.setupDefaults();
        }
    }
    Object.defineProperty(PaginatedData.prototype, "size", {
        get: function () {
            return this.paginationObject.page.size;
        },
        set: function (size) {
            this.paginationObject.page.size = size;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaginatedData.prototype, "page", {
        get: function () {
            return this.paginationObject.page.number;
        },
        set: function (num) {
            this.paginationObject.page.number = num;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaginatedData.prototype, "query", {
        get: function () {
            return this.paginationObject.filter;
        },
        set: function (query) {
            this.paginationObject.filter = query;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaginatedData.prototype, "scope", {
        get: function () {
            return this.paginationObject.scope;
        },
        set: function (scope) {
            this.paginationObject.scope = scope;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaginatedData.prototype, "scopeId", {
        get: function () {
            return this.paginationObject.groupId;
        },
        set: function (id) {
            this.paginationObject.groupId = id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaginatedData.prototype, "content", {
        get: function () {
            return this.paginationObject.page.content;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaginatedData.prototype, "total", {
        get: function () {
            return this.paginationObject.page.totalElements;
        },
        enumerable: true,
        configurable: true
    });
    PaginatedData.prototype.toObject = function () {
        if (!this.paginationObject.groupId) {
            delete this.paginationObject.groupId;
        }
        // This method is often called to create the HTTP request
        // Make sure we clear all the data when creating the request object.
        var requestObject = clone(this.paginationObject);
        // Make sure to clear the column values
        for (var _i = 0, _a = requestObject.columnValues; _i < _a.length; _i++) {
            var colVal = _a[_i];
            colVal.values = [];
        }
        // Strip all the current content data.
        requestObject.page.content = [];
        // Cleanup.
        // Need to comment this out to not flicker the paginator
        // delete requestObject.page.totalElements;
        // delete requestObject.page.numberOfElements;
        // delete requestObject.page.totalPages;
        // delete requestObject.page.first;
        // delete requestObject.page.last;
        return requestObject;
    };
    /**
     * Safely update the value of the current pagination option
     * Most used when updating the instance with a backend response.
     * @param value
     */
    PaginatedData.prototype.update = function (value) {
        // We don't really need update the whole object as we keep the current ones we have on the instance.
        this.paginationObject.page.content = value.page.content;
        this.paginationObject.page.totalElements = value.page.totalElements;
        this.paginationObject.page.first = value.page.first;
        this.paginationObject.page.last = value.page.last;
        // this.paginationObject.columnValues = value.columnValues;
        for (var _i = 0, _a = value.columnValues; _i < _a.length; _i++) {
            var responseValue = _a[_i];
            this.updateColumnValue(responseValue);
        }
        return this;
    };
    /**
     * Single field sorting.
     * @param field
     * @param order
     * @param nullsFirst
     */
    PaginatedData.prototype.sort = function (field, order, nullsFirst) {
        if (nullsFirst === void 0) { nullsFirst = false; }
        this.paginationObject.page.sort = [];
        this.paginationObject.page.sort = [
            { propertyName: field, sortOrder: this.translateSortOrder(order), nullsFirst: !!nullsFirst }
        ];
    };
    PaginatedData.prototype.getCurrentSort = function () {
        var sort = this.paginationObject.page.sort[0];
        if (sort) {
            return {
                field: sort.propertyName,
                sort: this.translateSortOrderReverse(sort.sortOrder),
                nullsFirst: !!sort.nullsFirst
            };
        }
        return null;
    };
    PaginatedData.prototype.sortMulti = function (sortParams) {
        var _this = this;
        this.paginationObject.page.sort = sortParams.map(function (param) {
            return {
                propertyName: param.field,
                sortOrder: _this.translateSortOrder(param.order),
                nullsFirst: !!param.nullsFirst
            };
        });
    };
    PaginatedData.prototype.getCurrentMultiSort = function () {
        var _this = this;
        if (!(Array.isArray(this.paginationObject.page.sort))) {
            return [];
        }
        return this.paginationObject.page.sort.map(function (sort) {
            return {
                field: sort.propertyName,
                order: _this.translateSortOrderReverse(sort.sortOrder)
            };
        });
    };
    PaginatedData.prototype.clearSort = function () {
        this.paginationObject.page.sort = [];
    };
    PaginatedData.prototype.addColumnValue = function (field, values, provideValuesForDropdown, readableField) {
        if (provideValuesForDropdown === void 0) { provideValuesForDropdown = false; }
        if (readableField === void 0) { readableField = ''; }
        // Check first if the field is existing already.
        var existingValue = this.paginationObject.columnValues.find(function (cvalue) { return cvalue.field === field; });
        // Override values if existing
        if (existingValue) {
            existingValue.values = values || existingValue.values || [];
            return;
        }
        // Otherwise add to the column value objects
        this.paginationObject
            .columnValues
            .push({ field: field, values: values || [], provideValuesForDropdown: provideValuesForDropdown, readableField: readableField });
    };
    PaginatedData.prototype.removeColumnValue = function (field) {
        this.paginationObject.columnValues = this.paginationObject
            .columnValues
            .filter(function (cvalue) { return cvalue.field !== field; });
    };
    PaginatedData.prototype.getColumnValue = function (field) {
        var colDef = this.paginationObject.columnValues.find(function (col) { return col.field === field; });
        if (colDef) {
            return colDef.values;
        }
        return [];
    };
    PaginatedData.prototype.getColumnValues = function () {
        return clone(this.paginationObject.columnValues);
    };
    /**
     * Override this value on specific cases.
     */
    PaginatedData.prototype.setupDefaults = function () {
        this.paginationObject = {
            columnValues: [],
            filter: '',
            page: {
                content: [],
                number: 0,
                size: 50,
                sort: [],
                totalElements: 0
            }
        };
    };
    PaginatedData.prototype.translateSortOrder = function (order) {
        if (order === 1) {
            return 'ASC';
        }
        return 'DESC';
    };
    PaginatedData.prototype.translateSortOrderReverse = function (order) {
        if (order === 'ASC') {
            return 1;
        }
        return -1;
    };
    PaginatedData.prototype.updateColumnValue = function (newValue) {
        var updateValue = this.paginationObject.columnValues.find(function (listValue) { return listValue.field === newValue.field; });
        if (updateValue) {
            updateValue.values = newValue.values;
        }
    };
    return PaginatedData;
}());
export { PaginatedData };
