import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take, tap } from 'rxjs/operators';
import { HttpCacheService } from '../services/http-cache.service';

@Injectable()
export class HttpCacheInterceptorService implements HttpInterceptor {

  private ignoredUrls: string[] = [
    '/api/public/validateEmail/',
    '/api/company/name/'
  ];

  constructor(private httpCache: HttpCacheService) {}

  /**
   * Provides AGGRESSIVE http GET request caching.
   *
   * When the same URL has been requested multiple times in XX seconds (adjustable in cache service),
   * they will share the same request instance instead of doing 3 separate request.
   *
   * Only works with GET requests.
   *
   * @param request
   * @param next
   */
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method !== 'GET') {
      return next.handle(request);
    }

    const ignored = this.ignoredUrls.some(url => request.url.startsWith(url));
    if (ignored) {
      return next.handle(request);
    }

    // Check if we have it in the ignore once list.
    if (this.httpCache.hasIgnoreOnce(request.url)) {
      return next.handle(request);
    }

    // Do cleanup first
    this.httpCache.cleanup();

    // Set urls and get current cache
    const origRequestUrl = request.url;
    let existingCache = this.httpCache.get(origRequestUrl);

    if (existingCache) {
      return existingCache
        .request
        .pipe(
          filter(val => val !== null),
          take(1),
          switchMap((val) => {
            if (val instanceof HttpErrorResponse) {
              return throwError(HttpErrorResponse);
            } else {
              const response = val as HttpResponse<any>;

              // Send cache hit to notifier, for debugging
              this.notifyCacheHit(origRequestUrl);

              // Make sure to always clone - as it can be mutated by the consumers
              return of(response.clone());
            }
          }),
        );
    } else {
      // Add it to the cache
      this.httpCache.add(request.url);
      existingCache = this.httpCache.get(origRequestUrl);
    }

    return next.handle(request)
      .pipe(
        catchError((err) => {
          // We need the cache to complete the request errors out
          // If we don't do this, the request will never be made again to the same URL
          return throwError(err);
        }),
        tap((event) => {
          if (event instanceof HttpResponse || event instanceof HttpErrorResponse) {
            // Save the response, we'll use this to check if the request was successful or not.
            existingCache.response = event;
            existingCache.request.next(event);
          }
        }),
        finalize(() => {
          // If there is no response (request maybe cancelled or suddenly no connection)
          // Make the cache expire, so it won't be served again.
          if (!existingCache.response) {
            this.httpCache.expireCache(existingCache);
          }

          existingCache.completed = true;
          this.httpCache.cleanupIgnoreOnce(request.url);
        })
      );
  }

  private notifyCacheHit(url: string): void {
    let enabled = false;
    try {
      // On production and test servers, set this value in local storage via chrome dev tools to debug http cache hits.
      // This will help in identifying http-cache related issues faster.
      enabled = window.localStorage.getItem('parkads_http_cache_debug') === 'true';
    } catch (e) {
      // Local storage not supported...
      // Or values not existing.
      // Fail silently because this is more of a debug thing.
    }

    if (!enabled) {
      return;
    }

    console.warn(`[${new Date().toISOString()}] Cache hit: ${url}`);
  }
}
