import { Component } from '@angular/core';
import { environment } from '../environments/environment';
import { AuthService } from './services/auth.service';
import { User } from './api/user/user';
import { Router, Event as RouterEvent, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { RaygunService } from './services/raygun.service';
import { ConnectionDaemonService } from './services/connection-daemon.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public styleIndexEnabled = false;

  constructor(private authService: AuthService,
              private router: Router,
              private raygunService: RaygunService,
              private connectionDaemonService: ConnectionDaemonService) {
    this.authService
      .status
      .subscribe(user => {
        // Trigger these events whenever the auth status changes.
        setTimeout(() => this.initStyleIndex(user));
        this.raygunService.setUser(user);
      });

    this.router
      .events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event) => {
        this.processRouterHooks(event);
      });

    this.connectionDaemonService.start();

  }

  /**
   * Checks if we can show the style index link.
   */
  private initStyleIndex(user: User): void {
    if (!user) {
      this.styleIndexEnabled = false;
      return;
    }

    // Always hide on production builds
    if (environment.production) {
      this.styleIndexEnabled = false;
      return;
    }

    // Always show on localhost.
    if (window.location.hostname === 'localhost') {
      this.styleIndexEnabled = true;
      return;
    }

    // Hidden by default.
    this.styleIndexEnabled = false;
  }

  private processRouterHooks(event: RouterEvent): void {
    window.scrollTo(0, 0);
    // ADD other router events handling here.
  }
}
