/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./terms-overlay.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../shared/terms-of-use-content/terms-of-use-content.component.ngfactory";
import * as i3 from "../shared/terms-of-use-content/terms-of-use-content.component";
import * as i4 from "../../../../node_modules/primeng/components/checkbox/checkbox.ngfactory";
import * as i5 from "primeng/components/checkbox/checkbox";
import * as i6 from "@angular/forms";
import * as i7 from "@angular/common";
import * as i8 from "./terms-overlay.component";
import * as i9 from "./terms-overlay.service";
import * as i10 from "../../services/data/system-terms.service";
import * as i11 from "@angular/router";
var styles_TermsOverlayComponent = [i0.styles];
var RenderType_TermsOverlayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TermsOverlayComponent, data: {} });
export { RenderType_TermsOverlayComponent as RenderType_TermsOverlayComponent };
function View_TermsOverlayComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "terms-overlay d-flex align-items-center flex-column justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "terms-container pr-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-terms-of-use-content", [], null, null, null, i2.View_TermsOfUseContentComponent_0, i2.RenderType_TermsOfUseContentComponent)), i1.ɵdid(3, 114688, null, 0, i3.TermsOfUseContentComponent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 12, "div", [["class", "mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "cb-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "p-checkbox", [["binary", "true"], ["label", "I agree to Park Evaluations Terms of Service"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.accepted = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_Checkbox_0, i4.RenderType_Checkbox)), i1.ɵdid(7, 49152, null, 0, i5.Checkbox, [i1.ChangeDetectorRef], { binary: [0, "binary"], label: [1, "label"] }, null), i1.ɵprd(1024, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.Checkbox]), i1.ɵdid(9, 671744, null, 0, i6.NgModel, [[8, null], [8, null], [8, null], [6, i6.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i6.NgControl, null, [i6.NgModel]), i1.ɵdid(11, 16384, null, 0, i6.NgControlStatus, [[4, i6.NgControl]], null, null), (_l()(), i1.ɵeld(12, 0, null, null, 4, "div", [["class", "mt-3 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["class", "btn btn-outline-secondary mr-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "button", [["class", "btn btn-primary"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.accept() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Confirm"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_7 = "true"; var currVal_8 = "I agree to Park Evaluations Terms of Service"; _ck(_v, 7, 0, currVal_7, currVal_8); var currVal_9 = _co.accepted; _ck(_v, 9, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 11).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 11).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 11).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 11).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 11).ngClassValid; var currVal_5 = i1.ɵnov(_v, 11).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 11).ngClassPending; _ck(_v, 6, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_10 = !_co.accepted; _ck(_v, 15, 0, currVal_10); }); }
export function View_TermsOverlayComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TermsOverlayComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showTerms; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TermsOverlayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-terms-overlay", [], null, null, null, View_TermsOverlayComponent_0, RenderType_TermsOverlayComponent)), i1.ɵdid(1, 114688, null, 0, i8.TermsOverlayComponent, [i9.TermsOverlayService, i10.SystemTermsService, i11.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TermsOverlayComponentNgFactory = i1.ɵccf("app-terms-overlay", i8.TermsOverlayComponent, View_TermsOverlayComponent_Host_0, {}, {}, []);
export { TermsOverlayComponentNgFactory as TermsOverlayComponentNgFactory };
