import { ReadableConfig } from './readable-config';
import { Country } from '../country';
import { SelectItem } from 'primeng/api';

export interface University extends ReadableConfig {
  country?: Country;
  thePrefix?: boolean;
  lastModified?: number;
  accreditationStatus?: AccreditationStatus;
}

export enum AccreditationStatus {
  Accredited = 'ACCREDITED',
  NotAccredited = 'NOT_ACCREDITED',
  Unknown = 'UNKNOWN',
}

export const ACCREDITATION_STATUSES: SelectItem[] = [
  { label: 'Accredited', value: AccreditationStatus.Accredited },
  { label: 'Not Accredited', value: AccreditationStatus.NotAccredited },
  { label: 'Unknown', value: AccreditationStatus.Unknown },
];
