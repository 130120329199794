import { Component, OnInit } from '@angular/core';
import { TermsOverlayService } from './terms-overlay.service';
import { Router } from '@angular/router';
import { SystemTermsService } from '../../services/data/system-terms.service';

@Component({
  selector: 'app-terms-overlay',
  templateUrl: './terms-overlay.component.html',
  styleUrls: ['./terms-overlay.component.scss']
})
export class TermsOverlayComponent implements OnInit {

  public showTerms = true;

  public accepted = false;

  constructor(private termsOverlayService: TermsOverlayService,
              private sysTerms: SystemTermsService,
              private router: Router) {
    this.termsOverlayService
      .shown
      .subscribe(shown => this.showTerms = shown);
  }

  public ngOnInit(): void {
  }

  public accept(): void {
    this.sysTerms
      .acceptForExpert()
      .subscribe(() => {
        this.showTerms = false;
      });
  }

  public cancel(): void {
    this.accepted = false;
    this.router.navigate(['logout']);
  }

}
