import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { UserRoleType } from '../../api/user/user-role-type';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';

export enum LoginMode {
  Login = 0,
  PasswordReset = 1,
  Signup = 2,
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  public username: string;

  public password: string;

  public invalidLogin: string = null;

  public oobCode: string;

  public querySub: Subscription;

  public user: string;

  public token: string;

  public mode = LoginMode.Login;

  public modes = LoginMode;

  constructor(private authService: AuthService,
              private router: Router,
              private route: ActivatedRoute) { }

  public ngOnInit(): void {
    this.querySub = this.route
      .queryParams
      .subscribe(() => {
        this.mode = LoginMode.Login;
        this.oobCode = this.route.snapshot.queryParamMap.get('oobCode');
        if (this.oobCode) {
          this.mode = LoginMode.PasswordReset;
        }

        // Do we have a new user signup
        // Check first if we have a signup code.
        // Note that if you reached this page, you probably do not have any session
        this.user = this.route.snapshot.queryParamMap.get('user');
        this.token = this.route.snapshot.queryParamMap.get('token');

        if (this.user && this.token) {
          this.mode = LoginMode.Signup;
        }
      });
  }

  public ngOnDestroy(): void {
    this.querySub.unsubscribe();
  }

  public login(form: NgForm): void {
    if (form.invalid) {
      return;
    }

    this.invalidLogin = null;

    this.authService
      .login(this.username, this.password)
      .toPromise()
      .then(() => this.authService
                        .hasValidSession()
                        .toPromise())
      .then((session) => {
        if (session) {
          this.router.navigate(['/']);
        } else {
          this.showInvalid();
        }
      })
      .catch(() => {
        this.showInvalid();
      });
  }

  private showInvalid(): void {
    this.invalidLogin = 'Invalid username or password';
  }
}
