export enum SystemConfigType {
  Services = 'SERVICES',
  Permissions = 'PERMISSIONS',
  SystemRoles = 'SYSTEM_ROLES',
  Degree = 'DEGREE',
  FieldOfStudy = 'FIELD_OF_STUDY',
  Billing = 'BILLING',
  AdsBilling = 'ADS_BILLING',
  ExclusionList = 'EXCLUSION_LIST',
  University = 'UNIVERSITY',
  AdditionalPricing = 'ADDITIONAL_PRICING',
  ServiceAndWorkflow = 'SERVICE_WORKFLOW',
  CountryPrefix = 'COUNTRY_PREFIX',
  ProgramDuration = 'PROGRAM_DURATION',
  MappedField = 'MAPPED_FIELD',
  Country = 'COUNTRY',
  ErrorType = 'ERROR_TYPE',
  SalesPerson = 'SALES_PERSON',
  Swa = 'SWA_STATE',
  OnlineConsumerSite = 'ONLINE_CONSUMER_SITE',
  OnlineTradeJournal = 'ONLINE_TRADE_JOURNAL',
  AdsServices = 'ADS_SERVICES',
  ParamConfig = 'PARAM_CONFIG',
  ReminderEmail = 'REMINDER_EMAIL',
  SystemDefaultUser = 'SYSTEM_DEFAULT_USER',
  AreaOfExpertise = 'AREA_OF_EXPERTISE',
  AcademicEquivalency = 'ACADEMIC_EQUIVALENCY',
  ObtainedCredential = 'OBTAINED_CREDENTIAL',
  SwaStateRequirements = 'SWA_STATE_REQUIREMENTS',
}
