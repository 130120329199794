import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-form-feedback',
  templateUrl: './form-feedback.component.html',
  styleUrls: ['./form-feedback.component.scss']
})
export class FormFeedbackComponent implements OnInit, OnDestroy {

  @Input()
  public control: NgModel;

  /**
   * Specifies which error keys to display.
   * This can be found in control.errors.
   */
  @Input()
  public errorKeys: string[];

  @Input()
  public errorOverride: { [key: string]: string } = {};

  constructor() { }

  public ngOnInit(): void {

  }

  public ngOnDestroy(): void {

  }

}
