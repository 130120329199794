import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { skipWhile, switchMap } from 'rxjs/operators';
import { isNullOrUndefinedOrBlank } from '../utils/is-null-or-undefined';
import { find } from '../utils/find';
import { UserPermissionType } from '../api/organization/user-permissions';

@Injectable({
  providedIn: 'root'
})
export class ParkAdsOnlyGuard implements CanActivate {

  constructor(private authService: AuthService) { }

  public canActivate(): Observable<boolean> {

    return this.authService
      .status
      .pipe(
        skipWhile(user => isNullOrUndefinedOrBlank(user)),
        switchMap((user) => {
          if (find(user.permissions, {permission: UserPermissionType.ParkAds})) {
            return of(true);
          }

          return throwError('You do not have permissions to access this module (ParkAds)');
        })
      );
  }

}
