import * as tslib_1 from "tslib";
import { ErrorHandler } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { RaygunService } from '../services/raygun.service';
var ErrorHandlerService = /** @class */ (function (_super) {
    tslib_1.__extends(ErrorHandlerService, _super);
    function ErrorHandlerService(injector, zone) {
        var _this = _super.call(this) || this;
        _this.injector = injector;
        _this.zone = zone;
        _this.prevError = null;
        // Make sure errors are cleaned up on initialization
        _this.clearErrors();
        return _this;
    }
    ErrorHandlerService.prototype.handleError = function (error) {
        var _this = this;
        // Clear the previous errors
        this.clearErrors();
        // Set the router;
        var router = this.injector.get(Router);
        // Set get the raygun service
        var raygun = this.injector.get(RaygunService);
        _super.prototype.handleError.call(this, error);
        // Make sure the error is logged.
        console.error(error);
        // Send it to raygun.
        raygun.send(error);
        // Save the last error for rendering on the error page.
        if (error instanceof HttpErrorResponse) {
            // If it is an http error, use response message as error;
            // For some reason, certain backend errors return http error response, and others do not.
            // If not - they are handled down futher below
            window.localStorage.setItem(ErrorHandlerService.storage, error.message);
        }
        else {
            window.localStorage.setItem(ErrorHandlerService.storage, error.toString());
        }
        window.localStorage.setItem(ErrorHandlerService.errorLocation, window.location.href);
        // Do not touch this unless you know what you're doing.
        // This prevents the infinite loop caused by template errors and errors we are unable to catch normally
        if (this.prevError === error.toString()) {
            this.drawFatalError();
            throw new Error('Fatal Error - ' + this.prevError);
        }
        // Set a new previous error if we reach here succesfully.
        this.prevError = error.toString();
        this.zone.run(function () {
            try {
                var stringError = error.toString();
                // NOTE: Do not move the HTTP error handling to the interceptor.
                // You will not be able to catch it at the consumer level
                // Sometimes, HTTP errors are parsed as http error response objects
                if (error instanceof HttpErrorResponse) {
                    _this.handleTypedHttpErrorResponse(error, router);
                    return;
                }
                // For HTTP errors that are not parsed properly as HttpErrorResponse
                if (stringError.indexOf('HttpErrorResponse') > -1 && stringError.indexOf('503') > -1) {
                    router.navigate(['error-503-maintenance'], { skipLocationChange: true });
                    return;
                }
                if (stringError.indexOf('HttpErrorResponse') > -1 && stringError.indexOf('502') > -1) {
                    router.navigate(['error-503-maintenance'], { skipLocationChange: true });
                    return;
                }
                if (stringError.indexOf('HttpErrorResponse') > -1 && stringError.indexOf('403') > -1) {
                    router.navigate(['error-403'], { skipLocationChange: true });
                    return;
                }
            }
            catch (e) {
                // If we couldn't stringify it, send to raygun so we know we have this instance of error.
                console.warn('Failed to parse error.');
                raygun.send(error, { note: 'Failed to parse error type from error handler' });
                // throw new Error('Fatal Error - ' + 'The error handler failed');
            }
            // Skip location change is essential as we want to user to be able to refresh on the current page
            // in which the error has occurred;
            router.navigate(['/error'], { skipLocationChange: true });
        });
    };
    ErrorHandlerService.prototype.clearErrors = function () {
        window.localStorage.removeItem(ErrorHandlerService.storage);
        window.localStorage.removeItem(ErrorHandlerService.errorLocation);
    };
    ErrorHandlerService.prototype.drawFatalError = function () {
        var ele = document.getElementById('fatal-error');
        ele.style.display = 'block';
        var messageDisplay = document.getElementById('extra-error-message');
        messageDisplay.innerText = this.prevError;
    };
    ErrorHandlerService.prototype.handleTypedHttpErrorResponse = function (error, router) {
        if (error.status === 503 || error.status === 502) {
            router.navigate(['error-503-maintenance'], { skipLocationChange: true });
            return;
        }
        if (error.status === 403) {
            router.navigate(['error-403'], { skipLocationChange: true });
            return;
        }
        // Default error navigation for http error
        router.navigate(['/error'], { skipLocationChange: true });
    };
    ErrorHandlerService.storage = 'parkeval_last_error';
    ErrorHandlerService.errorLocation = 'parkeval_last_error_location';
    return ErrorHandlerService;
}(ErrorHandler));
export { ErrorHandlerService };
