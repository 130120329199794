import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptorService implements HttpInterceptor {

  constructor(private router: Router) { }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // NOTE: This was deprecated but keeping this
    // Unfortunately, it's not wise to do it here as you won't be able to
    // to catch it at the consumer level if you want custom error handling for certain instances
    return next.handle(request);
      // .pipe(
      //   catchError((error) => {
      //     if (error.status === 502 || error.status === 503) {
      //       this.router.navigate(['error-503-maintenance'], { skipLocationChange: true });
      //     } else if (error.status === 403) {
      //       this.router.navigate(['error-403'], { skipLocationChange: true });
      //     }
      //
      //     // For testing only so you don't need to simulate.
      //     // Call an api that is not existing.
      //     // if (error.status === 404) {
      //     //   this.router.navigate(['error-503-maintenance'], { skipLocationChange: true });
      //     // }
      //
      //     return observableThrowError(error);
      //   }),
      // );
  }

}
