<div class="top w-100"></div>

<div class="container d-flex selection-container pb-5" style="margin-top: 100px">
  <a routerLink="/main" class="selection parkeval selection-padding d-flex flex-column">
      <img class="park-logos" src="assets/park-evaluations-logo-white.svg" />
    <div class="mt-auto h4 text-white mb-0">Park Evaluations (PM)</div>
    <div class="visit-link">
      Visit Park Evaluations Software <i class="fa fa-arrow-right d-inline-block ml-2"></i>
    </div>
  </a>
  <a routerLink="/park-ads/internal"  class="selection parkads">
    <img class="diagonal-effect" src="assets/images/park-advertising-header-diagonals.png">
    <div class="d-flex flex-column height-inherit selection-padding">
      <img class="park-logos" src="assets/park-advertising-logo.png"/>
      <div class="mt-auto h4 text-white mb-0">Park Advertising (PM)</div>
      <div class="visit-link">
        Visit Park Advertising Software <i class="fa fa-arrow-right d-inline-block ml-2"></i>
      </div>
    </div>
  </a>
</div>
