import { SelectItem } from 'primeng/api';

export enum ObtainedCredentialFlag {
  None = 'NONE',
  MarkupOnly = 'MARKUP_ONLY',
  MarkupAndWriting = 'MARKUP_AND_WRITING',
  WritingOnly = 'WRITING_ONLY',
}

export const OBTAINED_CREDENTIAL_FLAGS: SelectItem[] = [
  { label: 'Skip Markup and Writing', value: ObtainedCredentialFlag.None },
  { label: 'Skip Markup', value: ObtainedCredentialFlag.WritingOnly },
  { label: 'Skip Writing', value: ObtainedCredentialFlag.MarkupOnly },
  { label: 'Standard Workflow', value: ObtainedCredentialFlag.MarkupAndWriting },
];
