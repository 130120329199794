import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { environment } from '../../environments/environment';
// import 'firebase/messaging';
// import 'firebase/firestore';
// import 'firebase/database';

@Injectable()
export class FirebaseService {

  public app: firebase.app.App;
  public auth: firebase.auth.Auth;
  // Other Firebase services if we need to use them in the future
  // public messaging: firebase.messaging.Messaging;
  // public firestore: firebase.firestore.Firestore;
  // public database: firebase.database.Database;

  constructor() {
    this.app = firebase.initializeApp(environment.firebase);
    this.auth = firebase.auth();
  }

  public fb(): any {
    return firebase;
  }
}
