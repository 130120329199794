import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-403',
  templateUrl: './error-403.component.html',
  styleUrls: ['./error-403.component.scss']
})
export class Error403Component implements OnInit {

  constructor() { }

  public ngOnInit(): void {
  }

  public reload(): void {
    window.location.reload();
  }

}
