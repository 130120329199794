<div class="main-box">
    <h3>Reset Password</h3>
    <p *ngIf="!isEmailLinkSent">Please enter your email address and we'll email you a link to reset your password.</p>
  <form #forgotPasswordForm="ngForm" *ngIf="!isEmailLinkSent">
    <div class="form-group">
     <label>Email Address</label>
      <input
        type="email"
        class="form-control"
        name="email"
        [(ngModel)]="email"
        appValidEmailValidator>
    </div>
    <div class="alert alert-danger mt-3"  *ngIf="errorMessage">
      {{errorMessage}}
     </div>
    <!-- Action Buttons -->
    <div class="text-center">
      <button
        [disabled]="forgotPasswordForm.invalid || requestInProgress"
        type="submit"
        class="btn btn-primary btn-block btn-lg"
        (click)="forgot()">
        Send password reset email
      </button>
      <a routerLink="../login" class="btn btn-link">Go Back</a>
    </div>
  </form>

  <div *ngIf="isEmailLinkSent" class="margin-top-30">
      <div class="mt-4">
        <p>We've sent a password reset e-mail to {{email}}.</p>
        <p>Follow the instructions in the e-mail then click
          <a class="forgot-password clear-text-button" [routerLink]="['/public/login']">here</a> to login!</p>
      </div>
  </div>
</div>
