import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-instance-selector',
  templateUrl: './instance-selector.component.html',
  styleUrls: ['./instance-selector.component.scss']
})
export class InstanceSelectorComponent implements OnInit {

  constructor() { }

  public ngOnInit(): void {
  }

}
