import { HttpClient } from '@angular/common/http';
import * as FileSaver from 'file-saver';
import { map, tap } from 'rxjs/operators';
import { ApiQueryParams } from './data/api-query-params';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var FileService = /** @class */ (function () {
    function FileService(httpClient) {
        this.httpClient = httpClient;
    }
    FileService.prototype.getFile = function (fileKey, autoDownload) {
        if (autoDownload === void 0) { autoDownload = true; }
        var url = "/api/file/download";
        var keys = [fileKey];
        return this.httpClient
            .post(url, keys)
            .pipe(tap(function (resp) {
            if (autoDownload) {
                window.open('/api/public/file/' + resp.response, '_blank');
            }
        }), map(function (response) { return response.response; }));
    };
    FileService.prototype.getFileById = function (id, autoDownload) {
        if (autoDownload === void 0) { autoDownload = true; }
        var url = "/api/file/" + id + "/download";
        return this.httpClient
            .post(url, null)
            .pipe(tap(function (resp) {
            if (autoDownload) {
                window.open('/api/public/file/' + resp.response, '_blank');
            }
        }), map(function (response) { return response.response; }));
    };
    FileService.prototype.getFiles = function (fileKeys, filename) {
        if (filename === void 0) { filename = null; }
        var tokenUrl = "/api/file/download";
        var queryOptions = {
            queryParams: { theFileName: filename }
        };
        this.httpClient.post(tokenUrl, fileKeys)
            .toPromise()
            .then(function (resp) {
            var downloadUrl = '/api/public/file/download-zip-file/' + resp.response;
            if (filename) {
                // Append the generated query params
                downloadUrl += new ApiQueryParams(queryOptions).generateQueryParams();
            }
            window.open(downloadUrl, '_self');
        });
    };
    FileService.prototype.saveFile = function (resp) {
        var contentDispositionHeader = resp.headers.get('Content-Disposition');
        var parts = contentDispositionHeader.split(';');
        var filename = parts[1].split('=')[1];
        FileSaver.saveAs(resp.body, filename);
    };
    FileService.prototype.removeFiles = function (fileKeys) {
        this.httpClient.post("/api/file/delete", fileKeys)
            .toPromise();
    };
    FileService.prototype.updateFilename = function (file, newFilename, fileExtension) {
        // if no extension specified, we use the same extension from the file provided
        if (!fileExtension) {
            fileExtension = file
                .fileName
                .split('.')
                .pop();
        }
        return this.httpClient
            .put("/api/file/" + file.fileKey + "/update-filename/" + newFilename + "." + fileExtension, null)
            .toPromise();
    };
    FileService.prototype.htmlToPdf = function (html, filename, download) {
        var _this = this;
        if (filename === void 0) { filename = 'download.pdf'; }
        if (download === void 0) { download = true; }
        var url = '/api/file/html-string-to-pdf?fileName=';
        if (filename) {
            url += filename;
        }
        else {
            url += 'downloaded-draft.pdf';
        }
        return this.httpClient
            .post(url, html)
            .pipe(tap(function (file) {
            _this.getFile(file.fileKey)
                .toPromise();
        }));
    };
    FileService.prototype.getTextAsset = function (file) {
        return this.httpClient
            .get(file, { observe: 'response', responseType: 'text' })
            .pipe(map(function (resp) { return resp.body; }));
    };
    FileService.ngInjectableDef = i0.defineInjectable({ factory: function FileService_Factory() { return new FileService(i0.inject(i1.HttpClient)); }, token: FileService, providedIn: "root" });
    return FileService;
}());
export { FileService };
