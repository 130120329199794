import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Action, RequestType, WorkflowAction, WorkflowActionRequest } from '../../api/workflow-action';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { clone } from '../../utils/clone';
import { includes } from '../../utils/includes';

@Injectable({
  providedIn: 'root'
})
export class WorkflowService {

  protected workflows: WorkflowAction[] = [];

  constructor(protected httpClient: HttpClient) {
  }

  /**
   * Sets the value of the workflow action request based on the type.
   * @param waf
   * @param requestType
   * @param value
   */
  public static setActionRequestValue(waf: WorkflowAction, requestType: RequestType, value: any): void {
    if (!waf) {
      console.warn('No workflow action');
      return;
    }

    const request = waf.workflowActionRequests
      .find((action) => action.type === requestType);
    if (request) {
      request.requestResponse = value;
    } else {
      console.warn('Request not found:', requestType);
    }
  }

  public init(): Observable<WorkflowAction[]> {
    return this.httpClient
      .get<WorkflowAction[]>('/api/tasks/workflow-actions')
      .pipe(tap((response) => this.workflows = response));
  }

  public getAll(): WorkflowAction[] {
    return clone(this.workflows);
  }

  public get(id: number): WorkflowAction {
    return clone(this.workflows.find(workflow => workflow.id === id));
  }

  public getByIds(ids: number[]): WorkflowAction[] {
    return clone(this.workflows.filter((workflow) => includes(ids, workflow.id)));
  }

  public getByActionType(action: Action): WorkflowAction {
    const foundWorkflow = this.workflows.find(workflow => workflow.action === action);

    if (foundWorkflow) {
      return clone(foundWorkflow);
    }

    return null;
  }

  public getRequestsForAction(ids: number[], action: WorkflowAction): WorkflowActionRequest[] {
    return action.workflowActionRequests.filter((request) => {
      return includes(ids, request.id);
    });
  }

}
