import { Injectable } from '@angular/core';
import { User } from '../../api/user/user';
import { Observable, of, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Organization } from '../../api/organization/organization';
import { ModelDataUpdate } from './data-update';
import { switchMap, tap } from 'rxjs/operators';
import { sortBy } from '../../utils/sort-by';
import { UserPermissionType } from '../../api/organization/user-permissions';
import { UserRoleType } from '../../api/user/user-role-type';
import { Authorizations } from '../../api/authorizations';

export class OrganizationModelUpdate extends ModelDataUpdate<Organization> { }

@Injectable({
  providedIn: 'root'
})
export class InternalOrganizationService {

  public updates: Subject<ModelDataUpdate<any>>;

  constructor(private httpClient: HttpClient) {
    this.updates = new Subject();
  }

  public get(id: number): Observable<Organization> {
    return this.httpClient
      .get<Organization>(`/api/internal-organization`);
  }

  public getUser(id: number): Observable<User> {
    return this.httpClient
      .get<User>(`/api/internal-organization/user/${id}`);
  }

  public getUsers(): Observable<User[]> {
    return this.httpClient
      .get<User[]>('/api/internal-organization/users')
      .pipe(
        switchMap(users => {
          return of(sortBy(users, (user) => {
            return user.firstName + ' ' + user.lastName;
          }));
        })
      );
  }

  public getAssignableUsers(permission?: UserPermissionType): Observable<User[]> {
    const url = permission ? `/api/internal-organization/assignable-users/permission/${permission}` :
      '/api/internal-organization/assignable-users';

    return this.httpClient
      .get<User[]>(url)
      .pipe(
        switchMap(users => {
          return of(sortBy(users, (user) => {
            return (user.firstName + ' ' + user.lastName).toLowerCase();
          }));
        })
      );
  }

  public getAssignableUsersByRoleAndPermission(role: Authorizations, permission?: UserPermissionType):
    Observable<User[]> {
    const url = permission ? `/api/internal-organization/assignable-users/role/${role}/permission/${permission}` :
      '/api/internal-organization/assignable-users';

    return this.httpClient
      .get<User[]>(url)
      .pipe(
        switchMap(users => {
          return of(sortBy(users, (user) => {
            return (user.firstName + ' ' + user.lastName).toLowerCase();
          }));
        })
      );
  }

  public saveUser(data: User): Observable<Organization> {
    if (data.id) {
      return this.httpClient
        .put<Organization>(`/api/internal-organization/user/${data.id}`, data)
        .pipe(
          tap(res => this.updates.next(new OrganizationModelUpdate(res)))
        );
    }

    return this.httpClient
      .post<Organization>('/api/internal-organization/user', data);
  }

  public addAdmin(data: User): Observable<User> {
    return this.httpClient
      .post<User>(`/api/internal-organization/user/${data.id}/add-admin`, data);
  }

  public removeAdmin(data: User): Observable<User> {
    return this.httpClient
      .post<User>(`/api/internal-organization/user/${data.id}/remove-admin`, data);
  }
}
