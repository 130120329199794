import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

export enum ConnectionDaemonStatusCode {
  Offline = 'OFFLINE',
  Backend = 'BACKEND',
  Unknown = 'UNKNOWN',
  Online = 'ONLINE',
}

@Injectable()
export class ConnectionDaemonService {

  public status = new BehaviorSubject<ConnectionDaemonStatusCode>(ConnectionDaemonStatusCode.Online);

  // The url path to check if an internet connection is active.
  public connectionCheckPath = '/assets/alive.txt';

  // Number of milliseconds before each each
  private interval = 15000; //  15 secs

  private isChecking = false;

  private lastCheck: 'CLIENT' | 'BACKEND';

  constructor(private http: HttpClient) { }

  public start(): void {

    setInterval(() => {
      // Don't do another one if previous one is still in progress
      if (this.isChecking) {
        return;
      }

      this.isChecking = true;

      this.checkConnection()
        .toPromise()
        .then(() => {
          this.status.next(ConnectionDaemonStatusCode.Online);
          this.isChecking = false;
        })
        .catch((err) => {
          this.isChecking = false;
          this.handlerError(err);
        });

    }, this.interval);
  }

  private checkConnection(): any {
    // Set the last checked endpoint so we know where the problem came from
    this.lastCheck = 'CLIENT';
    return this.http.get(this.connectionCheckPath, {responseType: 'text' });
  }

  private handlerError(err: HttpErrorResponse): void {
    if (this.lastCheck === 'CLIENT') {
      this.status.next(ConnectionDaemonStatusCode.Offline);
    } else {
      this.status.next(ConnectionDaemonStatusCode.Backend);
    }
  }

}
