import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
// tslint:disable:no-console
var LoggerService = /** @class */ (function () {
    function LoggerService() {
    }
    LoggerService.prototype.log = function (data) {
        if (environment.production) {
            return;
        }
        console.log(data);
    };
    LoggerService.ngInjectableDef = i0.defineInjectable({ factory: function LoggerService_Factory() { return new LoggerService(); }, token: LoggerService, providedIn: "root" });
    return LoggerService;
}());
export { LoggerService };
