import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstanceSelectorComponent } from './instance-selector.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [InstanceSelectorComponent],
  imports: [
    CommonModule,
    RadioButtonModule,
    RouterModule
  ]
})
export class InstanceSelectorModule { }
