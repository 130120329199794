import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms-of-use-content',
  templateUrl: './terms-of-use-content.component.html',
  styleUrls: ['./terms-of-use-content.component.scss']
})
export class TermsOfUseContentComponent implements OnInit {

  constructor() { }

  public ngOnInit(): void {
  }

}
