import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[appEqualToValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: EqualToValidatorDirective, multi: true }]
})
export class EqualToValidatorDirective implements Validator {

  @Input()
  public appEqualToValidator: any;

  constructor() {
  }

  public validate(control: AbstractControl): { [key: string]: any } {
    if (control.value === this.appEqualToValidator) {
      return null;
    }

    return { notEqualTo: `Value is not the same` };
  }
}
