import { Component, OnInit } from '@angular/core';
import { ConnectionDaemonService } from 'src/app/services/connection-daemon.service';

export enum ConnectionDaemonStatusCode {
  Offline = 'OFFLINE',
  Backend = 'BACKEND',
  Unknown = 'UNKNOWN',
  Online = 'ONLINE',
}

@Component({
  selector: 'app-offline-screen',
  templateUrl: './offline-screen.component.html',
  styleUrls: ['./offline-screen.component.scss']
})
export class OfflineServicesComponent implements OnInit {

    public statusCodes = ConnectionDaemonStatusCode;

    public currentStatus: ConnectionDaemonStatusCode;

    constructor(private connectionDaemon: ConnectionDaemonService) { }

    public ngOnInit(): void {
        this.connectionDaemon
        .status
        .subscribe((val) => {
            this.currentStatus = val;
        });
    }
}
