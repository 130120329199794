import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanDeactivate } from '@angular/router';
import { SystemConfigService } from '../services/data/system-config.service';
import { SystemModule } from '../api/system-config/system-module';

@Injectable()
export class SystemSetParkadsModuleGuard implements CanActivate, CanDeactivate<any> {

  constructor(private systemConfig: SystemConfigService) { }

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {

    this.systemConfig.setModule(SystemModule.ParkAds);
    return true;
  }

  public canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): boolean {

    this.systemConfig.setModule(SystemModule.None);
    return true;
  }
}
