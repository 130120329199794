import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { AuthService } from './auth.service';
import { UserService } from './data/user.service';
import { GlobalLoaderService } from './global-loader.service';
import { FirebaseService } from './firebase.service';
import { MainShellService } from './main-shell.service';
import { FileService } from './file.service';
import { CaseService } from './data/case.service';
import { ToastService } from './toast.service';
import { MessageService } from 'primeng/api';
import { HttpCacheService } from './http-cache.service';
import { InternalOrganizationService } from './data/internal-organization.service';
import { SystemTermsService } from './data/system-terms.service';
import { WorkflowService } from './data/workflow.service';
import { MessagingService } from './data/messaging.service';
import { TaskService } from './data/task.service';
import { RequestService } from './request-service';
import { RaygunService } from './raygun.service';
import { UnsavedChangesService } from './unsaved-changes.service';
import { VendorService } from './data/vendor.service';
import { WorkflowAdsService } from './data/workflow-ads.service';
import { LoggerService } from './logger.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    AuthService,
    UserService,
    FirebaseService,
    GlobalLoaderService,
    MainShellService,
    FileService,
    CaseService,
    MessageService,
    ToastService,
    HttpCacheService,
    InternalOrganizationService,
    SystemTermsService,
    WorkflowService,
    MessagingService,
    TaskService,
    RequestService,
    RaygunService,
    UnsavedChangesService,
    TitleCasePipe,
    VendorService,
    WorkflowAdsService,
    LoggerService,
  ]
})
export class ServicesModule { }
