import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class ExistingSessionGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  /**
   * This guard handles existing session and redirects them to the main page.
   * E.g - If the user is navigating to the login page but has an existing session
   */
  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {

    return this.authService
      .hasValidSession()
      .pipe(
        map((session) => {
          // If we have a session = we must return false because we do not want to load the login page.
          return !session;
        }),
        tap((session) => {
          // If session is false - since we inverted it earlier, route to the root to properly load existing session.
          if (!session) {
            this.router.navigate(['/']);
          }
        })
      );
  }

}
