import { Injectable } from '@angular/core';
import { User } from '../api/user/user';
import { NavigationEnd, NavigationError, Router } from '@angular/router';
import * as rg4js from 'raygun4js';
import { environment } from '../../environments/environment';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root'
})
export class RaygunService {

  constructor(
    private router: Router,
    private logger: LoggerService,
  ) {
    this.setup();
  }

  public setup(): void {
    // NOTE: Tracking must only be enabled on production servers.
    if (!environment.production) {
      this.logger.log('Raygun NOT initialized');
      return;
    }

    this.logger.log('Initializing raygun');

    rg4js('apiKey', environment.raygunApiKey);
    rg4js('setVersion', environment.version);
    rg4js('enableCrashReporting', true);
    rg4js('enablePulse', true);

    this.router
      .events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          // Track navigation end
          rg4js('trackEvent', {
            type: 'pageView',
            path: event.url
          });

        } else if (event instanceof NavigationError) {
          // Track navigation error
          rg4js('send', {
            error: event.error
          });
        }
      });
  }

  public setUser(user: User): void {
    if (!environment.production) {
      return;
    }

    if (!user) {
      return;
    }

    this.logger.log(`Setting up user for Raygun: ${user.email}`);

    rg4js('setUser', {
      identifier: user.id,
      isAnonymous: false,
      email: user.email,
      firstName: user.firstName,
      fullName: user.firstName + ' ' + user.lastName
    });
  }

  public send(error: any, customData?: any): void {
    if (!environment.production) {
      return;
    }

    this.logger.log('Sending Raygun Error:');
    this.logger.log(error);
    this.logger.log(customData);

    if (customData) {
      rg4js('send', { error, customData });
    } else {
      rg4js('send', { error });
    }
  }
}
