import { map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { sortBy } from '../../utils/sort-by';
import { fullName } from '../../utils/full-name';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var VendorService = /** @class */ (function () {
    function VendorService(httpClient) {
        this.httpClient = httpClient;
    }
    VendorService.prototype.save = function (data) {
        // Trim whitespace
        data.name = data.name.trim();
        if (data.id) {
            return this.httpClient
                .put("/api/media-vendor/general-info/" + data.id, data)
                .pipe(tap(function (res) {
                // Nothing here for now, just testing.
            }));
        }
        return this.httpClient.post("/api/media-vendor", data);
    };
    VendorService.prototype.getAllPaginated = function (request) {
        return this.httpClient
            .post("/api/media-vendor/paginated", request.toObject())
            .pipe(
        // Update the given pagination object with values from the response.
        map(function (response) { return request.update(response); }));
    };
    VendorService.prototype.getAll = function () {
        return this.httpClient
            .get("/api/media-vendor")
            .pipe(map(function (vendors) { return sortBy(vendors, 'name'); }));
    };
    VendorService.prototype.get = function (id) {
        return this.httpClient.get("/api/media-vendor/" + id)
            .pipe(tap(function (data) {
            data.organizationUsers = sortBy(data.organizationUsers, function (user) {
                return fullName(user)
                    .toLowerCase();
            });
        }));
    };
    VendorService.prototype.getPrintAds = function (vendorId) {
        return this.httpClient.get("/api/media-vendor/" + vendorId + "/print-ad");
    };
    VendorService.prototype.updatePrintAds = function (vendorId, data) {
        return this.httpClient
            .put("/api/media-vendor/" + vendorId + "/print-ad", data);
    };
    VendorService.prototype.saveMediaContact = function (vendorId, data) {
        if (data.id) {
            return this.httpClient.put("/api/media-vendor/" + vendorId + "/contact", data);
        }
        else {
            return this.httpClient.post("/api/media-vendor/" + vendorId + "/contact", data);
        }
    };
    VendorService.prototype.toggleContactActive = function (userId) {
        return this.httpClient.put("/api/media-vendor/contact/" + userId + "/active", null);
    };
    VendorService.prototype.getRadioPricing = function (vendorId) {
        return this.httpClient.get("/api/media-vendor/" + vendorId + "/radio-pricing");
    };
    VendorService.prototype.updateRadioPricing = function (vendorId, data) {
        return this.httpClient.put("/api/media-vendor/" + vendorId + "/radio-pricing", data);
    };
    VendorService.prototype.getAllByAdsServiceId = function (serviceId, activeOnly, includeInactiveId) {
        if (activeOnly === void 0) { activeOnly = true; }
        if (includeInactiveId === void 0) { includeInactiveId = null; }
        return this.httpClient
            .get("/api/media-vendor/ads-service/" + serviceId)
            .pipe(map(function (vendors) {
            if (activeOnly) {
                vendors = vendors.filter(function (vendor) { return vendor.active || vendor.id === includeInactiveId; });
            }
            var sortedVendors = sortBy(vendors, function (vendor) {
                return vendor.name.toUpperCase();
            });
            for (var _i = 0, sortedVendors_1 = sortedVendors; _i < sortedVendors_1.length; _i++) {
                var vendor = sortedVendors_1[_i];
                vendor.organizationUsers = sortBy(vendor.organizationUsers, function (user) {
                    return fullName(user)
                        .trim()
                        .toUpperCase();
                });
            }
            return sortedVendors;
        }));
    };
    VendorService.prototype.getAllLiteByAdsServiceId = function (serviceId, activeOnly, preselectedMediaVendor) {
        if (activeOnly === void 0) { activeOnly = true; }
        return this.httpClient
            .get("/api/media-vendor/lite/ads-service/" + serviceId + "?isActive=" + activeOnly)
            .pipe(
        // push the preselected vendor that is no longer active.
        tap(function (vendors) {
            if (!vendors || !vendors.length || !preselectedMediaVendor) {
                return;
            }
            var vendorExists = vendors.some(function (vendor) { return vendor.id === preselectedMediaVendor.id; });
            if (!vendorExists && activeOnly) {
                vendors.push(preselectedMediaVendor);
            }
        }), map(function (vendors) { return sortBy(vendors, function (vendor) { return vendor.name ? vendor.name.toLowerCase() : ''; }); }));
    };
    VendorService.prototype.addFiles = function (id, fileSystemKeyIds) {
        return this.httpClient.post("/api/media-vendor/" + id + "/files/", fileSystemKeyIds);
    };
    VendorService.prototype.removeFiles = function (id, fileSystemKeyIds) {
        return this.httpClient.post("/api/media-vendor/" + id + "/files/delete", fileSystemKeyIds);
    };
    VendorService.prototype.getAffidavitFile = function (id) {
        return this.httpClient.get("/api/media-vendor/" + id + "/affidavit");
    };
    VendorService.prototype.updateAffidavitFile = function (id, file) {
        return this.httpClient.put("/api/media-vendor/" + id + "/affidavit", file);
    };
    VendorService.prototype.getBilling = function (id) {
        return this.httpClient.get("/api/media-vendor/" + id + "/billing");
    };
    VendorService.prototype.setQuickbooksPreference = function (mediaVendorId, quickBookPreference) {
        return this.httpClient
            .put("/api/media-vendor/" + mediaVendorId + "/qbid/sync-quickbooks-preference", quickBookPreference);
    };
    VendorService.prototype.updateBillingType = function (mediaVendorId, billingType) {
        return this.httpClient
            .put("/api/media-vendor/" + mediaVendorId + "/billing-type/" + billingType, null);
    };
    VendorService.prototype.getBillingType = function (mediaVendorId) {
        return this.httpClient
            .get("/api/media-vendor/" + mediaVendorId + "/billing-type");
    };
    VendorService.prototype.updateBillingAddress = function (id, billing) {
        return this.httpClient.put("/api/media-vendor/" + id + "/billing", billing);
    };
    VendorService.prototype.getNewspaperServices = function (mediaVendorId) {
        return this.httpClient.get("/api/media-vendor/" + mediaVendorId + "/newspaper-services");
    };
    VendorService.prototype.updateNewspaperServices = function (mediaVendorId, data) {
        return this.httpClient.put("/api/media-vendor/" + mediaVendorId + "/newspaper-services", data);
    };
    VendorService.prototype.updateQuickBooksId = function (mediaVendorId, quickBooksId) {
        return this.httpClient.put("/api/media-vendor/" + mediaVendorId + "/qbid/" + quickBooksId, null);
    };
    VendorService.prototype.isExisting = function (name) {
        return this.httpClient.get("api/media-vendor/name/" + name);
    };
    VendorService.prototype.updatePricingPreference = function (mediaVendorId, data) {
        return this.httpClient.put("/api/media-vendor/" + mediaVendorId + "/pricing-preference", data);
    };
    VendorService.prototype.getPricingPreference = function (mediaVendorId) {
        return this.httpClient.get("/api/media-vendor/" + mediaVendorId + "/pricing-preference");
    };
    VendorService.prototype.updateVisibilityToClient = function (mediaVendorId, visibility) {
        return this.httpClient
            .put("/api/media-vendor/" + mediaVendorId + "/visible-to-clients/" + visibility, null);
    };
    VendorService.prototype.updateCreditCardStoragePreference = function (mediaVendorId, ccInfoStored) {
        return this.httpClient
            .put("/api/media-vendor/" + mediaVendorId + "/cc-info-stored/" + ccInfoStored, null);
    };
    VendorService.prototype.getReminderEmail = function (mediaVendorId) {
        return this.httpClient.get("api/media-vendor/" + mediaVendorId + "/reminder-email");
    };
    VendorService.prototype.updateReminderEmail = function (mediaVendorId, reminderEmail) {
        return this.httpClient.put("api/media-vendor/" + mediaVendorId + "/reminder-email", reminderEmail);
    };
    VendorService.prototype.updateRequireReferenceNumber = function (mediaVendorId, require) {
        return this.httpClient
            .put("api/media-vendor/" + mediaVendorId + "/require-vendor-reference-number/" + require, null);
    };
    VendorService.ngInjectableDef = i0.defineInjectable({ factory: function VendorService_Factory() { return new VendorService(i0.inject(i1.HttpClient)); }, token: VendorService, providedIn: "root" });
    return VendorService;
}());
export { VendorService };
