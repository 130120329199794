import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { of } from 'rxjs';
import { AuthService } from '../services/auth.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/auth.service";
import * as i2 from "@angular/router";
var InstanceGuard = /** @class */ (function () {
    function InstanceGuard(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    InstanceGuard.prototype.canActivate = function (next, state) {
        // An important thing to note about the instance selector is that the user has
        // to have both the permissions for the instances. Otherwise, we will redirect them accordingly.
        // return this.authService
        //   .hasValidSession()
        //   .pipe(
        //     tap((session) => {
        //       if(!session) {
        //         this.router.navigate(['login']);
        //       }
        //
        //       // If we have session - check if we
        //       // this.authService.currentUser.authorities
        //     })
        //   );
        // NOTE: This is a temporary placeholder until we are able to figure out where to get the permissions from.
        return of(true);
    };
    InstanceGuard.ngInjectableDef = i0.defineInjectable({ factory: function InstanceGuard_Factory() { return new InstanceGuard(i0.inject(i1.AuthService), i0.inject(i2.Router)); }, token: InstanceGuard, providedIn: "root" });
    return InstanceGuard;
}());
export { InstanceGuard };
