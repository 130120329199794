import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './modal.component';
import { ModalModule as BsModal } from 'ngx-bootstrap/modal';
import { ModalBodyComponent, ModalFooterComponent, ModalHeaderComponent } from './modal-components';

@NgModule({
  declarations: [
    ModalComponent,
    ModalHeaderComponent,
    ModalBodyComponent,
    ModalFooterComponent,
  ],
  exports: [
    ModalComponent,
    ModalHeaderComponent,
    ModalBodyComponent,
    ModalFooterComponent,
  ],
  imports: [
    CommonModule,
    BsModal,
  ]
})
export class ModalModule { }
