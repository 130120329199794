import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, zip } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { WorkflowAdsService } from '../services/data/workflow-ads.service';

@Injectable({
  providedIn: 'root'
})
export class StartupEssentialsAdsGuard implements CanActivate {

  constructor(private workflowService: WorkflowAdsService) {}

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return zip(
      this.workflowService.init(),
      // Add other stuff here, e.g load system configs, stuff stuff.
      // this.workflowService.init()
    )
      .pipe(mapTo(true));
  }

}
