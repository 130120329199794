<div class="main-box" *ngIf="mode === modes.Login">
  <form class="main-form animated fadeIn mt-5" #form="ngForm">
    <div class="text-center mt-4">
      <img src="assets/park-evaluations-logo.svg" align="Park Evaluation Logo" width="200">
    </div>
    <h1 class="text-center mt-5 title font-weight-normal">Sign In to Park Evaluations</h1>
    <!-- Inputs start -->
    <div class="form-group mt-5">
      <label>Email Address</label>
      <input
        type="email"
        class="form-control"
        name="email" [(ngModel)]="username"
        appValidEmailValidator
        required>
    </div>
    <div class="form-group">
      <label>Password</label>
      <input
        type="password"
        class="form-control"
        name="password"
        [(ngModel)]="password"
        required>
    </div>
    <div class="alert alert-danger" *ngIf="invalidLogin">{{invalidLogin}}</div>
    <!-- Action Buttons -->
    <div class="text-center">
      <button type="submit"
              [disabled]="form.invalid"
              class="btn btn-primary btn-block btn-lg"
              (click)="login(form); $event.preventDefault()">
        Login
      </button>
      <button type="button" class="btn btn-link" routerLink="../forgot-password">Forgot Password</button>
    </div>
  </form>
</div>

<div class="main-box" *ngIf="mode === modes.PasswordReset">
  <app-password-reset [code]="oobCode"></app-password-reset>
</div>

<div class="main-box" *ngIf="mode === modes.Signup">
  <app-signup [user]="user" [token]="token"></app-signup>
</div>

<div class="py-5"></div>
