<app-modal styleClass="unsaved-changes-modal" (hidden)="submitAction(false)" size="lg">
  <app-modal-body>
    <div class="d-flex warning-note-container">
      <div class="d-flex align-items-center icon-alert-container">
        <i class="fa fa-exclamation-triangle fa-2x icon-style"></i>
      </div>
      <div class="w-100 notification">
        <div class="warning-note-header">
          Do you want to leave this page?
        </div>
        <div class="warning-note-value">
          Leaving this page will result in entered data loss.
        </div>
      </div>
    </div>
  </app-modal-body>
  <app-modal-footer>
    <button class="btn btn-primary btn-lg mr-2" (click)="submitAction(false);">
      Stay on this Page
    </button>
    <button class="btn btn-outline-primary btn-lg" (click)="submitAction(true);">
      Leave this Page
    </button>
  </app-modal-footer>
</app-modal>
