import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MessageCategory } from '../../api/messaging/message-category';
import { MessageScope } from '../../api/messaging/message-scope';
import { switchMap } from 'rxjs/operators';
import { sortBy } from '../../utils/sort-by';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var MessagingService = /** @class */ (function () {
    function MessagingService(httpClient) {
        this.httpClient = httpClient;
    }
    MessagingService.convertAdsQuoteErrorToNoteMessage = function (errors, caseId) {
        return errors.map(function (error) {
            return {
                creator: {
                    id: error.createdBy.id,
                    firstName: error.createdBy.firstName,
                    lastName: error.createdBy.lastName,
                },
                creationDate: error.creationDate,
                lastModified: error.lastModified,
                scopeId: caseId,
                id: error.id,
                category: MessageCategory.QuoteError,
                messageSubject: 'QUOTE ERROR NOTE',
                messageBody: error.description,
            };
        });
    };
    MessagingService.prototype.getServiceNotes = function (caseId, serviceId) {
        return this.httpClient
            .get("/api/messaging/note/case/" + caseId + "/service/" + serviceId);
    };
    MessagingService.prototype.getAdsServiceNotes = function (serviceId) {
        return this.httpClient
            .get("/api/messaging/note/ads-case-service/" + serviceId);
    };
    MessagingService.prototype.getDisplayNotes = function (serviceId) {
        return this.httpClient
            .get("api/messaging/note/service/" + serviceId + "/display");
    };
    MessagingService.prototype.getNotesByCategories = function (caseId, taskServiceId, categories) {
        var url = "/api/messaging/note/case/" + caseId + "/service/" + taskServiceId + "/categories?categories=" + categories;
        return this.httpClient
            .get(url);
    };
    MessagingService.prototype.addServiceNote = function (note) {
        return this.httpClient
            .post("/api/messaging/note", note);
    };
    MessagingService.prototype.updateNote = function (note) {
        return this.httpClient
            .put("/api/messaging/note", note);
    };
    MessagingService.prototype.deleteNote = function (note) {
        return this.httpClient
            .delete("/api/messaging/note/" + note.id);
    };
    MessagingService.prototype.getFilesNotes = function (fileIds) {
        return this.httpClient
            .get("/api/messaging/files/?fileIds=" + fileIds);
    };
    MessagingService.prototype.toggleNoteResolved = function (note) {
        return this.httpClient
            .put("/api/messaging/note/" + note.id + "/toggle-resolved", null);
    };
    MessagingService.prototype.getAdsNotes = function (caseId) {
        return this.httpClient
            .get("/api/messaging/note/ads-case/" + caseId);
    };
    MessagingService.prototype.getCompanyNote = function (companyId, category) {
        if (category === void 0) { category = MessageCategory.AdsCustomerNote; }
        return this.httpClient
            .get("/api/messaging/note/category/" + category + "/scope/COMPANY/id/" + companyId);
    };
    MessagingService.prototype.getCompanyContactNote = function (userId, category) {
        if (category === void 0) { category = MessageCategory.AdsCustomerContactNote; }
        return this.httpClient
            .get("/api/messaging/note/category/" + category + "/scope/COMPANY_CONTACT/id/" + userId);
    };
    MessagingService.prototype.getCaseCancellationNote = function (caseId) {
        return this.httpClient
            .get("/api/messaging/note/category/" + MessageCategory.Cancel + "/scope/" + MessageScope.Case + "/id/" + caseId)
            .pipe(switchMap(function (note) {
            // The endpoint return an array value
            // We will only return the first index since we can only cancel the case ONCE
            return of(note[0]);
        }));
    };
    MessagingService.prototype.getAdsCaseCancellationNote = function (caseId) {
        return this.httpClient
            .get("/api/messaging/note/category/" + MessageCategory.Cancel + "/scope/" + MessageScope.AdsCase + "/id/" + caseId)
            .pipe(switchMap(function (note) {
            // The endpoint return an array value
            // We will only return the first index since we can only cancel the case ONCE
            return of(note[0]);
        }));
    };
    MessagingService.prototype.getLatestCaseHoldNote = function (caseId) {
        return this.httpClient
            .get("/api/messaging/note/category/" + MessageCategory.HOLD + "/scope/" + MessageScope.Case + "/id/" + caseId)
            .pipe(switchMap(function (notes) {
            return of(sortBy(notes, 'lastModified')
                .pop());
        }));
    };
    MessagingService.prototype.getLatestAdsCaseHoldNote = function (caseId) {
        return this.httpClient
            .get("/api/messaging/note/category/" + MessageCategory.HOLD + "/scope/" + MessageScope.AdsCase + "/id/" + caseId)
            .pipe(switchMap(function (notes) {
            return of(sortBy(notes, 'lastModified')
                .pop());
        }));
    };
    MessagingService.prototype.getLatestServiceHoldNote = function (caseId, serviceId) {
        return this.getNotesByCategories(caseId, serviceId, ['HOLD'])
            .pipe(switchMap(function (notes) {
            return of(sortBy(notes, 'lastModified')
                .pop());
        }));
    };
    MessagingService.ngInjectableDef = i0.defineInjectable({ factory: function MessagingService_Factory() { return new MessagingService(i0.inject(i1.HttpClient)); }, token: MessagingService, providedIn: "root" });
    return MessagingService;
}());
export { MessagingService };
