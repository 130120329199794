import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnsavedChangesModalComponent } from './unsaved-changes-modal.component';
import { ModalModule } from '../../../controls/modal/modal.module';

@NgModule({
  declarations: [UnsavedChangesModalComponent],
  exports: [UnsavedChangesModalComponent],
  imports: [
    CommonModule,
    ModalModule
  ]
})
export class UnsavedChangesModalModule { }
