import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

// tslint:disable:no-console

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  constructor() { }

  public log(data: any): void {
    if (environment.production) {
      return;
    }

    console.log(data);
  }
}
