import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TermsOverlayComponent } from './terms-overlay.component';
import { TermsOverlayService } from './terms-overlay.service';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { TermsOfUseContentModule } from '../shared/terms-of-use-content/terms-of-use-content.module';

@NgModule({
  declarations: [TermsOverlayComponent],
  exports: [
    TermsOverlayComponent
  ],
  imports: [
    CommonModule,
    CheckboxModule,
    FormsModule,
    TermsOfUseContentModule,
  ],
  providers: [TermsOverlayService]
})
export class TermsOverlayModule { }
