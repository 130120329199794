import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var HttpErrorInterceptorService = /** @class */ (function () {
    function HttpErrorInterceptorService(router) {
        this.router = router;
    }
    HttpErrorInterceptorService.prototype.intercept = function (request, next) {
        // NOTE: This was deprecated but keeping this
        // Unfortunately, it's not wise to do it here as you won't be able to
        // to catch it at the consumer level if you want custom error handling for certain instances
        return next.handle(request);
        // .pipe(
        //   catchError((error) => {
        //     if (error.status === 502 || error.status === 503) {
        //       this.router.navigate(['error-503-maintenance'], { skipLocationChange: true });
        //     } else if (error.status === 403) {
        //       this.router.navigate(['error-403'], { skipLocationChange: true });
        //     }
        //
        //     // For testing only so you don't need to simulate.
        //     // Call an api that is not existing.
        //     // if (error.status === 404) {
        //     //   this.router.navigate(['error-503-maintenance'], { skipLocationChange: true });
        //     // }
        //
        //     return observableThrowError(error);
        //   }),
        // );
    };
    HttpErrorInterceptorService.ngInjectableDef = i0.defineInjectable({ factory: function HttpErrorInterceptorService_Factory() { return new HttpErrorInterceptorService(i0.inject(i1.Router)); }, token: HttpErrorInterceptorService, providedIn: "root" });
    return HttpErrorInterceptorService;
}());
export { HttpErrorInterceptorService };
