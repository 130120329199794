<div class="offline-screen" *ngIf="currentStatus !== statusCodes.Online">
  <div class="d-flex align-items-center vh-100">
    <div class="m-auto offline-dialog d-flex flex-column">
            <div class="p-5 mb-4 text-center" *ngIf="currentStatus === statusCodes.Offline">
                <i class="fas fa-exclamation-triangle dialog-icon text-warning mt-3"></i>
                <h1 class="mt-3">System currently offline.</h1>
                <h5 class="font-weight-normal">You might be offline or the system is currently undergoing maintenance.</h5>
                <p class="font-weight-light">To preserve the integrity of your data, system access will be disabled.</p>
            </div>
        </div>
    </div>
</div>
