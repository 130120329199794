import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { UserType } from '../api/user/user-type';
import * as i0 from "@angular/core";
import * as i1 from "../services/auth.service";
import * as i2 from "@angular/router";
var InternalCasesGuard = /** @class */ (function () {
    function InternalCasesGuard(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    InternalCasesGuard.prototype.canActivate = function (route, state) {
        if (this.authService.currentUser.userType === UserType.Expert) {
            this.router.navigate(['main', 'expert-services'], { replaceUrl: false });
            return false;
        }
        return true;
    };
    InternalCasesGuard.ngInjectableDef = i0.defineInjectable({ factory: function InternalCasesGuard_Factory() { return new InternalCasesGuard(i0.inject(i1.AuthService), i0.inject(i2.Router)); }, token: InternalCasesGuard, providedIn: "root" });
    return InternalCasesGuard;
}());
export { InternalCasesGuard };
