import { HttpClient } from '@angular/common/http';
import { Action, RequestAction } from '../../api/workflow-action';
import { WorkflowService } from './workflow.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./workflow.service";
var TaskService = /** @class */ (function () {
    function TaskService(httpClient, workflowService) {
        this.httpClient = httpClient;
        this.workflowService = workflowService;
    }
    TaskService.prototype.getByCase = function (caseId) {
        return this.httpClient.get("/api/tasks/case/" + caseId);
    };
    TaskService.prototype.updateTasks = function (requests) {
        return this.httpClient
            .post("/api/tasks/update", requests);
    };
    TaskService.prototype.startTask = function (taskId, taskWorkflows) {
        var availableActions = this.workflowService.getByIds(taskWorkflows);
        // Start task
        var startWorkflow = availableActions.find(function (workflow) { return workflow.action === Action.StartAssigned; });
        var startRequest = startWorkflow.workflowActionRequests
            .find(function (request) { return request.requestAction === RequestAction.Start; });
        startRequest.requestResponse = true;
        var updateRequest = { taskId: taskId, workflowAction: startWorkflow };
        return this.updateTasks([updateRequest]);
    };
    TaskService.prototype.getTaskDeliveryNotification = function (caseId) {
        return this.httpClient.get("/api/tasks/case/" + caseId + "/delivery-notification");
    };
    TaskService.ngInjectableDef = i0.defineInjectable({ factory: function TaskService_Factory() { return new TaskService(i0.inject(i1.HttpClient), i0.inject(i2.WorkflowService)); }, token: TaskService, providedIn: "root" });
    return TaskService;
}());
export { TaskService };
