import { of, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { skipWhile, switchMap } from 'rxjs/operators';
import { isNullOrUndefinedOrBlank } from '../utils/is-null-or-undefined';
import { find } from '../utils/find';
import { UserPermissionType } from '../api/organization/user-permissions';
import * as i0 from "@angular/core";
import * as i1 from "../services/auth.service";
var ParkAdsOnlyGuard = /** @class */ (function () {
    function ParkAdsOnlyGuard(authService) {
        this.authService = authService;
    }
    ParkAdsOnlyGuard.prototype.canActivate = function () {
        return this.authService
            .status
            .pipe(skipWhile(function (user) { return isNullOrUndefinedOrBlank(user); }), switchMap(function (user) {
            if (find(user.permissions, { permission: UserPermissionType.ParkAds })) {
                return of(true);
            }
            return throwError('You do not have permissions to access this module (ParkAds)');
        }));
    };
    ParkAdsOnlyGuard.ngInjectableDef = i0.defineInjectable({ factory: function ParkAdsOnlyGuard_Factory() { return new ParkAdsOnlyGuard(i0.inject(i1.AuthService)); }, token: ParkAdsOnlyGuard, providedIn: "root" });
    return ParkAdsOnlyGuard;
}());
export { ParkAdsOnlyGuard };
