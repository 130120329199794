<div class="text-center mt-3">
  <h1 class="welcome-heading">Welcome to Park Evaluations</h1>
  <p>Please update your password to complete your registration.</p>
</div>

<div class="alert alert-warning text-center" *ngIf="verifying">
  Verifying link...
</div>

<div class="alert alert-danger text-center" *ngIf="expired === true">
  Your link is expired or invalid.
</div>

<form class="form" #form="ngForm" [hidden]="(verifying || !verifying && expired)">
  <div class="form-group">
    <label>Email</label>
    <input type="text" class="form-control" [disabled]="true" [value]="user" />
  </div>
  <div class="form-group">
    <label>Password</label>
    <input
      type="password"
      [(ngModel)]="password"
      [appMinLengthValidator]="6"
      name="password"
      class="form-control"
      required />
    <small id="emailHelp" class="form-text text-muted">Must be at least 6 characters</small>
  </div>
  <div class="form-group">
    <label>Repeat your password</label>
    <input
      type="password"
      [(ngModel)]="passwordRepeat"
      name="passwordRepeat"
      class="form-control"
      [appMinLengthValidator]="6"
      [appEqualToValidator]="password"
      required />
  </div>
  <div class="alert alert-danger" *ngIf="error">{{error}}</div>
  <button class="btn btn-primary btn-lg btn-block" [disabled]="form.invalid" (click)="update(form)">Update</button>
  <div class="mt-4"></div>
</form>
