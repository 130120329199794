/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "ngx-bootstrap/modal";
import * as i4 from "ngx-bootstrap/component-loader";
import * as i5 from "./modal.component";
var styles_ModalComponent = [i0.styles];
var RenderType_ModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalComponent, data: {} });
export { RenderType_ModalComponent as RenderType_ModalComponent };
function View_ModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["aria-label", "Close"], ["class", "close pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-times"]], null, null, null, null, null))], null, null); }
function View_ModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title pull-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.showCloseBtn; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.heading; _ck(_v, 2, 0, currVal_0); }); }
function View_ModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "modal-footer"]], null, null, null, null, null)), i1.ɵncd(null, 2)], null, null); }
export function View_ModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { modal: 0 }), (_l()(), i1.ɵeld(1, 16777216, null, null, 12, "div", [["aria-labelledby", "dialog-static-name"], ["bsModal", ""], ["role", "dialog"], ["tabindex", "-1"]], [[8, "className", 0]], [[null, "onHidden"], [null, "click"], [null, "keydown.esc"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.esc" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onEsc($event) !== false);
        ad = (pd_1 && ad);
    } if (("onHidden" === en)) {
        var pd_2 = (_co.onModalHidden() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 212992, [[1, 4], ["staticModal", 4]], 0, i3.ModalDirective, [i1.ElementRef, i1.ViewContainerRef, i1.Renderer2, i4.ComponentLoaderFactory], { config: [0, "config"] }, { onHidden: "onHidden" }), i1.ɵpod(3, { backdrop: 0, show: 1 }), (_l()(), i1.ɵeld(4, 0, null, null, 9, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 8, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "modal-header"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalComponent_1)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "modal-body"]], null, null, null, null, null)), i1.ɵncd(null, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalComponent_3)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 3, 0, "static", _co.shown); _ck(_v, 2, 0, currVal_1); var currVal_3 = !_co.headerContent; _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.footerContent; _ck(_v, 13, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "modal fade ", _co.styleClass, ""); _ck(_v, 1, 0, currVal_0); var currVal_2 = i1.ɵinlineInterpolate(1, "modal-dialog modal-", _co.size, ""); _ck(_v, 4, 0, currVal_2); }); }
export function View_ModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-modal", [], null, null, null, View_ModalComponent_0, RenderType_ModalComponent)), i1.ɵdid(1, 114688, null, 2, i5.ModalComponent, [], null, null), i1.ɵqud(335544320, 1, { headerContent: 0 }), i1.ɵqud(335544320, 2, { footerContent: 0 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalComponentNgFactory = i1.ɵccf("app-modal", i5.ModalComponent, View_ModalComponent_Host_0, { size: "size", heading: "heading", styleClass: "styleClass", showCloseBtn: "showCloseBtn", shown: "shown" }, { hidden: "hidden" }, ["app-modal-header", "app-modal-body", "app-modal-footer"]);
export { ModalComponentNgFactory as ModalComponentNgFactory };
