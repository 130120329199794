import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { FirebaseService } from '../../services/firebase.service';
import { RaygunService } from '../../services/raygun.service';
import { UserService } from '../../services/data/user.service';
import { PasswordResetCode } from '../../api/password-reset-response';
import { isNullOrUndefinedOrBlank } from '../../utils/is-null-or-undefined';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  public email: string;

  public isEmailLinkSent: boolean;

  public errorMessage: string;

  public requestInProgress = false;

  constructor(
    private authService: AuthService,
    private firebase: FirebaseService,
    private raygun: RaygunService,
    private userService: UserService
  ) {
  }

  public ngOnInit(): void {
  }

  public forgot(): void {
    this.requestInProgress = true;
    this.errorMessage = null;

    this.userService
      .resetUserPassword(this.email)
      .toPromise()
      .then(response => {

        if (response.statusCode === PasswordResetCode.OK) {
          this.isEmailLinkSent = true;
          return;
        }

        if (!isNullOrUndefinedOrBlank(response.errMessage)) {
          this.errorMessage = response.errMessage;
          return;
        }

        this.errorMessage = 'Failed to reset password.';
        this.requestInProgress = false;

      })
      .catch((err) => {
        // default error
        this.errorMessage = 'Failed to reset password.';
        this.raygun.send(err, {
          email: this.email,
          message: 'Sent from forgot password - unhandled error'
        });

        this.requestInProgress = false;
      });
  }
}
