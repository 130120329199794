import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import * as FileSaver from 'file-saver';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiQueryOptions, ApiQueryParams } from './data/api-query-params';
import { FileSystemKey } from '../api/file-system-key';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private httpClient: HttpClient) {

  }

  public getFile(fileKey: string, autoDownload: boolean = true): Observable<string> {
    const url = `/api/file/download`;
    const keys = [fileKey];

    return this.httpClient
      .post<any>(url, keys)
      .pipe(
        tap((resp) => {
          if (autoDownload) {
            window.open('/api/public/file/' + resp.response, '_blank');
          }
        }),
        map(response => response.response)
      );
  }

  public getFileById(id: number, autoDownload: boolean = true): Observable<string> {
    const url = `/api/file/${id}/download`;

    return this.httpClient
      .post<any>(url, null)
      .pipe(
        tap((resp) => {
          if (autoDownload) {
            window.open('/api/public/file/' + resp.response, '_blank');
          }
        }),
        map(response => response.response)
      );
  }

  public getFiles(fileKeys: string[], filename: string = null): void {
    const tokenUrl = `/api/file/download`;
    const queryOptions: ApiQueryOptions = {
      queryParams: { theFileName: filename }
    };
    this.httpClient.post<any>(tokenUrl, fileKeys)
      .toPromise()
      .then(resp => {
          let downloadUrl = '/api/public/file/download-zip-file/' + resp.response;
          if (filename) {
            // Append the generated query params
            downloadUrl += new ApiQueryParams(queryOptions).generateQueryParams();
          }
          window.open(downloadUrl, '_self');
        }
      );
  }

  public saveFile(resp: HttpResponse<Blob>): void {
    const contentDispositionHeader: string = resp.headers.get('Content-Disposition');
    const parts: string[] = contentDispositionHeader.split(';');
    const filename = parts[1].split('=')[1];
    FileSaver.saveAs(resp.body, filename);
  }

  public removeFiles(fileKeys: string[]): void {
    this.httpClient.post<any>(`/api/file/delete`, fileKeys)
      .toPromise();
  }

  public updateFilename(file: FileSystemKey, newFilename: string, fileExtension?: string): Promise<FileSystemKey> {
    // if no extension specified, we use the same extension from the file provided
    if (!fileExtension) {
      fileExtension = file
        .fileName
        .split('.')
        .pop();
    }
    return this.httpClient
      .put<FileSystemKey>(`/api/file/${file.fileKey}/update-filename/${newFilename}.${fileExtension}`, null)
      .toPromise();
  }

  public htmlToPdf(html: string, filename: string = 'download.pdf', download: boolean = true)
    : Observable<FileSystemKey> {

    let url = '/api/file/html-string-to-pdf?fileName=';
    if (filename) {
      url += filename;
    } else {
      url += 'downloaded-draft.pdf';
    }

    return this.httpClient
      .post<FileSystemKey>(url, html)
      .pipe(
        tap((file) => {
          this.getFile(file.fileKey)
            .toPromise();
        })
      );
  }

  public getTextAsset(file: string): Observable<string> {
    return this.httpClient
      .get(file, {observe: 'response', responseType: 'text'})
      .pipe(
        map((resp) => resp.body)
      );
  }

}
