import { zip } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { WorkflowAdsService } from '../services/data/workflow-ads.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/data/workflow-ads.service";
var StartupEssentialsAdsGuard = /** @class */ (function () {
    function StartupEssentialsAdsGuard(workflowService) {
        this.workflowService = workflowService;
    }
    StartupEssentialsAdsGuard.prototype.canActivate = function (next, state) {
        return zip(this.workflowService.init())
            .pipe(mapTo(true));
    };
    StartupEssentialsAdsGuard.ngInjectableDef = i0.defineInjectable({ factory: function StartupEssentialsAdsGuard_Factory() { return new StartupEssentialsAdsGuard(i0.inject(i1.WorkflowAdsService)); }, token: StartupEssentialsAdsGuard, providedIn: "root" });
    return StartupEssentialsAdsGuard;
}());
export { StartupEssentialsAdsGuard };
