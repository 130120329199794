import { Injectable } from '@angular/core';
import { TaskUpdateRequest } from '../../api/workflow/task-update-request';
import { Observable } from 'rxjs';
import { Task } from '../../api/case/task';
import { HttpClient } from '@angular/common/http';
import { TaskUpdateResponse } from '../../api/workflow/task-update-response';
import { Action, RequestAction } from '../../api/workflow-action';
import { WorkflowService } from './workflow.service';
import { TaskDeliveryNotification } from '../../api/workflow/task-delivery-notification';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  constructor(private httpClient: HttpClient, private workflowService: WorkflowService) { }

  public getByCase(caseId: number): Observable<Task[]> {
    return this.httpClient.get<Task[]>(`/api/tasks/case/${caseId}`);
  }

  public updateTasks(requests: TaskUpdateRequest[]): Observable<TaskUpdateResponse[]> {
    return this.httpClient
      .post<any>(`/api/tasks/update`, requests);
  }

  public startTask(taskId: number, taskWorkflows: number[]): Observable<any> {
    const availableActions = this.workflowService.getByIds(taskWorkflows);

    // Start task
    const startWorkflow = availableActions.find(workflow => workflow.action === Action.StartAssigned);
    const startRequest = startWorkflow.workflowActionRequests
      .find(request => request.requestAction === RequestAction.Start);
    startRequest.requestResponse = true;

    const updateRequest = {taskId, workflowAction: startWorkflow} as TaskUpdateRequest;
    return this.updateTasks([updateRequest]);
  }

  public getTaskDeliveryNotification(caseId: number): Observable<TaskDeliveryNotification> {
    return this.httpClient.get<TaskDeliveryNotification>(`/api/tasks/case/${caseId}/delivery-notification`);
  }

}
