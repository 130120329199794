import { SelectItem } from 'primeng/api';

export enum ServiceType {
  AcademicEvaluation = 'ACADEMIC_EVALUATION',
  AcademicExpertLetter = 'ACADEMIC_EXPERT_LETTER',
  BeneficiaryQualificationsAcademics = 'BENEFICIARY_QUALIFICATIONS_ACADEMICS',
  BeneficiaryQualificationsExperience = 'BENEFICIARY_QUALIFICATIONS_EXPERIENCE',
  BusinessNecessityLetter = 'BUSINESS_NECESSITY_LETTER',
  CombinedAcademicAndExperienceEvaluation = 'COMBINED_ACADEMIC_AND_EXPERIENCE_EVALUATION',
  CourseByCourseEvaluation = 'COURSE_BY_COURSE_EVALUATION',
  CustomerExpertLetter = 'CUSTOM_EXPERT_LETTER',
  ExperienceExpertLetter = 'EXPERIENCE_EXPERT_LETTER',
  ExtraordinaryAbilityLetter = 'EXTRAORDINARY_ABILITY_LETTER',
  IntegratedReportAcademic = 'INTEGRATED_REPORT_ACADEMIC',
  IntegratedReportAcademicSupplementalExperience = 'INTEGRATED_REPORT_ACADEMIC_SUPPLEMENTAL_EXPERIENCE',
  IntegratedReportExperience = 'INTEGRATED_REPORT_EXPERIENCE',
  LetterOfExpertise = 'LETTER_OF_EXPERTISE',
  ManagerialCapacityLetter = 'MANAGERIAL_CAPACITY_LETTER',
  NationalInterestWaiver = 'NATIONAL_INTEREST_WAIVER',
  PositionComparison = 'POSITION_COMPARISON',
  PositionEvaluation = 'POSITION_EVALUATION',
  PositionEvaluationGenericNoCandidateName = 'POSITION_EVALUATION_GENERIC_NO_CANDIDATE_NAME',
  SocCodeLetter = 'SOC_CODE_LETTER',
  SpecializedKnowledgeLetter = 'SPECIALIZED_KNOWLEDGE_LETTER',
  TranslationOnly = 'TRANSLATION_ONLY',
  Other = 'OTHER',
  Translation = 'TRANSLATION',
  GeneralStatement = 'GENERAL_STATEMENT',

  // Additional Services
  SocCodeAnalysis = 'SOC_CODE_ANALYSIS',
  WageLevelAnalysis = 'WAGE_LEVEL_ANALYSIS',
  NexusAnalysis = 'NEXUS_ANALYSIS',
  PositionInterview = 'POSITION_INTERVIEW',
  CandidateInterview = 'CANDIDATE_INTERVIEW',
  ComboInterview = 'COMBO_INTERVIEW',
  VirtualVisit = 'VIRTUAL_VISIT',
  ComboVirtualVisit = 'COMBO_VIRTUAL_VISIT',
  RecognitionOfExpertise = 'RECOGNITION_OF_EXPERTISE',
  DisparateFieldsFootnote = 'DISPARATE_FIELDS_FOOTNOTE',
  IndustryStandardLanguage = 'INDUSTRY_STANDARD_LANGUAGE',
  DoubleProofreading = 'DOUBLE_PROOFREADING',
  EndClientJobDescriptionComparison = 'END_CLIENT_JOB_DESC_COMP',
  SendHardCopy = 'SEND_HARD_COPY',
  CreditCard = 'CREDIT_CARD',
  Surcharge = 'SURCHARGE',
  EdgeWithScreenshots = 'EDGE_WITH_SCREENSHOTS',
  EdgeWithoutScreenshots = 'EDGE_WITHOUT_SCREENSHOTS',

  // Story 19000:These services have been removed from implementation
  // They are only used to filter out from the list since BE still returns them.
  OtherExpertOpinionLetter = 'OTHER_EXPERT_OPINION_LETTER_TBD',
  ExperienceAndExpertiseComboLetter = 'EXPERIENCE_AND_EXPERTISE_COMBO_LETTER',
}

export const ADDONS_SORT_ORDER = [
  ServiceType.SocCodeAnalysis,
  ServiceType.WageLevelAnalysis,
  ServiceType.NexusAnalysis,
  ServiceType.PositionInterview,
  ServiceType.CandidateInterview,
  ServiceType.ComboInterview,
  ServiceType.VirtualVisit,
  ServiceType.ComboVirtualVisit,
  ServiceType.RecognitionOfExpertise,
  ServiceType.DisparateFieldsFootnote,
  ServiceType.IndustryStandardLanguage,
  ServiceType.DoubleProofreading,
  ServiceType.EndClientJobDescriptionComparison,
  ServiceType.SendHardCopy,
  ServiceType.EdgeWithScreenshots,
  ServiceType.EdgeWithoutScreenshots,
] as ServiceType[];
