<div class="modal fade {{styleClass}}" bsModal #staticModal="bs-modal" [config]="{backdrop: 'static', show: shown}"
     tabindex="-1" role="dialog" aria-labelledby="dialog-static-name" (onHidden)="onModalHidden()">
  <div class="modal-dialog modal-{{size}}">
    <div class="modal-content">
      <div class="modal-header">
        <!-- Header template specified -->
        <ng-content select="app-modal-header"></ng-content>
        <!-- No header template specified -->
        <ng-container *ngIf="!headerContent">
          <h4 class="modal-title pull-left">{{heading}}</h4>
          <button type="button" class="close pull-right" aria-label="Close" *ngIf="showCloseBtn" (click)="hide()">
            <i class="fa fa-times"></i>
          </button>
        </ng-container>
      </div>
      <div class="modal-body">
        <ng-content select="app-modal-body"></ng-content>
      </div>
      <div class="modal-footer" *ngIf="footerContent">
        <ng-content select="app-modal-footer"></ng-content>
      </div>
    </div>
  </div>
</div>
