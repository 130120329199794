import * as _find from 'lodash/find';

/**
 * Usage:
 * https://lodash.com/docs/4.17.15#find
 *
 * @param arr
 * @param args
 */
export function find<T>(arr: T[], args: any): T {
  return _find(arr, args);
}
