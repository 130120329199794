import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UnsavedChangesService } from '../../../services/unsaved-changes.service';
import { Subject, Subscription } from 'rxjs';
import { ModalComponent } from '../../../controls/modal/modal.component';

@Component({
  selector: 'app-unsaved-changes-modal',
  templateUrl: './unsaved-changes-modal.component.html',
  styleUrls: ['./unsaved-changes-modal.component.scss']
})
export class UnsavedChangesModalComponent implements OnInit, OnDestroy {

  @ViewChild(ModalComponent)
  public modal: ModalComponent;

  public modalSubscription: Subscription;

  constructor(private unsavedService: UnsavedChangesService) {
  }

  public ngOnInit(): void {
    this.modalSubscription = this.unsavedService
      .shown
      .subscribe(show => {
        if (show) {
          this.modal.show();
          return;
        }

        this.modal.hide();
      });
  }

  public ngOnDestroy(): void {
    this.modalSubscription.unsubscribe();
  }

  public submitAction(proceed: boolean): void {
    this.unsavedService.modalAction.next(proceed);
    this.modal.hide();
  }

}
