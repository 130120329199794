/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./global-loader.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./global-loader.component";
import * as i4 from "../../services/global-loader.service";
import * as i5 from "@angular/router";
var styles_GlobalLoaderComponent = [i0.styles];
var RenderType_GlobalLoaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GlobalLoaderComponent, data: {} });
export { RenderType_GlobalLoaderComponent as RenderType_GlobalLoaderComponent };
function View_GlobalLoaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "global-action-block"]], null, null, null, null, null))], null, null); }
function View_GlobalLoaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "global-loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-sync fa-spin"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "d-inline-block ml-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Loading"]))], null, null); }
export function View_GlobalLoaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_GlobalLoaderComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GlobalLoaderComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showLoader; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.showLoader; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_GlobalLoaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-global-loader", [], null, null, null, View_GlobalLoaderComponent_0, RenderType_GlobalLoaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.GlobalLoaderComponent, [i4.GlobalLoaderService, i5.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GlobalLoaderComponentNgFactory = i1.ɵccf("app-global-loader", i3.GlobalLoaderComponent, View_GlobalLoaderComponent_Host_0, {}, {}, []);
export { GlobalLoaderComponentNgFactory as GlobalLoaderComponentNgFactory };
