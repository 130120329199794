import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-error-inactive-end-client',
  templateUrl: './error-inactive-end-client.component.html',
  styleUrls: ['./error-inactive-end-client.component.scss']
})
export class ErrorInactiveEndClientComponent implements OnInit {
  public endClient = '';

  public caseNumber = '';

  constructor(private router: Router, private route: ActivatedRoute) { }

  public ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.endClient = params.endClient;
      this.caseNumber = params.caseNumber;
    });
  }

  public goBack(): void {
    this.router.navigate(['/park-ads/company/dashboard']);
  }
}
