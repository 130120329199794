import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TermsOfUseContentComponent } from './terms-of-use-content.component';

@NgModule({
  declarations: [TermsOfUseContentComponent],
  exports: [TermsOfUseContentComponent],
  imports: [
    CommonModule
  ]
})
export class TermsOfUseContentModule { }
