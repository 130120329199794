import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalFooterComponent, ModalHeaderComponent } from './modal-components';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  @ViewChild(ModalDirective)
  public modal: ModalDirective;

  @ContentChild(ModalHeaderComponent)
  public headerContent: ModalHeaderComponent;

  @ContentChild(ModalFooterComponent)
  public footerContent: ModalFooterComponent;

  @Input()
  public size: 'sm' | 'lg' | '' = '';

  @Input()
  public heading: string;

  @Input()
  public styleClass: string;

  @Input()
  public showCloseBtn = true;

  @Input()
  public shown = false;

  @Output()
  public hidden: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  public ngOnInit(): void {
  }

  public show(): void {
    this.modal.show();
  }

  public hide(): void {
    this.modal.hide();
  }

  public onModalHidden(): void {
    this.hidden.emit(true);
  }

}
