import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { tap, switchMap, catchError, delay } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { isNullOrUndefined } from 'util';
import { Router } from '@angular/router';

@Injectable()
export class HttpAuthHeaderInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private router: Router) {}

  public addAuthHeader(request: HttpRequest<any>): HttpRequest<any> {
    const authHeader = this.authService.getAuthorizationHeader();
    if (!isNullOrUndefined(authHeader)) {
      const updatedReq = request.clone({
        headers: request.headers.set('Authorization', this.authService.getAuthorizationHeader())
      }) as HttpRequest<any>;

      // For debugging -
      // console.log(updatedReq);
      return updatedReq;
    }

    // No headers
    return request.clone();
  }

  public logout(): void {
    this.router.navigate(['logout']);
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // Handle request
    request = this.addAuthHeader(request);

    // Handle response
    return next
      .handle(request)
      .pipe(
        catchError(error => {
          // If we are not logged in, do not attempt to refresh tokens.
          if (!this.authService.currentUser) {
            return observableThrowError(error);
          }

          // Other statuses
          if (error.status !== 401) {
            return observableThrowError(error);
          }

          // 401 error means the token is expired.
          return this.authService.refreshToken()
            .pipe(
              catchError((refreshErr) => {
                this.logout();
                return observableThrowError(refreshErr);
              }),
              switchMap(() => {
                const retryRequest = this.addAuthHeader(request);
                // Delay is necessary so we don't request new token too many times on simult requests
                return next
                  .handle(retryRequest)
                  .pipe(delay(1000));
              })
            );
        }), // -- End catch handler
      ); // -- End Pipe
  }
}
