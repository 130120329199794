import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { clone } from '../../utils/clone';
import { includes } from '../../utils/includes';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var WorkflowService = /** @class */ (function () {
    function WorkflowService(httpClient) {
        this.httpClient = httpClient;
        this.workflows = [];
    }
    /**
     * Sets the value of the workflow action request based on the type.
     * @param waf
     * @param requestType
     * @param value
     */
    WorkflowService.setActionRequestValue = function (waf, requestType, value) {
        if (!waf) {
            console.warn('No workflow action');
            return;
        }
        var request = waf.workflowActionRequests
            .find(function (action) { return action.type === requestType; });
        if (request) {
            request.requestResponse = value;
        }
        else {
            console.warn('Request not found:', requestType);
        }
    };
    WorkflowService.prototype.init = function () {
        var _this = this;
        return this.httpClient
            .get('/api/tasks/workflow-actions')
            .pipe(tap(function (response) { return _this.workflows = response; }));
    };
    WorkflowService.prototype.getAll = function () {
        return clone(this.workflows);
    };
    WorkflowService.prototype.get = function (id) {
        return clone(this.workflows.find(function (workflow) { return workflow.id === id; }));
    };
    WorkflowService.prototype.getByIds = function (ids) {
        return clone(this.workflows.filter(function (workflow) { return includes(ids, workflow.id); }));
    };
    WorkflowService.prototype.getByActionType = function (action) {
        var foundWorkflow = this.workflows.find(function (workflow) { return workflow.action === action; });
        if (foundWorkflow) {
            return clone(foundWorkflow);
        }
        return null;
    };
    WorkflowService.prototype.getRequestsForAction = function (ids, action) {
        return action.workflowActionRequests.filter(function (request) {
            return includes(ids, request.id);
        });
    };
    WorkflowService.ngInjectableDef = i0.defineInjectable({ factory: function WorkflowService_Factory() { return new WorkflowService(i0.inject(i1.HttpClient)); }, token: WorkflowService, providedIn: "root" });
    return WorkflowService;
}());
export { WorkflowService };
