export enum MessageCategory {
  ESTIMATE = 'ESTIMATE',
  BILLING = 'BILLING',
  DELIVERY = 'DELIVERY',
  OTHER = 'OTHER',
  VENDOR = 'VENDOR',
  CLIENT = 'CLIENT',
  EMAIL = 'EMAIL',
  HOLD = 'HOLD',
  RESUME = 'RESUME',
  FILE = 'FILE',
  // Park Ads
  Approval = 'APPROVAL',
  Revision = 'REVISION',
  InternalRevision = 'INTERNAL_REVISION',
  Cancel = 'CANCEL',
  NoteToClient = 'NOTE_TO_CLIENT',
  NoteToPark = 'NOTE_TO_PARK',
  Internal = 'INTERNAL_NOTE',
  ClientInternal = 'CLIENT_INTERNAL_NOTE',
  AdsEndClientNote = 'ADS_END_CLIENT_NOTE',
  AdsCustomerNote = 'ADS_CUSTOMER_NOTE',
  EvalCustomerNote = 'EVAL_CUSTOMER_NOTE',
  EvalCustomerContactNote = 'EVAL_CUSTOMER_CONTACT_NOTE',
  AdsCustomerContactNote = 'ADS_CUSTOMER_CONTACT_NOTE',
  TranslationAdded = 'TRANSLATION_ADDED',
  TranslationRemoved = 'TRANSLATION_REMOVED',
  TranslationCompleted = 'TRANSLATION_COMPLETED',
  QuoteError = 'QUOTE_ERROR', // -- FE ONLY used to manually
  ClientServiceNote = 'CLIENT_SERVICE_NOTE',
  SpecialInstruction = 'SPECIAL_INSTRUCTIONS', // -- FE ONLY used manually
}
