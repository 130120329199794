/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./unsaved-changes-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../controls/modal/modal.component.ngfactory";
import * as i3 from "../../../controls/modal/modal.component";
import * as i4 from "../../../controls/modal/modal-components.ngfactory";
import * as i5 from "../../../controls/modal/modal-components";
import * as i6 from "./unsaved-changes-modal.component";
import * as i7 from "../../../services/unsaved-changes.service";
var styles_UnsavedChangesModalComponent = [i0.styles];
var RenderType_UnsavedChangesModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UnsavedChangesModalComponent, data: {} });
export { RenderType_UnsavedChangesModalComponent as RenderType_UnsavedChangesModalComponent };
export function View_UnsavedChangesModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { modal: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 19, "app-modal", [["size", "lg"], ["styleClass", "unsaved-changes-modal"]], null, [[null, "hidden"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("hidden" === en)) {
        var pd_0 = (_co.submitAction(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ModalComponent_0, i2.RenderType_ModalComponent)), i1.ɵdid(2, 114688, [[1, 4]], 2, i3.ModalComponent, [], { size: [0, "size"], styleClass: [1, "styleClass"] }, { hidden: "hidden" }), i1.ɵqud(335544320, 2, { headerContent: 0 }), i1.ɵqud(335544320, 3, { footerContent: 0 }), (_l()(), i1.ɵeld(5, 0, null, 1, 9, "app-modal-body", [], null, null, null, i4.View_ModalBodyComponent_0, i4.RenderType_ModalBodyComponent)), i1.ɵdid(6, 49152, null, 0, i5.ModalBodyComponent, [], null, null), (_l()(), i1.ɵeld(7, 0, null, 0, 7, "div", [["class", "d-flex warning-note-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "d-flex align-items-center icon-alert-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["class", "fa fa-exclamation-triangle fa-2x icon-style"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "w-100 notification"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["class", "warning-note-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Do you want to leave this page? "])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["class", "warning-note-value"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Leaving this page will result in entered data loss. "])), (_l()(), i1.ɵeld(15, 0, null, 2, 5, "app-modal-footer", [], null, null, null, i4.View_ModalFooterComponent_0, i4.RenderType_ModalFooterComponent)), i1.ɵdid(16, 49152, [[3, 4]], 0, i5.ModalFooterComponent, [], null, null), (_l()(), i1.ɵeld(17, 0, null, 0, 1, "button", [["class", "btn btn-primary btn-lg mr-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submitAction(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Stay on this Page "])), (_l()(), i1.ɵeld(19, 0, null, 0, 1, "button", [["class", "btn btn-outline-primary btn-lg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submitAction(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Leave this Page "]))], function (_ck, _v) { var currVal_0 = "lg"; var currVal_1 = "unsaved-changes-modal"; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_UnsavedChangesModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-unsaved-changes-modal", [], null, null, null, View_UnsavedChangesModalComponent_0, RenderType_UnsavedChangesModalComponent)), i1.ɵdid(1, 245760, null, 0, i6.UnsavedChangesModalComponent, [i7.UnsavedChangesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UnsavedChangesModalComponentNgFactory = i1.ɵccf("app-unsaved-changes-modal", i6.UnsavedChangesModalComponent, View_UnsavedChangesModalComponent_Host_0, {}, {}, []);
export { UnsavedChangesModalComponentNgFactory as UnsavedChangesModalComponentNgFactory };
