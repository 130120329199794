import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { touchForm } from '../../utils/touch-form';
import { Router } from '@angular/router';
import { RaygunService } from '../../services/raygun.service';
var SignupComponent = /** @class */ (function () {
    function SignupComponent(authService, router, raygun) {
        this.authService = authService;
        this.router = router;
        this.raygun = raygun;
        this.verifying = true;
        this.expired = null; // expired unless proven
    }
    SignupComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.authService
            .loginToVerify(this.user, this.token)
            .toPromise()
            .then(function () {
            // debugger;
            _this.expired = false;
        })
            .catch(function () {
            // debugger;
            _this.expired = true;
        })
            .finally(function () {
            _this.verifying = false;
        });
    };
    SignupComponent.prototype.update = function (form) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var user, e_1, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.error = null;
                        touchForm(form);
                        if (form.invalid) {
                            return [2 /*return*/];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.authService
                                .login(this.user, this.token)
                                .toPromise()];
                    case 2:
                        user = _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        this.error = 'Password update failed. Your token maybe expired';
                        return [2 /*return*/];
                    case 4:
                        _a.trys.push([4, 6, , 7]);
                        return [4 /*yield*/, user.user.updatePassword(this.password)];
                    case 5:
                        _a.sent();
                        return [3 /*break*/, 7];
                    case 6:
                        e_2 = _a.sent();
                        this.error = 'Password update failed. Please try again.';
                        // If this error-ed out, something must be amiss. Since we have the proper validators.
                        // Send to raygun for investigation.
                        this.raygun.send(e_2, { source: 'Signup password update.' });
                        // Log out the user again if the operation errors out
                        this.authService.logout();
                        return [2 /*return*/];
                    case 7:
                        window.location.reload();
                        return [2 /*return*/];
                }
            });
        });
    };
    return SignupComponent;
}());
export { SignupComponent };
