import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { UserType } from '../../../api/user/user-type';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
  ) { }

  public ngOnInit(): void {
    this.activatedRoute.queryParams
    // .map(param => param.route)
      .subscribe((params) => {
        // Scope Redirects top level check
        if (params.scope && params.scopeId) {
          this.handleRedirectFromScopeAndId(params);
          return;
        }

        // Params.query is a json string. We need to parse it.
        let query = null;
        if (params.query) {
          query = JSON.parse(params.query);
        }

        this.handleRedirect(params.route, query);
      });
  }

  /**
   * Handles the redirection for the specified route
   *
   * @param {string} route
   * @param {any} query JSON object
   *
   */
  public handleRedirect(route: string, query?: any): void {
    this.router.navigate([route], {queryParams: query});
  }

  private handleRedirectFromScopeAndId(params: Params): void {
    const caseId = params.scopeId;
    const user = this.authService.currentUser;

    let pathType = 'internal';
    if (user.userType === UserType.Company) {
      pathType = 'company';
    }

    if (params.scope === 'CASE') {
      const isDraft = params.draft;
      if (isDraft) {
        this.router.navigate(['main', 'create-case', caseId]);
        return;
      }

      this.router.navigate(['main', 'case', caseId]);
      return;
    }

    if (params.scope === 'ADS_CASE') {
      this.router.navigate(['park-ads', pathType, 'case', caseId]);
    }

    if (params.scope === 'ADS_SERVICE') {
      const queryParams: Params = { serviceId: caseId };
      this.router.navigate(['park-ads', pathType, 'case', params.parentScopeId, 'ads'], {queryParams});
    }
  }
}
