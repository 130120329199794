import { SelectItem } from 'primeng/api';

export interface UserPermission {
  id?: number;
  lastModified?: number;
  creationDate?: number;
  name?: string;
  permission?: UserPermissionType;
}

export enum UserPermissionType {
  ParkEval = 'PARK_EVAL_USER',
  ParkAds = 'PARK_ADS_USER',
  QuoteReviewer = 'QUOTE_REVIEWER',
  EditConfig = 'EDIT_CONFIG',
  EditBilling = 'EDIT_BILLING',
  EditPermission = 'EDIT_PERMISSION',
  ChangeEndClient = 'CHANGE_END_CLIENT',

  // Reports Permissions
  RunReports = 'RUN_REPORTS',
  ViewProductivityReport = 'VIEW_PRODUCTIVITY_REPORT',
  ViewQualityReport = 'VIEW_QUALITY_REPORT',
  ViewCompanyCaseReport = 'VIEW_COMPANY_CASE_REPORT',
  ViewCompanyContactListReport = 'VIEW_COMPANY_CONTACT_LIST_REPORT',
  ViewVendorBillReport = 'VIEW_VENDOR_BILL_REPORT',
  ViewCompanyUserReport = 'VIEW_COMPANY_USER_REPORT',
  ViewCaseIntakeDraftReport = 'VIEW_CASE_INTAKE_DRAFT_REPORT',
  ViewInvoiceReport = 'VIEW_EVAL_INVOICE_REPORT',
  ViewEvalVolumeReport = 'VIEW_EVAL_VOLUME_REPORT',
  ViewCanceledCaseBills = 'VIEW_ADS_CANCELED_CASE_BILL_REPORT',

  // Ads Reports Permissions
  ViewAdsCompanyCaseReport = 'VIEW_ADS_COMPANY_CASE_REPORT',
  ViewAdsProductivityReport = 'VIEW_ADS_PRODUCTIVITY_REPORT',
  ViewAdsInvoiceReport = 'VIEW_ADS_INVOICE_REPORT',
  ViewAdsVendorBillReport = 'VIEW_ADS_VENDOR_BILL_REPORT',
  ViewAdsScreenshotReport = 'VIEW_ADS_SCREENSHOT_REPORT',
  ViewAdsVolumeReport = 'VIEW_ADS_VOLUME_REPORT',
  ViewAdsScheduleReport = 'VIEW_ADS_SCHEDULE_REPORT',

  // limited company Permissions
  UsersAndTeams = 'USERS_AND_TEAMS',
  FinancesAndBilling = 'FINANCES_AND_BILLING',
}

export const USER_PERMISSION_TYPES = [
  {label: 'Park Advertising', value: UserPermissionType.ParkAds},
  {label: 'Park Evaluations', value: UserPermissionType.ParkEval},
  {label: 'Users & Teams', value: UserPermissionType.UsersAndTeams},
  {label: 'Finances and Billing', value: UserPermissionType.FinancesAndBilling},
] as SelectItem[];
