/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error-403.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./error-403.component";
var styles_Error403Component = [i0.styles];
var RenderType_Error403Component = i1.ɵcrt({ encapsulation: 0, styles: styles_Error403Component, data: {} });
export { RenderType_Error403Component as RenderType_Error403Component };
export function View_Error403Component_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "text-center pt-3 bg-white"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["src", "../../../assets/park-evaluations-logo.svg"], ["width", "160"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 18, "div", [["class", "d-flex align-items-center justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 17, "div", [["class", "error-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "error-code"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["403"])), (_l()(), i1.ɵeld(7, 0, null, null, 14, "div", [["class", "error-content text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "error-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You don't have access"])), (_l()(), i1.ɵeld(10, 0, null, null, 11, "div", [["class", "error-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["class", "mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["If you think this is a mistake, please contact support."])), (_l()(), i1.ɵeld(13, 0, null, null, 2, "button", [["class", "btn btn-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.reload() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 0, "i", [["class", "fa fa-redo"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" RELOAD"])), (_l()(), i1.ɵeld(16, 0, null, null, 1, "span", [["class", "d-inline-block mx-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["or"])), (_l()(), i1.ɵeld(18, 0, null, null, 3, "button", [["class", "btn btn-link"], ["routerLink", "/logout"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(19, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(20, 0, null, null, 0, "i", [["class", "fa fa-sign-out-alt"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" LOGOUT"]))], function (_ck, _v) { var currVal_0 = "/logout"; _ck(_v, 19, 0, currVal_0); }, null); }
export function View_Error403Component_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-error-403", [], null, null, null, View_Error403Component_0, RenderType_Error403Component)), i1.ɵdid(1, 114688, null, 0, i3.Error403Component, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var Error403ComponentNgFactory = i1.ɵccf("app-error-403", i3.Error403Component, View_Error403Component_Host_0, {}, {}, []);
export { Error403ComponentNgFactory as Error403ComponentNgFactory };
