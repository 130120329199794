/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error-screensize-monitor.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./error-screensize-monitor.component";
var styles_ErrorScreensizeMonitorComponent = [i0.styles];
var RenderType_ErrorScreensizeMonitorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ErrorScreensizeMonitorComponent, data: {} });
export { RenderType_ErrorScreensizeMonitorComponent as RenderType_ErrorScreensizeMonitorComponent };
function View_ErrorScreensizeMonitorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "screensize-monitor-container d-flex vh-100 w-100 justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "screensize-monitor align-self-center text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["src", "assets/park-evaluations-logo.svg"], ["title", "Park Evaluations"], ["width", "160"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h2", [["class", "mt-5"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Oops, screensize not supported!"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You must be on at least a 900px screen."]))], null, null); }
export function View_ErrorScreensizeMonitorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorScreensizeMonitorComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showError; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ErrorScreensizeMonitorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-error-screensize-monitor", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ErrorScreensizeMonitorComponent_0, RenderType_ErrorScreensizeMonitorComponent)), i1.ɵdid(1, 114688, null, 0, i3.ErrorScreensizeMonitorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ErrorScreensizeMonitorComponentNgFactory = i1.ɵccf("app-error-screensize-monitor", i3.ErrorScreensizeMonitorComponent, View_ErrorScreensizeMonitorComponent_Host_0, {}, {}, []);
export { ErrorScreensizeMonitorComponentNgFactory as ErrorScreensizeMonitorComponentNgFactory };
