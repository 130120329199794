import { Component } from '@angular/core';

@Component({
  selector: 'app-modal-header',
  template: '<ng-content></ng-content>'
})
export class ModalHeaderComponent { }

@Component({
  selector: 'app-modal-body',
  template: '<ng-content></ng-content>'
})
export class ModalBodyComponent { }

@Component({
  selector: 'app-modal-footer',
  template: '<ng-content></ng-content>'
})
export class ModalFooterComponent { }
