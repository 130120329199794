import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[appMinLengthValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MinLengthValidatorDirective, multi: true }]
})
export class MinLengthValidatorDirective implements Validator {

  @Input()
  public appMinLengthValidator: number;

  constructor() {
  }

  public validate(control: AbstractControl): { [key: string]: any } {
    if (!control.value) {
      return { minLengthInvalid: `Minimum of ${this.appMinLengthValidator} characters required` };
    }

    const value = control.value
      .toString()
      .trim();

    if (value.length < this.appMinLengthValidator) {
      return { minLengthInvalid: `Minimum of ${this.appMinLengthValidator} characters required`  };
    }

    return null;
  }
}
