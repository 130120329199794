import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../../api/user/user';
import { EmailAvailability } from '../../api/email-availability';
import { NotificationSettings } from 'src/app/api/notification/notification-settings';
import { EmailExclusion } from '../../api/system-config/email-exclusion';
import { PasswordResetResponse } from '../../api/password-reset-response';

@Injectable()
export class UserService {

  constructor(private httpClient: HttpClient) {

  }

  public isEmailAvailable(email: string): Observable<EmailAvailability> {
    return this.httpClient.get<EmailAvailability>('/api/public/validateEmail/' + email);
  }

  public toggleActive(userId: number): Observable<User> {
    return this.httpClient.put<User>(`/api/user/active/${userId}`, null);
  }

  public getIndividualCompanyUsers(): Observable<User[]> {
    return this.httpClient.get<User[]>('/api/user/individual/company');
  }

  public getNotificationSettings(userId: number): Observable<NotificationSettings> {
    return this.httpClient.get<NotificationSettings>(`/api/messaging/preferences/user/${userId}`);
  }

  public updateNotificationSettings(userId: number, settings: NotificationSettings): Observable<NotificationSettings> {
    return this.httpClient.put<NotificationSettings>(`/api/messaging/preferences/user/${userId}`, settings);
  }

  public getAllNonIndividualEmailsExclusions(): Observable<EmailExclusion[]> {
    return this.httpClient.get<EmailExclusion[]>('/api/user/non-individual/emails-exclusions');
  }

  public updatePermission(user: User): Observable<User> {
    return this.httpClient.put<User>(`/api/user/${user.id}/permissions`, user);
  }

  public resetUserPassword(email: string): Observable<PasswordResetResponse> {
    return this.httpClient.get<PasswordResetResponse>(`/api/public/request-password-reset/${email}`);
  }

  public saveSalesforceId(userId: number, salesforceId: string): Observable<User> {
    return this.httpClient.put<User>(`/api/user/${userId}/salesforce/${salesforceId}`, null);
  }
}
