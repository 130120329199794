import { Component, OnInit } from '@angular/core';
import { ErrorHandlerService } from '../error-handler.service';

@Component({
  selector: 'app-generic-error',
  templateUrl: './generic-error.component.html',
  styleUrls: ['./generic-error.component.scss']
})
export class GenericErrorComponent implements OnInit {

  public errorMessage: string;

  public errorLocation: string;

  constructor() { }

  public ngOnInit(): void {
    this.errorMessage = window.localStorage.getItem(ErrorHandlerService.storage);
    this.errorLocation = window.localStorage.getItem(ErrorHandlerService.errorLocation);
  }

  public refresh(): void {
    window.location.reload();
  }

}
