export enum MessageScope {
  Task = 'TASK',
  Service = 'SERVICE',
  Case = 'CASE',
  File = 'FILE',
  AdsCase='ADS_CASE',
  AdsService = 'ADS_SERVICE',
  EndClient = 'END_CLIENT',
  Company = 'COMPANY',
  CompanyContact = 'COMPANY_CONTACT',
}
