<div style="font-size:19px;" class="text-center mb-3">
  <strong>PARK EVALUATIONS &ndash; TERMS OF USE AGREEMENT</strong>
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;">
    This Terms of Use Agreement (&ldquo;Agreement&rdquo;) is a legally binding agreement between you,
  whether personally or on behalf of an entity (&ldquo;user&rdquo; or &ldquo;you&rdquo;) and Park Firm LLC and its affiliated companies
  (collectively, &ldquo;Company&rdquo; or &ldquo;we&rdquo; or &ldquo;us&rdquo; or &ldquo;our&rdquo;),
  and governs your use of the Park Evaluations website (the &ldquo;Website&rdquo;)
  and all related web and mobile services (collectively, the &ldquo;Platform&rdquo;).
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;">
  The Platform provides functionality for foreign credential evaluations,
  expert opinion letters, PERM Advertising, translation and proofreading services, and
  for procuring and organizing other legal and related services (the &ldquo;Platform Services&rdquo;).
  The Platform provides functionality whereby lawyers, law firms and others providing related services
  (&ldquo;Legal Service Providers&rdquo;) may use the Platform to provide legal services (the &ldquo;Legal Services&rdquo;).
  The Platform also provides functionality for ordering evaluation, Expert opinion letters, PERM Advertising, translation
  and proofreading services performed directly by us (&ldquo;E&amp;T Services&rdquo;).
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>
<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;">
  <strong>By using the Platform, Platform Services, Legal Services and/or E&amp;T Services,
    you agree to be bound by the terms of this Agreement. If you do not agree to abide by this Agreement,
    or to modifications that we may make to this Agreement in the future, do not use or access, or continue
    to use or access, the Platform Services.</strong>
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;">
  Our Platform is hosted in the United States of America and is subject to U.S.
  state and federal law.
  <span style='color:black;'>
    The information provided on the Platform is not
    intended for distribution to or use by any person or entity in any jurisdiction
    or country where such distribution or use would be contrary to law or regulation or
    which would subject us to any registration requirement within such jurisdiction or country.
    Accordingly, users access the Platform on their own initiative and are solely responsible for
    compliance with local laws, if and to the extent local laws are applicable.</span>
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;">
  <strong>If you are an individual accepting this Agreement on behalf of an entity,
    then you represent that you are authorized to bind the entity to the terms of this
    Agreement and you agree on behalf of such entity to inform any other representatives of
    such entity who will be accessing the Platform Services of their obligations under this Agreement.</strong>
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;">
  Persons who are minors in the jurisdiction in which they reside (generally under the
  age of 18) may not use the Platform.</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>


<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;">
  <strong style='color:black;'>SOFTWARE LICENSE</strong>
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>


<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;color:black;">
  Subject to other license terms specific for particular applications,
  we grant you a personal, non-exclusive, non-transferable, limited
  license to use the software we provide to you as a part of the Platform
  Services and in accordance with this Agreement.
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;color:black;">
  As part of the Platform Services, you may from time to time receive updates to our software
  which may be automatically downloaded and installed to your device or computer.
  These updates may include bug fixes, feature enhancements or improvements, or entirely
  new versions of the software. You agree that we may automatically deliver such updates to
  you as part of the Platform Services and you shall receive and install them as required.
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;color:black;">
  Some software used in the Platform Services may
   be offered under an open source license that we
   will make available to you. There may be provisions
   in the open source license that expressly override some of these terms.
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;color:black;"><strong>LEGAL SERVICES</strong></div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>


<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;color:black;">
  The Platform provides functionality whereby Legal Service Providers may use the Platform to provide Legal Services,
  accept orders for Legal Services from other users of the Platform, communicate with such users relating to such Legal Services,
  accept payment via the Platform for the performance of such Legal Services, and perform other activities and utilize
  other functionality that may be available via the Platform from time to time. However,
  <strong>
    NEITHER COMPANY NOR THE PLATFORM IS A PROVIDER OF LEGAL
    SERVICES NOR IS EITHER INVOLVED IN THE PRACTICE OF LAW. NOTHING ON THIS PLATFORM SHALL BE
    CONSTRUED AS LEGAL ADVICE FROM US. WE ARE NOT AUTHORIZED TO PROVIDE LEGAL ADVICE.
  </strong>
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;color:black;">
  We interview prospective Legal Service Providers for potential
  compatibility with our Platform. But we do not perform background
  checks on Legal Service Providers or verify the accuracy of information contained in a
  Legal Service Provider&rsquo;s profile that appears on the Platform. We don&rsquo;t monitor
  the performance of Legal Service Providers on the Platform. We are not liable for the credentials,
  experience, qualifications, performance or results (or lack thereof) of Legal Service Providers.
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;color:black;">
  We do not receive payments for Legal Services,
  but as a provider of software services via the Platform and payment processor.
  Any disputes applicable to fees for Legal Services shall be resolved solely
  between the Legal Service Providers and other applicable users,
  and we shall have no responsibility in resolving any such disputes.
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><strong>PRIVACY</strong></div>

<div
  style="margin-top:.1in;margin-right:0in;margin-bottom:.2in;margin-left:0in;text-align:justify;line-height:normal;">
  We care about the privacy of our users. Please review our Privacy Policy located at
  <a href="http://www.parkeval.com/privacy">www.parkeval.com/privacy</a>
  By using the Platform or Platform Services, you are consenting to have your personal
  data transferred to and processed in the United States.&nbsp;
  By using the Platform or the Platform Services, you are consenting to the terms of our Privacy Policy.
</div>

<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;">
  <strong>ELECTRONIC CONTRACTING</strong>
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;">
  Your use of the Platform may include the ability to enter into agreements and/or to make
  transactions electronically. You acknowledge that your electronic submissions constitute your
  agreement and intent to be bound by and to pay for such agreements and transactions.
  Your agreement and intent to be bound by electronic submissions applies to all records relating to
  all transactions you enter into relating to the Platform Services, including notices of cancellation, policies, contracts,
  and applications. In order to access and retain your electronic records,
  you may be required to have certain hardware and software, which are your sole responsibility.
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>


<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><strong>USER REPRESENTATIONS</strong></div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>


<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;">
  <strong><em>Regarding Your Registration</em></strong>
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;">
  By using the Platform Services, you represent and warrant that:
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<ol style="list-style-type: lower-alpha;">
	<li>all registration information you submit is truthful and accurate;</li>
	<li>you will maintain the accuracy of such information;</li>
	<li>you will keep your password confidential and will be responsible for all use of your password and account;</li>
	<li>you are not a minor in the jurisdiction in which you reside; and</li>
	<li>your use of the Platform Services does not violate any applicable law or regulation.</li>
</ol>

<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;color:black;">
  You also agree to:
  (i) provide true, accurate, current and complete information about
  yourself as prompted by the Platform&rsquo;s registration form and
  (ii) maintain and  promptly update registration data to keep it true, accurate, current and complete.
  If you provide any information that is untrue, inaccurate, not current, or incomplete, or&nbsp;we have
  reasonable grounds to suspect that such information is untrue, inaccurate, not current, or incomplete,&nbsp;
  we have the right to suspend or terminate your account and refuse any and all current or future use of the Platform (or any portion thereof).
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>


<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;color:black;">
  You are responsible for the violation of this Agreement by any employee or agent of yours,
  any other person to whom you have given access to the Platform Services, and any person
  who gains access to your account as a result of your failure to use reasonable security precautions,
  to the same extent as if you had committed the violation yourself, even if such violation was not authorized by you.
  You are responsible to us for any fees arising from the use of the Platform Services by any of these persons,
  even if that use was not authorized by you.
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><strong><em>Regarding Content You Provide</em></strong></div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;color:black;">
  The Platform may provide you with the opportunity to create, submit, post, display,
  transmit, perform, publish, distribute or broadcast content and materials via the Platform, including,
  without limitation, text, writings, video, audio, photographs, graphics, comments, suggestions or personally
  identifiable information or other material (collectively &quot;Contributions&quot;).&nbsp;When you create or
   make available a Contribution, you thereby represent and warrant that:
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<ol class="text-justify" style="list-style-type: lower-alpha;">
	<li>the creation, distribution, transmission, public display and performance, accessing, downloading and copying of your Contribution does not and will not infringe the proprietary rights, including but not limited to the copyright, patent, trademark, trade secret or moral rights of any third party;</li>
	<li>you are the creator and owner of or have the necessary licenses, rights, consents, releases and permissions to use and to authorize Company and the Platform users to use your Contributions as necessary to exercise the licenses granted by you under this Agreement;</li>
	<li>you have the written consent, release, and/or permission of each and every identifiable individual person in the Contribution to use the name or likeness of each and every such identifiable individual person to enable inclusion and use of the Contribution in the manner contemplated by this Platform;</li>
	<li>your Contribution is not obscene, lewd, lascivious, filthy, violent, harassing or otherwise objectionable (as determined by us), libelous or slanderous, does not ridicule, mock, disparage, intimidate or abuse anyone, does not advocate the violent overthrow of any government, does not incite, encourage or threaten physical harm against another, does not violate any applicable law, regulation, or rule, and does not violate the privacy or publicity rights of any third party;</li>
	<li>your Contribution does not include any offensive comments that are connected to race, national origin, gender, sexual preference or physical handicap; and</li>
	<li>your Contribution does not otherwise violate, or link to material that violates, any provision of this Agreement or any applicable law or regulation.</li>
</ol>

<div  style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><strong>PROHIBITED ACTIVITIES</strong></div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;">
  You may not access or use the Platform for any purpose other
  than that for which we make it available. The Platform may not be used in
  connection with any commercial endeavors except those that are specifically endorsed or approved by us.
  Prohibited activity includes, but is not limited to:
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<ol class="text-justify" style="list-style-type: lower-alpha;">
	<li>criminal or tortious activity;</li>
	<li>systematic retrieval of data or other content from the Platform to create or compile, directly or indirectly, a collection, compilation, database or directory without written permission from us;</li>
	<li>making any unauthorized use of the Platform Services, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false pretenses;</li>
	<li>tricking, defrauding or misleading us and other users, especially in any attempt to learn sensitive account information such as passwords;</li>
	<li>engaging in any automated use of the Platform, such as using any data mining, robots or similar data gathering and extraction tools;</li>
	<li>interfering with, disrupting, or creating an undue burden on the Platform or the networks or services connected to the Platform;</li>
	<li>attempting to impersonate another user or person or using the username of another user;</li>
	<li>selling or otherwise transferring your profile;</li>
	<li>using any information obtained from the Platform in order to harass, abuse, or harm another person;</li>
	<li>using the Platform Services as part of any effort by you or a third party to directly or indirectly compete with Company or to provide services as a service bureau;</li>
	<li>creating a derivative work of, deciphering, decompiling, disassembling or reverse engineering any of the software comprising or in any way making up a part of the Platform or otherwise attempting to discover the source code, selling, leasing, sublicensing, assigning, granting a security interest in or otherwise transferring any right in such software;</li>
	<li>attempting to bypass any measures of the Platform designed to prevent or restrict access to the Platform, or any portion of the Platform;</li>
	<li>harassing, annoying, intimidating or threatening any Company employees or agents engaged in providing any portion of the Platform Services to you;</li>
	<li>deleting the copyright or other proprietary rights notice from any Platform content;</li>
	<li>except as may be the result of standard search engine or Internet browser usage, using or launching, developing or distributing any automated system, including, without limitation, any spider, robot (or &quot;bot&quot;), cheat utility, scraper or offline reader that accesses the Platform, or using or launching any unauthorized script or other software; or</li>
	<li>using the Platform in a manner inconsistent with any and all applicable laws and regulations.</li>
</ol>


<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div style="margin-bottom:.0001pt;line-height:normal;color:black"><strong>GUIDELINES FOR REVIEWS</strong></div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>
<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;color:black;">
  We may accept, reject or remove reviews in our sole discretion.
  We have absolutely no obligation to screen reviews or to delete reviews,
  even if anyone considers reviews objectionable or inaccurate.
  Those persons posting reviews should comply with the following criteria:
  (1) reviewers should have firsthand experience with the person/entity being reviewed;
  (2) reviews should not contain: offensive language, profanity, or abusive, racist, or hate language;
      discriminatory references based on religion, race, gender, national origin, age, marital status, sexual orientation or disability;
      or references to illegal activity;
  (3) reviewers should not be affiliated with competitors if posting negative reviews;
  (4) reviewers should not make any conclusions as to the legality of conduct; and
  (5) reviewers may not post any false statements or organize a campaign encouraging others to post reviews,
      whether positive or negative. Reviews are not endorsed by us, and do not represent the views of Company or of any affiliate or
      partner of Company. We do not assume liability for any review or for any claims, liabilities or losses resulting from any review.
      By posting a review, the reviewer hereby grants to us a perpetual, non-exclusive, worldwide, royalty-free, fully-paid,
      assignable and sublicensable license to Company to reproduce, modify, translate, transmit by any means, display, perform
      and/or distribute all content relating to reviews.
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>


<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;color:black;"><strong>SUBMISSIONS</strong></div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;">
  Any questions, comments, suggestions, ideas, feedback or other information about the Platform or
  the Platform Services provided by you (&quot;Submissions&quot;) are non-confidential and Company
  (as well as any designee of Company) shall be entitled to the unrestricted use and dissemination of these Submissions for any purpose,
  commercial or otherwise, without acknowledgment or compensation to you.
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><strong>INTELLECTUAL PROPERTY RIGHTS</strong></div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div  style="margin-bottom:.0001pt;text-align:justify;line-height:normal;">
  The content on the Platform (&ldquo;Company Content&rdquo;) and the trademarks,
  service marks and logos contained therein (&ldquo;Marks&rdquo;) are owned by or licensed to us,
  and are subject to copyright and other intellectual property rights under United States and foreign
  laws and international conventions. Company Content includes, without limitation, all source code,
  databases, functionality, software, Platform designs, audio, video, text, photographs and graphics.
  All Company graphics, logos, designs, page headers, button icons, scripts and service names are registered trademarks,
  common law trademarks or trade dress of Company in the U.S. and/or other countries. Our trademarks and trade dress may not be used,
  including as part of trademarks and/or as part of domain names, in connection with any product or service in any manner that is
  likely to cause confusion and may not be copied, imitated, or used, in whole or in part, without our prior written consent.
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;">&nbsp;</p>


<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;color:black;"><strong>THIRD PARTY PLATFORMS AND CONTENT</strong></div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>


<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;">
  The Platform may contain (or you may be sent through the Platform or the Platform Services to) links to other platforms
  and websites such as the USPTO, WIPO, law firms, or Legal Service Providers (&quot;Third Party Websites&quot;)
  as well as articles, photographs, text, graphics, pictures, designs, music, sound, video, information, applications,
  software and other content or items belonging to or originating from third parties (the &quot;Third Party Content&quot;).
  We do not investigate, monitor or check for accuracy, appropriateness, or completeness Third Party Websites and Third Party Content,
  and we are not responsible for any Third Party Websites accessed through the Platform or any Third Party Content posted on, available
  through or installed from the Platform, including the content, accuracy, offensiveness, opinions, reliability, privacy practices or
  other policies of or contained in the Third Party Websites or the Third Party Content. Inclusion of, linking to or permitting the
  use or installation of any Third-Party Website or any Third Party Content does not imply approval or endorsement by us.
  If you decide to leave the Platform and access the Third-Party Websites or to use or install any Third Party Content, you do
  so at your own risk and you should be aware that our terms and policies no longer govern. You should review the applicable terms
  and policies, including privacy and data gathering practices, of any website to which you navigate from the Platform or relating
  to any applications you use or install from the Platform. Any purchases you make through Third Party Websites will be through other
  websites and from other companies, and we take no responsibility whatsoever in relation to such purchases which are exclusively
  between you and the applicable third party.
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>


<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><strong>SITE MANAGEMENT</strong></div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;">We reserve the right to:</div>

<ol class="text-justify" style="list-style-type: lower-alpha;">
	<li>monitor the Platform for violations of this Agreement;</li>
	<li>take appropriate legal action against anyone who, in our sole discretion, violates this Agreement, including without limitation, reporting such user to law enforcement authorities;</li>
	<li>in our sole discretion and without limitation, refuse, restrict access to or availability of, or disable (to the extent technologically feasible) any user&rsquo;s contribution or any portion thereof that may violate this Agreement or any Company policy;</li>
	<li>in our sole discretion and without limitation, notice or liability to remove from the Platform or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems;</li>
	<li>otherwise manage the Platform in a manner designed to protect the rights and property of Company and others and to facilitate the proper functioning of the Platform.</li>
</ol>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>


<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><strong>MODIFICATIONS</strong></div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div  style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><em>To Agreement</em></div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>


<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;">
  <strong>
    This Agreement will change and evolve. We reserve the right to make these changes at our
    sole discretion and without notice. These terms of use are available under the &ldquo;Settings&rdquo; tab
    located on the side of the Platform&rsquo;s &ldquo;Account Settings&rdquo; page. Please check these terms
    often for updates. By continuing to use the Platform, you consent to all changes.
  </strong>
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>


<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><em>To Services</em></div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>


<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;">
  We reserve the right at any time to modify or discontinue, temporarily or permanently,
  the Platform Services (or any part thereof) with or without notice. You agree that we are not
  liable to you or to any third party for any modification, suspension or discontinuance of the Platform Services.
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><strong>TERM AND TERMINATION</strong></div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;">
  This Agreement shall remain in full force and effect while you use the Platform or are otherwise
  a user or member of the Platform, as applicable. You may terminate your use or participation at any time,
  for any reason, by following the instructions for terminating user accounts in your account settings,
  if available, or by contacting us using the contact information below.
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>


<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;">
  <strong>
    Without limiting any other provision of this agreement, we reserve the right to, in our sole discretion and without notice or liability,
    deny access to and use of the Platform and the Platform Services, to any person for any reason or for no reason at all, including without
    limitation for breach of any representation, warranty or covenant contained in this Agreement, or of any applicable law or regulation,
    and we may terminate your use or participation in the Platform and the Platform Services, delete your profile and any content or information
    that you have posted at any time, without warning, in our sole discretion.
  </strong>
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>


<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;">
  In order to protect the integrity of the Platform and Platform Services, we reserve the right at any
  time in our sole discretion to block certain IP addresses from accessing the Platform and Platform Services.
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>


<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;">
  Any provisions of this Agreement that,
  in order to fulfill the purposes of such provisions,
  need to survive the termination or expiration of this Agreement,
  shall be deemed to survive for as long as necessary to fulfill such purposes.
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>


<div style="text-align:justify;line-height:normal;color:black;">
  If we terminate or suspend your account for any reason, you are prohibited
  from registering and creating a new account under your name, a fake or borrowed name,
  or the name of any third party, even if you may be acting on behalf of the third party.
  In addition to terminating or suspending your account, we reserve the right to take appropriate legal action,
  including without limitation pursuing civil, criminal, and injunctive redress.
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>


<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><strong>USER DATA</strong></div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;">
  Our Platform will maintain certain data and documents that you transfer to the Platform for the purpose of the performance of
  the Platform Services, and relating to your use of the Platform Services.
  Although we perform regular routine backups of data and documents, you are primarily
  responsible for all data and documents that you have transferred or that relates
  to any activity you have undertaken using the Platform Services.
  You agree that we have no liability to you for any loss or corruption of any such data or documents,
  and you hereby waive any right of action against Company arising from any such loss or corruption of such data.
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>


<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><strong>DATA PROTECTION</strong></div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>


<div>
  Data and documents which you may have access to or otherwise process on the Platform will include Personal Data,
  also known as Personally Identifiable Information, of people. That Personal Data may also be governed by the EU
  General Data Protection Regulation 2016/679 (&quot;GDPR&quot;) and other laws. Regarding any personal data,
  you process in connection with the Platform Services, we are the Processor of such data (as such terms is defined in GDPR)
  and you are our sub-processor. You agree that you will only process Personal Data in accordance with these terms and to fulfill them,
  and in line with our documented instructions, unless processing is required by law to which you are subject,
  in which case you shall inform us of that legal requirement before the relevant processing of that Personal Data.
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div>
  You are and will remain contractually committed to confidentiality,
  will Process Personal Data only as instructed by Company and according to this Agreement,
  and will preserve the security of the data as described herein.
  You will take industry appropriate technical and organizational measures to ensure the security of the Personal Data and any Processing.
  You will not transfer any Personal Data to any third party.
  If requested by us, you will assist us in accessing, amending, deleting or moving any Personal Data and meeting other data subject rights.
  You will inform us without delay in the event of any unauthorized access to Personal Data while the data is processed by you.
  You will delete or return, at Company&rsquo;s discretion, any Personal Data where so instructed by us, or
  on termination as detailed above, unless retention is required by applicable law.
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div>
  You will make available all information necessary, and reasonably available to you to
  demonstrate compliance with these terms and will allow for and contribute to audits and
  inspection in this regard. You will assist us, where required, in ensuring compliance with
  our obligations pursuant to GDPR Articles 32 to 36 (data security, breach notification and related obligations).
</div>

<div>You may process the following types and categories of Personal Data for Company:
  name, CV, degree certificates and transcripts, work experience and related documents of visa applicants;
  any additional data ordinarily required as part of the evaluation process and transferred by us to you.
  Our data protection team may be contacted at&nbsp;<a href="mailto:privacy@parkeval.com">privacy@parkeval.com</a>.
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><strong>DISPUTES</strong></div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div><strong><em>Between Users</em></strong></div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div>
  If you have a dispute with one or more other users or Legal Service Providers,
  you hereby release us and our officers, employees, agents and successors in
  rights from claims, demands and damages (actual and consequential) of every kind or
  nature, known or unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in
  any way related to such disputes and/or the Platform Services.
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div><strong><em>With Company</em></strong></div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div>
  All questions of law, rights, and remedies regarding any act,
  event or occurrence undertaken pursuant or relating to this Platform or the
  Platform Services shall be governed and construed by the law of New York State, excluding such
  state&rsquo;s conflicts of law rules. &nbsp;Any legal action of whatever nature by or against us
  arising out of or related in any respect to this Platform and the Platform Services shall be brought
  solely in either the applicable federal or state courts located in or with jurisdiction over New York County,
  State of New York; subject, however, to our right to bring an action to seek injunctive relief to enforce
  this Agreement or to stop or prevent an infringement of proprietary or other third party rights
  (or any similar cause of action) in any applicable court in any jurisdiction where jurisdiction exists
  with regard to a user. You hereby consent to (and waive any challenge or objection to) personal jurisdiction and venue in the above-referenced courts.
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div><strong>CORRECTIONS</strong></div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;color:black;">
  Occasionally there may be information on the Platform that contains typographical errors, inaccuracies or
  omissions that may relate to service descriptions, pricing, availability, and various other information.
  Company reserves the right to correct any errors, inaccuracies or omissions and to change or update the
  information at any time, without prior notice.
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><strong>DISCLAIMERS</strong></div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>


<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;">
  YOU AGREE THAT YOUR USE OF THE PLATFORM AND PLATFORM SERVICES WILL BE AT YOUR SOLE RISK.
  TO THE FULLEST EXTENT PERMITTED BY LAW, COMPANY, ITS OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS DISCLAIM ALL WARRANTIES,
  EXPRESS OR IMPLIED, IN CONNECTION WITH THE PLATFORM AND THE PLATFORM SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION,
  THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. COMPANY MAKES NO WARRANTIES
  OR REPRESENTATIONS ABOUT THE PERFORMANCE OF THE PLATFORM SERVICES AND ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY (A) ERRORS, MISTAKES,
  OR INACCURACIES IN THE OPERATION OF THE PLATFORM, (B) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR
  ACCESS TO AND USE OF OUR PLATFORM, (C) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION
  AND/OR FINANCIAL INFORMATION STORED THEREIN, (D) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE PLATFORM OR PLATFORM SERVICES,
  (E) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE PLATFORM BY ANY THIRD PARTY, AND/OR (F)
  ANY ERRORS OR OMISSIONS IN THE PLATFORM, ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE
  USE OF THE PLATFORM SERVICES OR ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE PLATFORM. COMPANY DOES NOT WARRANT,
  ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE PLATFORM OR
  ANY HYPERLINKED PLATFORM OR FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND COMPANY WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE
  FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>


<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><strong>LIMITATIONS OF LIABILITY</strong></div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div>
  IN NO EVENT SHALL COMPANY OR ITS DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT,
  INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE,
  LOSS OF DATA OR OTHER DAMAGES ARISING FROM YOUR USE OF THE PLATFORM OR PLATFORM SERVICES,
  EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>


<div>
  IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE CALIFORNIA CIVIL CODE SECTION 1542, WHICH SAYS: &quot;A GENERAL RELEASE
  DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING
  THE RELEASE, WHICH, IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR.&quot;
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div><strong>INDEMNITY</strong></div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal; color:black;">
  You agree to defend, indemnify and hold Company, its subsidiaries, and affiliates, and their respective officers,
  agents, partners and employees, harmless from and against, any loss, damage, liability, claim, or demand, including
  reasonable attorneys&rsquo; fees and expenses, made by any third party due to or arising out of your contributed content,
  use of the Platform Services, and/or arising from a breach of this Agreement and/or any breach of your representations
  and warranties set forth above. Company will use reasonable efforts to notify you of any such claim, action, or proceeding
  which is subject to this indemnification upon becoming aware of it.
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div style="text-align:justify;line-height:normal;color:black;"><strong>GOVERNMENT USERS</strong></div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div style="text-align:justify;line-height:normal;color:black;">
  If you are a U.S. government entity, you acknowledge that any software and documentation are provided as &ldquo;Commercial Items&rdquo;
  as defined at 48 C.F.R. 2.101, and are being licensed to U.S. government end users as commercial computer software subject to the restricted
  rights described in 48 C.F.R. 2.101 and 12.212.
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div><strong>MISCELLANEOUS</strong></div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>
<div>
  This Agreement and your account may not be assigned by you without our express written consent.
  We may assign any or all of our rights and obligations to others at any time. We are not
  be responsible or liable for any loss, damage, delay or failure to act caused by any cause beyond
  our reasonable control. If any provision or part of a provision of this Agreement is unlawful, void or
  unenforceable, that provision or part of the provision is deemed severable from this Agreement and does
  not affect the validity and enforceability of any remaining provisions.
</div>
<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;color:black;"><strong>COMMUNICATIONS</strong></div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div>
  We will communicate with you by email or by posting communications via the Platform.
  You consent to receive communications from us electronically and you agree that these electronic communications
  satisfy any legal requirement that such communications be in writing. You hereby waive any and all defenses you
  may have based on the electronic form of this Agreement and the lack of signing by the parties hereto to execute
  this Agreement. Legal Service Providers and other users of the Platform Services waive any right of action,
  including right to seek damages, arising out of or relating to the ordering or performance of Legal Services via the Platform.
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div>
  You will be considered to have received communication when we send it to the email
  address you have provided to us via the Platform, or when we post such communication on the Platform.
  You must keep your email address updated on the Platform and you must regularly check the Platform for postings.
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div>
  If you fail to respond to an email message from us regarding violation,
  dispute or complaint within two business days, we have the right to terminate
  or suspend your use of the Platform.
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;color:black;">
  All notices to us intended to have a legal effect concerning this Agreement must be
  in writing and delivered either in person or by a means evidenced by a delivery receipt,
  to the following address, and are deemed effective upon receipt:
</div>

<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div style='color:black;'>Park Firm LLC</div>
<div style='color:#333333;'>555 E City Ave, Suite 940</div>
<div style='color:#333333;'>Bala Cynwyd, PA 19004</div>
<p style="margin-bottom:.0001pt;text-align:justify;line-height:normal;"><span>&nbsp;</span></p>

<div style="margin-bottom:.0001pt;text-align:justify;line-height:normal;">
  If your notice is a complaint that is not satisfactorily resolved,
  and you are a California resident, you can contact the Complaint Assistance Unit of the
  Division of Consumer Services of the Department of Consumer Affairs in writing at 400 &quot;R&quot; Street,
  Sacramento, California 95814 or by telephone at 1-916-445-1254.
</div>
