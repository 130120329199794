// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const DEFAULT_ENV = {
  production: false,
  // NOTE: This is already the DEV environment.
  firebase: {
    apiKey: 'AIzaSyAt6kPnQQ20k4XAybwJBJ72GzPTx1g0gBM',
    authDomain: 'park-dev-2ea17.firebaseapp.com',
    projectId: 'park-dev-2ea17',
    storageBucket: 'park-dev-2ea17.firebasestorage.app',
    messagingSenderId: '317128944646',
    appId: '1:317128944646:web:a146af26cb2fed9bae6787'
  },
  raygunApiKey: 'yyO4fOoKshUqvHh8RScxA',
  version: '4.2.33',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
