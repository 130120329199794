import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-screensize-monitor',
  templateUrl: './error-screensize-monitor.component.html',
  styleUrls: ['./error-screensize-monitor.component.scss']
})
export class ErrorScreensizeMonitorComponent implements OnInit {

  public threshold: number; // max-width at which the error will be hidden (px)

  public showError: boolean;

  constructor() {
    this.threshold = 899; // Default threshold. Adjust as needed.
    this.showError = false;
    this.onResize();
  }

  public ngOnInit(): void {
  }

  @HostListener('window:resize', ['$event'])
  public onResize(): void {
    // Add pages that bypasses the screensize monitor
    // if (new RegExp('/print-preview').test(window.location.href)) {
    //   this.showError = false;
    //   return;
    // }

    this.showError = window.innerWidth <= this.threshold;
  }
}
