/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./modal-components";
var styles_ModalHeaderComponent = [];
var RenderType_ModalHeaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ModalHeaderComponent, data: {} });
export { RenderType_ModalHeaderComponent as RenderType_ModalHeaderComponent };
export function View_ModalHeaderComponent_0(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 0)], null, null); }
export function View_ModalHeaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-modal-header", [], null, null, null, View_ModalHeaderComponent_0, RenderType_ModalHeaderComponent)), i0.ɵdid(1, 49152, null, 0, i1.ModalHeaderComponent, [], null, null)], null, null); }
var ModalHeaderComponentNgFactory = i0.ɵccf("app-modal-header", i1.ModalHeaderComponent, View_ModalHeaderComponent_Host_0, {}, {}, ["*"]);
export { ModalHeaderComponentNgFactory as ModalHeaderComponentNgFactory };
var styles_ModalBodyComponent = [];
var RenderType_ModalBodyComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ModalBodyComponent, data: {} });
export { RenderType_ModalBodyComponent as RenderType_ModalBodyComponent };
export function View_ModalBodyComponent_0(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 0)], null, null); }
export function View_ModalBodyComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-modal-body", [], null, null, null, View_ModalBodyComponent_0, RenderType_ModalBodyComponent)), i0.ɵdid(1, 49152, null, 0, i1.ModalBodyComponent, [], null, null)], null, null); }
var ModalBodyComponentNgFactory = i0.ɵccf("app-modal-body", i1.ModalBodyComponent, View_ModalBodyComponent_Host_0, {}, {}, ["*"]);
export { ModalBodyComponentNgFactory as ModalBodyComponentNgFactory };
var styles_ModalFooterComponent = [];
var RenderType_ModalFooterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ModalFooterComponent, data: {} });
export { RenderType_ModalFooterComponent as RenderType_ModalFooterComponent };
export function View_ModalFooterComponent_0(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 0)], null, null); }
export function View_ModalFooterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-modal-footer", [], null, null, null, View_ModalFooterComponent_0, RenderType_ModalFooterComponent)), i0.ɵdid(1, 49152, null, 0, i1.ModalFooterComponent, [], null, null)], null, null); }
var ModalFooterComponentNgFactory = i0.ɵccf("app-modal-footer", i1.ModalFooterComponent, View_ModalFooterComponent_Host_0, {}, {}, ["*"]);
export { ModalFooterComponentNgFactory as ModalFooterComponentNgFactory };
