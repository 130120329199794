export interface PagerQueryOptions {
  start?: number;
  end?: number;
  offset?: number; // For future use if one day we decide to apply api-paging
  page?: number; // For future use if one day we decide to apply api-paging
}

export interface ApiQueryOptions {
  queryParams?: any;
  pagerParams?: PagerQueryOptions;
}

export class ApiQueryParams {

  private queryString = '?';

  constructor(options: ApiQueryOptions) {
    if (options.queryParams) {
      // Iterate over the parameters and generate the corresponding segment
      for (const prop in options.queryParams) {
        if (options.queryParams[prop]) {
          this.queryString += `${prop}=` + encodeURIComponent(options.queryParams[prop]) + '&';
        }
      }
    }

    if (options.pagerParams) {
      // Iterate over the parameters and generate the corresponding segment
      for (const prop in options.pagerParams) {
        if (options.pagerParams[prop]) {
          this.queryString += `${prop}=` + encodeURIComponent(options.pagerParams[prop]) + '&';
        }
      }
    }
  }

  public generateQueryParams(): string {

    if (this.queryString.endsWith('&')) {
      // Remove the extra '&'
      return this.queryString.substr(0, this.queryString.length - 1);
    }

    return this.queryString;
  }
}
