import { DEFAULT_ENV } from './environment.default';

export const environment = {
  ...DEFAULT_ENV,
  firebase: {
    apiKey: 'AIzaSyAvHr7H5cWgEpOsgo9C34ZwTRUMe7ZYWq8',
    authDomain: 'parkeval-production.firebaseapp.com',
    databaseURL: 'https://parkeval-production.firebaseio.com',
    projectId: 'parkeval-production',
    storageBucket: 'parkeval-production.appspot.com',
    messagingSenderId: '153585928431',
    appId: '1:153585928431:web:c187a18c727cfd46d7f31a'
  },
  production: true,
  raygunApiKey: 'UvlZ6ljXuJGxJiCr03uw'
};
