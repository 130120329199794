import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { RouterModule } from '@angular/router';
import { ShellComponent } from './shell/shell.component';
import { FormsModule } from '@angular/forms';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LogoutComponent } from './logout/logout.component';
import { GlobalLoaderComponent } from './global-loader/global-loader.component';
import { ValidatorsModule } from '../validators/validators.module';
import { PasswordResetModule } from './password-reset/password-reset.module';
import { SignupComponent } from './signup/signup.component';

@NgModule({
  declarations: [
    LoginComponent,
    ShellComponent,
    ForgotPasswordComponent,
    LogoutComponent,
    GlobalLoaderComponent,
    SignupComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ValidatorsModule,
    PasswordResetModule
  ],
  exports: [
    GlobalLoaderComponent,
  ]
})
export class PublicModule { }
