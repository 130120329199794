<div class="main-box">
  <div class="text-center">
    <p>You have successfully logged out.</p>
    <div>
      <button class="btn btn-primary" routerLink="/login">Login Page</button>
    </div>
  </div>
</div>


