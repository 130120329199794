import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { GlobalLoaderInterceptor } from './global-loader.interceptor';
import { HttpAuthHeaderInterceptor } from './http-auth-header.interceptor';
import { HttpCacheInterceptorService } from './http-cache.interceptor.service';
import { HttpErrorInterceptorService } from './http-error-interceptor.service';

// @ts-ignore
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCacheInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalLoaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpAuthHeaderInterceptor,
      multi: true,
    },
  ]
})
export class InterceptorsModule { }
