import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class UnsavedChangesService {

  public modalAction: Subject<boolean> = new Subject<boolean>();

  public shown: Subject<boolean> = new Subject<boolean>();

  constructor() {
  }

  public show(): void {
    this.shown.next(true);
  }

  public hide(): void {
    this.shown.next(false);
  }
}
