import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { NoteMessage } from '../../api/messaging/note-message';
import { HttpClient } from '@angular/common/http';
import { AdsCaseQuoteError } from '../../api/case/ads-quote-error';
import { MessageCategory } from '../../api/messaging/message-category';
import { MessageScope } from '../../api/messaging/message-scope';
import { switchMap } from 'rxjs/operators';
import { sortBy } from '../../utils/sort-by';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  constructor(private httpClient: HttpClient) {
  }

  public static convertAdsQuoteErrorToNoteMessage(errors: AdsCaseQuoteError[], caseId?: number): NoteMessage[] {
    return errors.map((error): NoteMessage => {
      return {
        creator: {
          id: error.createdBy.id,
          firstName: error.createdBy.firstName,
          lastName: error.createdBy.lastName,
        },
        creationDate: error.creationDate,
        lastModified: error.lastModified,
        scopeId: caseId,
        id: error.id,
        category: MessageCategory.QuoteError,
        messageSubject: 'QUOTE ERROR NOTE',
        messageBody: error.description,
      } as NoteMessage;
    });
  }

  public getServiceNotes(caseId: number, serviceId: number): Observable<NoteMessage[]> {
    return this.httpClient
      .get<NoteMessage[]>(`/api/messaging/note/case/${caseId}/service/${serviceId}`);
  }

  public getAdsServiceNotes(serviceId: number): Observable<NoteMessage[]> {
    return this.httpClient
      .get<NoteMessage[]>(`/api/messaging/note/ads-case-service/${serviceId}`);
  }

  public getDisplayNotes(serviceId: number): Observable<NoteMessage[]> {
    return this.httpClient
      .get<NoteMessage[]>(`api/messaging/note/service/${serviceId}/display`);
  }

  public getNotesByCategories(caseId: number, taskServiceId: number, categories: string[]): Observable<NoteMessage[]> {
    const url = `/api/messaging/note/case/${caseId}/service/${taskServiceId}/categories?categories=${categories}`;
    return this.httpClient
      .get<NoteMessage[]>(url);
  }

  public addServiceNote(note: NoteMessage): Observable<NoteMessage> {
    return this.httpClient
      .post<NoteMessage>(`/api/messaging/note`, note);
  }

  public updateNote(note: NoteMessage): Observable<NoteMessage> {
    return this.httpClient
      .put<NoteMessage>(`/api/messaging/note`, note);
  }

  public deleteNote(note: NoteMessage): Observable<void> {
    return this.httpClient
      .delete<void>(`/api/messaging/note/${note.id}`);
  }

  public getFilesNotes(fileIds: number[]): Observable<NoteMessage[]> {
    return this.httpClient
      .get<NoteMessage[]>(`/api/messaging/files/?fileIds=${fileIds}`);
  }

  public toggleNoteResolved(note: NoteMessage): Observable<NoteMessage> {
    return this.httpClient
      .put<NoteMessage>(`/api/messaging/note/${note.id}/toggle-resolved`, null);
  }

  public getAdsNotes(caseId: number): Observable<NoteMessage[]> {
    return this.httpClient
      .get<NoteMessage[]>(`/api/messaging/note/ads-case/${caseId}`);
  }

  public getCompanyNote(companyId: number, category: MessageCategory = MessageCategory.AdsCustomerNote)
    : Observable<NoteMessage[]> {
    return this.httpClient
      .get<NoteMessage[]>(`/api/messaging/note/category/${category}/scope/COMPANY/id/${companyId}`);
  }

  public getCompanyContactNote(
    userId: number,
    category: MessageCategory = MessageCategory.AdsCustomerContactNote,
  ): Observable<NoteMessage[]> {
    return this.httpClient
      .get<NoteMessage[]>(`/api/messaging/note/category/${category}/scope/COMPANY_CONTACT/id/${userId}`);
  }

  public getCaseCancellationNote(caseId: number): Observable<NoteMessage> {
    return this.httpClient
      .get<NoteMessage[]>(
        `/api/messaging/note/category/${MessageCategory.Cancel}/scope/${MessageScope.Case}/id/${caseId}`
      )
      .pipe(
        switchMap((note) => {
          // The endpoint return an array value
          // We will only return the first index since we can only cancel the case ONCE
          return of(note[0]);
        })
      );
  }

  public getAdsCaseCancellationNote(caseId: number): Observable<NoteMessage> {
    return this.httpClient
      .get<NoteMessage[]>(
        `/api/messaging/note/category/${MessageCategory.Cancel}/scope/${MessageScope.AdsCase}/id/${caseId}`
      )
      .pipe(
        switchMap((note) => {
          // The endpoint return an array value
          // We will only return the first index since we can only cancel the case ONCE
          return of(note[0]);
        })
      );
  }

  public getLatestCaseHoldNote(caseId: number): Observable<NoteMessage> {
    return this.httpClient
      .get<NoteMessage[]>(
        `/api/messaging/note/category/${MessageCategory.HOLD}/scope/${MessageScope.Case}/id/${caseId}`
      )
      .pipe(
        switchMap((notes) => {
          return of(
            sortBy(notes, 'lastModified')
              .pop()
          );
        })
      );
  }

  public getLatestAdsCaseHoldNote(caseId: number): Observable<NoteMessage> {
    return this.httpClient
      .get<NoteMessage[]>(
        `/api/messaging/note/category/${MessageCategory.HOLD}/scope/${MessageScope.AdsCase}/id/${caseId}`
      )
      .pipe(
        switchMap((notes) => {
          return of(
            sortBy(notes, 'lastModified')
              .pop()
          );
        })
      );
  }

  public getLatestServiceHoldNote(caseId: number, serviceId: number): Observable<NoteMessage> {
    return this.getNotesByCategories(caseId, serviceId, ['HOLD'])
      .pipe(
        switchMap((notes) => {
          return of(
            sortBy(notes, 'lastModified')
              .pop()
          );
        })
      );
  }
}
