import { of, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { skipWhile, switchMap } from 'rxjs/operators';
import { isNullOrUndefinedOrBlank } from '../utils/is-null-or-undefined';
import { find } from '../utils/find';
import { UserPermissionType } from '../api/organization/user-permissions';
import * as i0 from "@angular/core";
import * as i1 from "../services/auth.service";
var ParkEvalOnlyGuard = /** @class */ (function () {
    function ParkEvalOnlyGuard(authService) {
        this.authService = authService;
    }
    ParkEvalOnlyGuard.prototype.canActivate = function () {
        return this.authService
            .status
            .pipe(skipWhile(function (user) { return isNullOrUndefinedOrBlank(user); }), switchMap(function (user) {
            if (find(user.permissions, { permission: UserPermissionType.ParkEval })) {
                return of(true);
            }
            return throwError('You do not have permissions to access this module (ParkEval)');
        }));
    };
    ParkEvalOnlyGuard.ngInjectableDef = i0.defineInjectable({ factory: function ParkEvalOnlyGuard_Factory() { return new ParkEvalOnlyGuard(i0.inject(i1.AuthService)); }, token: ParkEvalOnlyGuard, providedIn: "root" });
    return ParkEvalOnlyGuard;
}());
export { ParkEvalOnlyGuard };
