import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, skipWhile, take, tap } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, throwError as observableThrowError } from 'rxjs';
import { FirebaseService } from './firebase.service';
import * as firebase from 'firebase/app';
import { User } from '../api/user/user';
import { isNull, isNullOrUndefined } from 'util';
import { UserRoleType } from '../api/user/user-role-type';
import { UserType } from '../api/user/user-type';
import { sortBy } from '../utils/sort-by';
import { UserGroup } from '../api/organization/user-group';

@Injectable()
export class AuthService {

  /**
   * Currently logged in user.
   * This will always have a value when user is logged in inside the main module.
   */
  public currentUser: User;

  public currentUserType: UserType;

  /**
   * Contains the stream of the currently logged in user.
   *
   * IMPORTANT NOTE:
   * Subscribe to this only when you are implementing a logic that displays or handles the user
   * as it changes in session (e.g top navbar).
   * Also this can return NULL during the app's lifecycle so make sure to handle this.
   *
   * You won't need to subscribe to this 95% of the time.
   * Otherwise - user this.currentUser
   */
  public status = new BehaviorSubject<User>(undefined);

  /**
   * Contains the currently active RAW session token
   * Does not contain proper auth headers that are required for http calls.
   * Use the correct method for getting that one
   */
  public token = null;

  /**
   * Contains the firebase user object for this session.
   * NOTE: Will always have a value when inside the main module.
   */
  private firebaseUser: firebase.User = null;

  /**
   * Only used for debugging until backend roles are full available.
   */
  private forceUserType: UserRoleType;

  private forceRoleStorageKey = 'parkeval_forcerole';

  private verifyOnly = false;

  constructor(private httpClient: HttpClient,
              private firebaseService: FirebaseService) {
    this.listenForSessionChange();

    this.forceUserType = window.localStorage.getItem(this.forceRoleStorageKey) as UserRoleType;
  }

  public login(email: string, password: string, forceRole?: UserRoleType): Observable<firebase.auth.UserCredential> {
    // window.localStorage.setItem(this.forceRoleStorageKey, forceRole);
    this.forceUserType = forceRole;
    return from(this.firebaseService.auth.signInWithEmailAndPassword(email, password));
  }

  public loginToVerify(email: string, password: string): Observable<firebase.auth.UserCredential> {
    // Make sure this is set.
    // We will set this to false once it the session change gets triggered.
    this.verifyOnly = true;
    return from(this.firebaseService.auth.signInWithEmailAndPassword(email, password))
      .pipe(
        catchError((e) => {
          // Set to false again if error.
          this.verifyOnly = false;
          return observableThrowError(e);
        })
      );
  }

  public logout(): Observable<void> {
    return from(this.firebaseService.auth.signOut())
      .pipe(
        tap(() => {
          this.cleanup();
        })
      );
  }

  public hasValidSession(): Observable<boolean> {
    // NOTE:
    // When the value is undefined, it means that the system has not yet received any data about the session
    // When the value is null -  we got the session information but there was no session
    // Value should contain the session user from firebase
    return this.status
      .pipe(
        skipWhile((value) => value === undefined),
        take(1),
        map((value) => {
          return isNull(value) ? false : true;
        })
      );
      // .toPromise();
  }


  public getAuthorizationHeader(): string {
    return isNullOrUndefined(this.token) ? null : 'Bearer ' + this.token;
  }

  public refreshToken(): Observable<string> {
    if (isNullOrUndefined(this.currentUser)) {
      return observableThrowError('Could not refresh token - No active session yet');
    }

    return from(this.firebaseService.auth.currentUser.getIdToken(true))
      .pipe(
        tap(token => {
          // Debug stuff here -
          this.token = token;
        })
      );
  }

  public isInternal(): boolean {
    return !!this.currentUser.roles.find((role) => role.name === UserRoleType.Internal);
  }

  public isInternalAdmin(): boolean {
    return !!this.currentUser.roles.find((role) => role.name === UserRoleType.InternalAdmin);
  }

  public isExpert(): boolean {
    return !!this.currentUser.roles.find((role) => role.name === UserRoleType.Expert);
  }

  public isCompanyAdmin(): boolean {
    return !!this.currentUser.roles.find((role) => role.name === UserRoleType.CompanyAdmin);
  }

  /**
   * Use this to debug the token-retry interceptor when it's not working.
   * This allows the the interceptor the retry-request procedure.
   */
  public poisonToken(): void {
    this.token = 'Zaf231Z-B.123456';
  }

  private listenForSessionChange(): void {
    // We can only choose on or the other.
    // Avoid using both onAuthStateChanged and onIdTokenChange at the same time.

    // this.firebaseService
    //   .auth
    //   .onAuthStateChanged((user) => {
    //     console.log('session change', user);
    //     this.syncObjects(user);
    //   });

    this.firebaseService
      .auth
      .onIdTokenChanged((user) => {
        // We need to ignore any changes to auth when we are verifying only.
        // But also make sure we reset it back. This is supposed to be a one-shot thing.
        if (this.verifyOnly) {
          this.verifyOnly = false;
          this.logout();
          return;
        }

        this.status.next(undefined);
        this.syncObjects(user);
      });
  }

  private syncObjects(user: firebase.User): void {
    if (!user) {
      this.cleanup();
      return;
    }

    this.firebaseUser = user;

    user.getIdToken()
      .then((token) => {
        this.token = token;
        this.fetchCurrentUser();
      })
      .catch(() => {
        console.error('Failed to get token.');
        this.cleanup();
      });
  }

  private cleanup(): void {
    this.token = null;
    this.currentUser = null;
    this.firebaseUser = null;
    this.status.next(null);

    try {
      window.localStorage.removeItem('park_est_notice');
      // Other to clear here
      // Probably need a more cleaner way to do this in the future.
    } catch(e) {
      console.warn('Could not clear storage items');
    }
  }

  private fetchCurrentUser(): void {
    this.getUser()
      .subscribe((user) => {
        this.currentUser = user;
        this.currentUser.groups = sortBy(this.currentUser.groups, (group: UserGroup) => group.groupName.toUpperCase());
        this.status.next(user);

        // Use this to debug expert user role
        if (this.forceUserType && this.forceUserType === UserRoleType.Expert) {
          this.forceExpertUser();
        }

        this.parseUserType();

        // Some debugging log
        // console.log('internal:', this.isInternal());
        // console.log('expert:', this.isExpert());
        // console.log(this.currentUserType);

      }, (err) => {

        // TODO: Add handling here depending in the HTTP error type.
        // E.g, when user is disabled it will get 403, etc, redirect to the necessary pages.
        console.error(err);
        this.currentUser = null;
        this.status.next(null);
      });
  }

  /**
   * Fetches the currently logged in user's information
   */
  public getUser(): Observable<User> {
    return this.httpClient.get<User>('/api/user');
  }

  private parseUserType(): void {
    this.currentUserType = null;

    if (this.isInternal()) {
      this.currentUserType = UserType.Internal;
      return;
    }

    if (this.isExpert()) {
      this.currentUserType = UserType.Expert;
      return;
    }
  }

  /**
   * FOR DEBUGGING ONLY.
   * Force the user to be an expert.
   */
  private forceExpertUser(): void {
    this.currentUser.roles = [
      {id: 123, name: UserRoleType.Expert, deleteInd: false}
    ];
  }
}
