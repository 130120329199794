<form
  #resetForm="ngForm"
  class="form-group margin-bottom-0">
  <h1 class="title my-4 text-center">Please update your password</h1>
  <div class="form-group ">
    <label>Password</label>
    <input
      type="password"
      class="form-control"
      name="password"
      [(ngModel)]="password"
      [appMinLengthValidator]="6"
      maxlength="25"
      #passControl="ngModel">
    <app-form-feedback [control]="passControl" [errorKeys]="['minLengthInvalid']"></app-form-feedback>
  </div>
  <div class="form-group">
    <label>Repeat Password</label>
    <input
      type="password"
      class="form-control"
      name="repeatPassword"
      [(ngModel)]="passwordRepeat"
      [appMinLengthValidator]="6"
      [appEqualToValidator]="password"
      maxlength="25"
      #repeatControl="ngModel">
    <app-form-feedback
      [control]="repeatControl"
      [errorKeys]="['pattern', 'minLengthInvalid']"
      [errorOverride]="{ pattern: 'Passwords do not match' }">
    </app-form-feedback>
  </div>
  <div class="text-center">
    <button
      type="submit"
      [disabled]="resetForm.invalid || requestInProgress"
      class="btn btn-primary btn-block btn-lg"
      (click)="submit()">
      Submit
    </button>
  </div>
</form>

<div class="alert alert-danger text-center" *ngIf="errorMsg && errorMsg.length > 0">
  <strong>{{errorMsg}}</strong>
  <hr />
  <div>You can send another reset password link by clicking the button below.</div>
  <a routerLink="/main/public/forgot-password" class="btn btn-link">
    Forgot Password
  </a>
</div>
