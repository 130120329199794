/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./public/global-loader/global-loader.component.ngfactory";
import * as i4 from "./public/global-loader/global-loader.component";
import * as i5 from "./services/global-loader.service";
import * as i6 from "./errors/error-screensize-monitor/error-screensize-monitor.component.ngfactory";
import * as i7 from "./errors/error-screensize-monitor/error-screensize-monitor.component";
import * as i8 from "@angular/common";
import * as i9 from "../../node_modules/primeng/components/toast/toast.ngfactory";
import * as i10 from "primeng/components/toast/toast";
import * as i11 from "primeng/components/common/messageservice";
import * as i12 from "./main/shared/unsaved-changes-modal/unsaved-changes-modal.component.ngfactory";
import * as i13 from "./main/shared/unsaved-changes-modal/unsaved-changes-modal.component";
import * as i14 from "./services/unsaved-changes.service";
import * as i15 from "./main/offline-screen/offline-screen.component.ngfactory";
import * as i16 from "./main/offline-screen/offline-screen.component";
import * as i17 from "./services/connection-daemon.service";
import * as i18 from "./main/terms-overlay/terms-overlay.component.ngfactory";
import * as i19 from "./main/terms-overlay/terms-overlay.component";
import * as i20 from "./main/terms-overlay/terms-overlay.service";
import * as i21 from "./services/data/system-terms.service";
import * as i22 from "./app.component";
import * as i23 from "./services/auth.service";
import * as i24 from "./services/raygun.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "style-index-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["routerLink", "/main/style-index"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Theme and Style Index (For Developers)"]))], function (_ck, _v) { var currVal_0 = "/main/style-index"; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-global-loader", [], null, null, null, i3.View_GlobalLoaderComponent_0, i3.RenderType_GlobalLoaderComponent)), i1.ɵdid(3, 114688, null, 0, i4.GlobalLoaderComponent, [i5.GlobalLoaderService, i2.Router], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-error-screensize-monitor", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_ErrorScreensizeMonitorComponent_0, i6.RenderType_ErrorScreensizeMonitorComponent)), i1.ɵdid(5, 114688, null, 0, i7.ErrorScreensizeMonitorComponent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(7, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "p-toast", [], null, null, null, i9.View_Toast_0, i9.RenderType_Toast)), i1.ɵdid(9, 1294336, null, 1, i10.Toast, [i11.MessageService], null, null), i1.ɵqud(603979776, 1, { templates: 1 }), (_l()(), i1.ɵeld(11, 0, null, null, 1, "app-unsaved-changes-modal", [], null, null, null, i12.View_UnsavedChangesModalComponent_0, i12.RenderType_UnsavedChangesModalComponent)), i1.ɵdid(12, 245760, null, 0, i13.UnsavedChangesModalComponent, [i14.UnsavedChangesService], null, null), (_l()(), i1.ɵeld(13, 0, null, null, 1, "app-offline-screen", [], null, null, null, i15.View_OfflineServicesComponent_0, i15.RenderType_OfflineServicesComponent)), i1.ɵdid(14, 114688, null, 0, i16.OfflineServicesComponent, [i17.ConnectionDaemonService], null, null), (_l()(), i1.ɵeld(15, 0, null, null, 1, "app-terms-overlay", [], null, null, null, i18.View_TermsOverlayComponent_0, i18.RenderType_TermsOverlayComponent)), i1.ɵdid(16, 114688, null, 0, i19.TermsOverlayComponent, [i20.TermsOverlayService, i21.SystemTermsService, i2.Router], null, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); _ck(_v, 3, 0); _ck(_v, 5, 0); var currVal_0 = _co.styleIndexEnabled; _ck(_v, 7, 0, currVal_0); _ck(_v, 9, 0); _ck(_v, 12, 0); _ck(_v, 14, 0); _ck(_v, 16, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i22.AppComponent, [i23.AuthService, i2.Router, i24.RaygunService, i17.ConnectionDaemonService], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i22.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
