import { Injectable } from '@angular/core';
import { WorkflowService } from './workflow.service';
import { HttpClient } from '@angular/common/http';
import { UpdateAdsActionRequest } from '../../api/workflow/update-ads-action-request';
import { Observable } from 'rxjs';
import { WorkflowAction } from '../../api/workflow-action';
import { tap } from 'rxjs/operators';
import { clone } from '../../utils/clone';
import { includes } from '../../utils/includes';

@Injectable({
  providedIn: 'root'
})
export class WorkflowAdsService extends WorkflowService {

  protected workflows: WorkflowAction[] = [];

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public init(): Observable<WorkflowAction[]> {
    return this.httpClient
      .get<WorkflowAction[]>('/api/park-ads/workflow-actions')
      .pipe(tap((response) => this.workflows = response));
  }

  public getByIds(ids: number[]): WorkflowAction[] {
    return clone(this.workflows.filter((workflow) => includes(ids, workflow.id)));
  }

  public updateAdsAction(requests: UpdateAdsActionRequest): Observable<void> {
    return this.httpClient
      .post<void>(`/api/park-ads/workflow/update`, requests);
  }

  public getCaseActions(caseId: number): Observable<number[]> {
    return this.httpClient
      .get<number[]>(`/api/park-ads/workflow-actions/case/${caseId}`);
  }

  public getServiceActions(serviceId: number): Observable<number[]> {
    return this.httpClient
      .get<number[]>(`/api/park-ads/workflow-actions/service/${serviceId}`);
  }
}
