export interface PasswordResetResponse {
  statusCode: PasswordResetCode;
  errMessage: string;
}

export enum PasswordResetCode {
  OK = 'OK',
  RESET_PASSWORD_EXCEED_LIMIT = 'RESET_PASSWORD_EXCEED_LIMIT',
  BAD_REQUEST = 'BAD_REQUEST',
}

