import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BannedEmailsValidatorDirective } from './banned-emails-validator.directive';
import { ExistingEmailValidatorDirective } from './existing-email-validator.directive';
import { ValidateEmailPatternDirective } from './validate-email-pattern.directive';
import { ValidateMinDateDirective } from './validate-min-date.directive';
import { ValidateMaxDateDirective } from './validate-max-date.directive';
import { NoPendingServiceValidatorDirective } from './no-pending-service-validator.directive';
import { DuplicateConfigValidatorDirective } from './duplicate-config-validator.directive';
import { MinLengthValidatorDirective } from './min-length.validator.directive';
import { PhoneValidatorDirective } from './phone-validator.directive';
import { NumberValidatorDirective } from './number-validator.directive';
import { UniqueParagraphValidatorDirective } from './unique-paragraph-validator.directive';
import { EqualToValidatorDirective } from './equal-to-validator.directive';
import { ValidateMinDateMultiDirective } from './validate-min-date-multi.directive';
import { MinNumberValidatorDirective } from './min-number-validator.directive';
import { MaxNumberValidatorDirective } from './max-number-validator.directive';
import { MinMaxValidatorDirective } from './min-max-validator.directive';
import { ExistingCompanyValidatorDirective } from './existing-company-validator.directive';
import { NoIndividualCompanyValidatorDirective } from './no-individual-company-validator.directive';
import { IdValidatorDirective } from './id-validator.directive';
import { NotEqualToValidatorDirective } from './not-equal-to-validator.directive';
import { ExistingConsumerNameValidatorDirective } from './existing-consumer-name-validator.directive';
import { ExistingConsumerUrlValidatorDirective } from './existing-consumer-url-validator.directive';
import { ExistingCompanyTeamNameValidatorDirective } from './existing-company-team-name-validator.directive';
import { ExistingCompanyEndClientValidatorDirective } from './existing-company-end-client-validator.directive';
import { ExistingMediaNameValidatorDirective } from './existing-media-name-validator.directive';
import { ExistingCollegeNameValidatorDirective } from './existing-college-name-validator.directive';
import { AlphaNumericPlusBasicValidatorDirective } from './alphanum-plus-basic.validator.directive';
import { ExistingGroupValidatorDirective } from './existing-group-validator.directive';
import { AnyArrayValueExistsDirective } from './any-array-value-exists.directive';

@NgModule({
  declarations: [
    BannedEmailsValidatorDirective,
    ExistingEmailValidatorDirective,
    ValidateEmailPatternDirective,
    ValidateMinDateDirective,
    ValidateMaxDateDirective,
    NoPendingServiceValidatorDirective,
    ExistingEmailValidatorDirective,
    DuplicateConfigValidatorDirective,
    MinLengthValidatorDirective,
    PhoneValidatorDirective,
    NumberValidatorDirective,
    UniqueParagraphValidatorDirective,
    EqualToValidatorDirective,
    ValidateMinDateMultiDirective,
    MinNumberValidatorDirective,
    MaxNumberValidatorDirective,
    MinMaxValidatorDirective,
    NoIndividualCompanyValidatorDirective,
    ExistingCompanyValidatorDirective,
    IdValidatorDirective,
    NotEqualToValidatorDirective,
    ExistingConsumerNameValidatorDirective,
    ExistingConsumerUrlValidatorDirective,
    ExistingCompanyTeamNameValidatorDirective,
    ExistingCompanyEndClientValidatorDirective,
    ExistingMediaNameValidatorDirective,
    ExistingCollegeNameValidatorDirective,
    AlphaNumericPlusBasicValidatorDirective,
    ExistingGroupValidatorDirective,
    AnyArrayValueExistsDirective,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    BannedEmailsValidatorDirective,
    ExistingEmailValidatorDirective,
    ValidateEmailPatternDirective,
    ValidateMinDateDirective,
    ValidateMaxDateDirective,
    NoPendingServiceValidatorDirective,
    DuplicateConfigValidatorDirective,
    MinLengthValidatorDirective,
    PhoneValidatorDirective,
    NumberValidatorDirective,
    UniqueParagraphValidatorDirective,
    EqualToValidatorDirective,
    ValidateMinDateMultiDirective,
    MinNumberValidatorDirective,
    MaxNumberValidatorDirective,
    MinMaxValidatorDirective,
    NoIndividualCompanyValidatorDirective,
    ExistingCompanyValidatorDirective,
    IdValidatorDirective,
    NotEqualToValidatorDirective,
    ExistingConsumerNameValidatorDirective,
    ExistingConsumerUrlValidatorDirective,
    ExistingCompanyTeamNameValidatorDirective,
    ExistingCompanyEndClientValidatorDirective,
    ExistingMediaNameValidatorDirective,
    ExistingCollegeNameValidatorDirective,
    AlphaNumericPlusBasicValidatorDirective,
    ExistingGroupValidatorDirective,
    AnyArrayValueExistsDirective,
  ]
})
export class ValidatorsModule { }
