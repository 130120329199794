import { Injectable } from '@angular/core';
import { User } from '../../api/user/user';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SystemTerms } from '../../api/system-terms';

@Injectable({
  providedIn: 'root'
})
export class SystemTermsService {

  constructor(private httpClient: HttpClient) { }

  public get(organizationId: number): Observable<SystemTerms> {
    return this.httpClient.get<SystemTerms>(`/api/term/organization/${organizationId}`);
  }

  public bypass(termId: number, bypass: boolean, reason: string): Observable<SystemTerms> {
    return this.httpClient.post<SystemTerms>(`/api/term/${termId}/bypass/${bypass}`, reason);
  }

  /**
   * Use this to accept a term as PM INTERNAL USER (for now)
   * @param data
   */
  public update(data: SystemTerms): Observable<SystemTerms> {
    return this.httpClient.post<SystemTerms>(`/api/term/${data.id}/update`, data);
  }

  /**
   * Not sure what this is for yet.
   * @param type
   * @param organizationId
   */
  public accept(type: any, organizationId: number): Observable<SystemTerms> {
    return this.httpClient.post<SystemTerms>(`/api/term/${type}/accept/${organizationId}`, null);
  }

  /**
   * Used by non-internal.
   */
  public acceptForExpert(): Observable<SystemTerms> {
    return this.httpClient.post<SystemTerms>(`/api/term/accept`, null);
  }
}
