import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FirebaseService } from '../../services/firebase.service';
import { ToastService } from '../../services/toast.service';
import { RaygunService } from '../../services/raygun.service';
var PasswordResetComponent = /** @class */ (function () {
    function PasswordResetComponent(fb, authService, route, firebase, toast, raygun, router) {
        this.fb = fb;
        this.authService = authService;
        this.route = route;
        this.firebase = firebase;
        this.toast = toast;
        this.raygun = raygun;
        this.router = router;
        this.requestInProgress = false;
    }
    PasswordResetComponent.prototype.ngOnInit = function () {
    };
    PasswordResetComponent.prototype.redirectToLogIn = function () {
        this.router.navigate(['/public/login']);
    };
    PasswordResetComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.errorMsg = null;
                        this.requestInProgress = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, this.firebase.auth.verifyPasswordResetCode(this.code)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.firebase.auth.confirmPasswordReset(this.code, this.password)];
                    case 3:
                        _a.sent();
                        this.toast.addSuccess('Password updated');
                        this.redirectToLogIn();
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _a.sent();
                        // handling errors here.
                        if (e_1.code && e_1.code === 'auth/invalid-action-code') {
                            this.errorMsg = 'Reset link has expired';
                            return [2 /*return*/];
                        }
                        // Other errors - they will be send to raygun if unhandled.
                        this.errorMsg = 'Failed to update your password. Try again or please contact support.';
                        this.raygun.send(e_1, {
                            message: 'Failed to update password from Firebase',
                        });
                        this.requestInProgress = false;
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    return PasswordResetComponent;
}());
export { PasswordResetComponent };
