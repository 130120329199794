import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { UserType } from '../api/user/user-type';
import { UserPermissionType } from '../api/organization/user-permissions';
import { skipWhile, switchMap } from 'rxjs/operators';
import { isNullOrUndefinedOrBlank } from '../utils/is-null-or-undefined';

@Injectable({
  providedIn: 'root'
})
export class UserGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  /**
   * This guard is for detecting which module to redirect the current user.
   *
   * @param next
   * @param state
   */
  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {

    return this.authService
      .status
      .pipe(
        skipWhile(user => isNullOrUndefinedOrBlank(user)),
        switchMap((user) => {
          if (user.userType === UserType.Internal) {
            return of(this.handleInternal());
          }

          if (user.userType  ===  UserType.Expert) {
            this.handleExpert();
          }

          if (user.userType  === UserType.Company) {
            this.handleCustomer();
          }

          // Everything else returns false. Only the internal user type can display the instance selector
          return of(false);
        })
      );
  }

  private handleInternal(): boolean {
    const permissions = this.authService
      .currentUser
      .permissions
      .map((perms) => perms.permission);


    // Do you have access to both systems?
    if (permissions.includes(UserPermissionType.ParkEval) && permissions.includes(UserPermissionType.ParkAds)) {
      // Nothing to do here. We will render instance selector.
      return true;
    }

    // For ParkAds only
    if (permissions.includes(UserPermissionType.ParkAds)) {
      this.router.navigate(['/park-ads/internal']);
      return false;
    }

    // For ParkEval only
    if (permissions.includes(UserPermissionType.ParkEval)) {
      this.router.navigate(['/main']);
      return false;
    }

    // If you reached here, you don't have either. That is definitely a problem.
    // Normally we wouldn't reach here.
    throwError('No valid module permission for this user');
  }

  private handleExpert(): void {
    this.router.navigate(['/main']);
  }

  private handleCustomer(): void {
    // In the current setup, ONLY park ads company are allowed to login.
    // ParkEval companies are not allowed to access the system.
    this.router.navigate(['/park-ads/company']);
  }

}
