import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';
import * as i0 from "@angular/core";
var HttpCacheService = /** @class */ (function () {
    function HttpCacheService() {
        this.ignoreOnceList = [];
        this.cache = [];
        /**
         * Time until the aggressive ttl cache expires
         */
        this.aggressiveTTL = 2000;
    }
    HttpCacheService.prototype.add = function (url) {
        // TODO: Add support for e-tags too if supported by backend
        // Do a cleanup first.
        this.cleanup();
        var existing = this.cache.find(function (cache) { return cache.url === url; });
        // Making sure we do not re-add existing ones.
        if (existing) {
            return;
        }
        // Not existing yet.
        this.cache.push({
            url: url,
            response: null,
            request: new BehaviorSubject(null),
            requested: new Date(),
            completed: false,
        });
    };
    HttpCacheService.prototype.get = function (url) {
        return this.cache.find(function (response) { return response.url === url; });
    };
    HttpCacheService.prototype.cleanup = function () {
        var _this = this;
        var now = new Date().valueOf();
        this.cache = this.cache.filter(function (cache) {
            if (!cache.completed) {
                return true;
            }
            return now - cache.requested.valueOf() < _this.aggressiveTTL;
        });
    };
    HttpCacheService.prototype.ignoreOnce = function (url) {
        this.ignoreOnceList.push(url);
    };
    HttpCacheService.prototype.cleanupIgnoreOnce = function (url) {
        this.ignoreOnceList = this.ignoreOnceList.filter(function (eIgnoreUrl) { return eIgnoreUrl !== url; });
    };
    HttpCacheService.prototype.hasIgnoreOnce = function (url) {
        return !!this.ignoreOnceList.find(function (ignore) { return ignore === url; });
    };
    /**
     * This purges the current cache of http request.
     * DO NOT USE THIS if you don't know WHAT you're doing or WHY you are doing it
     * Use this SPARINGLY as this negates any performance gain from http caching.
     */
    HttpCacheService.prototype.purge = function () {
        this.cache = [];
    };
    HttpCacheService.prototype.expireCache = function (cache) {
        cache.requested = moment()
            .day(-1)
            .toDate();
    };
    HttpCacheService.ngInjectableDef = i0.defineInjectable({ factory: function HttpCacheService_Factory() { return new HttpCacheService(); }, token: HttpCacheService, providedIn: "root" });
    return HttpCacheService;
}());
export { HttpCacheService };
